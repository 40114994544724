
// always shrink images to fit their parent
img {
  max-width: 100%;
}

// ...when as specific height is set (content editor), make sure that image still get small on mobile devices
@include media-query(palm) {
  img[data-lazyload-src][style*="height"],
  img[data-lazyload-src][height],
  img[src][style*="height"],
  img[src][height] {
    height: auto !important;
    max-width: 100% !important;
  }
}
