.c-micro-form {
  &__input {
    width: calc(100% - 50px) !important;
    display: inline-block;
    opacity: 0.8;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &__button {
    position: absolute;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 50px;

    &::before {
      border-radius: 0;
    }

    @include media-query(palm) {
      display: inline-block;
      line-height: 20px;
      width: 50px;
    }
  }

  &__update-usage {
    border-radius: $border-radius-small;
    padding-left: 5px;
    padding-right: 0;
    display: block;
    width: 100%;
    background-color: brand-colors(nav, hover);
  }

  &__button-arrow {
    transform: rotate(90deg);
    display: inline-block;
    text-align: center;
    fill: colors(white);

    &--left {
      transform: rotate(270deg);
    }
  }
}
