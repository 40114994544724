@use "sass:math";

$logo-loader-speed: 1s;

@keyframes logo-loader {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.c-logo {
  svg & {
    stroke: colors(white);
  }

  &__arrow {
    animation: logo-loader $logo-loader-speed ease infinite;
    opacity: 1;
    fill: brand-colors(nav, hover);

    &--1 {
      animation-delay: 0;
    }

    &--2 {
      animation-delay: $logo-loader-speed * 0.1;
    }

    &--3 {
      animation-delay: $logo-loader-speed * 0.2;
    }

    &--4 {
      animation-delay: $logo-loader-speed * 0.3;
    }
  }

  &__pole {
    fill: colors(grey);
    opacity: 0.7;
  }

  &__bg {
    opacity: 0.7;
    fill: colors(grey, light);
  }
}

@keyframes logo-provider {
  0% {
    opacity: 0;
  }

  9% {
    opacity: 1;
  }

  10% {
    opacity: 1;
  }

  15% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

$logoproviderspeed: 15s;
$logoprovidernumber: 10;


.c-logo-provider {
  position: relative;

  &__item {
    position: absolute;
    top: 0;
    left: 20px;
    right: 0;
    bottom: 0;
    text-align: center;
    opacity: 0;
    display: none;
    animation-name: logo-provider;
    animation-duration: $logoproviderspeed;
    animation-iteration-count: infinite;

    @for $item from 0 through ($logoprovidernumber - 1) {
      &:nth-child(#{$item + 1}) {
        animation-delay: #{$item * math.div($logoproviderspeed, $logoprovidernumber)};

        .c-logo-provider--randomized & {
          display: block;
        }
      }
    }
  }

  &__image {
    @include center;
    max-width: 140px;
    max-height: 70px;
    opacity: 0.8;
  }
}
