.c-tariff-block {
  display: grid;
  position: relative;
  z-index: 0;
  grid-template-columns: [tariff-type] auto [tariff-meter] auto [tariff] 1fr [redelivery]  1fr [last];
  grid-template-rows: [header] auto [electricity] auto [electricity-low] auto [gas] auto [last];
  row-gap: 3px;
  column-gap: 15px;

  @include media-query(palm) {
    column-gap: 5px;
  }

  &__col {
    &-tariff-type {
      grid-column-start: tariff-type;
      align-self: center;
      padding-left: 20px;

      @include media-query(palm) {
        padding-left: 10px;
      }
    }

    &-tariff-meter {
      grid-column-start: tariff-meter;
      align-self: center;

      @include media-query(palm) {
        display: none;
      }
    }

    &-tariff {
      grid-column-start: tariff;
    }

    &-redelivery {
      grid-column-start: redelivery;
    }

    &-tariff,
    &-redelivery {
      place-self: center;
      margin-right: 15px;
      padding-right: 5px;
      padding-left: 5px;

      @include media-query(palm) {
        margin-right: 10px;
      }
    }
  }

  &__row {
    &-header {
      grid-row-start: header;
      text-align: center;
      padding: 10px 5px;
    }

    &-electricity {
      grid-row-start: electricity;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 15px;

      @include media-query(palm) {
        margin-bottom: 10px;
      }

      &:has(~ .c-tariff-block__row-electricity-low) {
        margin-bottom: 0;
      }
    }

    &-electricity-low {
      grid-row-start: electricity-low;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 15px;

      @include media-query(palm) {
        margin-bottom: 10px;
      }
    }

    &-gas {
      grid-row-start: gas;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 15px;

      @include media-query(palm) {
        margin-bottom: 10px;
      }
    }
  }

  &__bg-row {
    grid-column: tariff-type / redelivery;
    border-radius: $border-radius;
    background-color: rgba(colors(black), 0.05);
    backdrop-filter: blur(10px);
    z-index: -1;
    place-self: stretch;

    &.c-tariff-block__row-electricity:has(~ .c-tariff-block__row-electricity-low) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.c-tariff-block__row-electricity-low {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.c-tariff-block__row-electricity,
    &.c-tariff-block__row-electricity-low {
      &:has(~ .c-tariff-block__col-redelivery) {
        grid-column-end: last;
      }
    }
  }

  &__bg-col {
    grid-row: header / last;
    border-radius: $border-radius;
    background-color: rgba(colors(white), 0.12);
    backdrop-filter: blur(10px);
    z-index: -1;
    place-self: stretch;

    &--redelivery {
      grid-row-end: gas;
    }
  }
}
