// ----------------------------------------------------------------------------
// Top 5
// ----------------------------------------------------------------------------

.c-top5-tabs {
  &__tabs {
    line-height: 2em;

    @include media-query(default) {
      margin-top: -2.8em;
      position: relative;
    }
  }

  &__tab {
    border-radius: $border-radius-small $border-radius-small 0 0;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    fill: colors(white);
    background-color: rgba(colors(white), 0.2);
    color: colors(white);

    @include media-query(lap) {
      padding-right: 15px;
      padding-left: 15px;
    }

    @include media-query(desk) {
      padding-right: 20px;
      padding-left: 20px;
    }

    &.current,
    &:hover {
      text-decoration: none;
      background-color: colors(white);
      color: colors(grey, dark);

      .u-svg-fill {
        fill: colors(status, green);
      }
    }

    @include media-query(palm) {
      &.current {
        display: none;
      }
    }

    svg {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: -3px;
      position: relative;
    }
  }

  &__title {
    @include media-query(default) {
      color: colors(white);
      position: absolute;
      top: -2em;
    }

    svg {
      position: absolute;
      left: -30px;
      top: 15px;

      @media screen and (max-width: 76.25em) {
        left: -20px;
        transform: scale(0.7);
      }
    }
  }

  &__explanation-link {
    margin-top: -4em;
    position: relative;
    float: right;

    @media screen and (max-width: 63.9375em) {
      margin-top: -0.5em;
      float: left;
    }

    &--in-tab {
      margin-top: -7em;
      color: colors(white);

      @media screen and (max-width: 63.9375em) {
        margin-top: -4em;
      }
    }
  }

  &__established-price {
    @include media-query(desk) {
      margin-top: -29px;
    }
  }

  &__green-package {
    width: 18px;
    display: inline-block;

    + .c-top5-tabs__package-name {
      width: calc(100% - 25px);
      display: inline-block;
    }

    svg {
      fill: colors(status, green);
    }
  }

  &__logo {
    max-height: 44px;
  }

  &__package-name {
    @include media-query(default) {
      @include overflow--ellipsis;
    }
  }
}
