﻿// ------------------------ TABLE ------------------------
table.c-result-table,
table.resultTable {
  @include typography(zeta);
  border-collapse: separate;
  width: 100%;
  font-family: $brand-face;

  tbody {
    /* BEL-527 Fix for layout-bug with cookieconsent on Bellen.com */
    display: table-row-group !important;
  }

  thead {
    tr {
      vertical-align: top;
    }

    th {
      font-weight: bold;
      text-align: left;
      padding: 10px 5px;
      border-right: 1px solid colors(white);
      line-height: 16px;
      background-color: colors(grey, x-light);

      span.subText {
        font-size: 10px;
        display: block;
        font-weight: normal;
        line-height: 15px;
        margin: 3px 0 5px;
      }

      &:first-child {
        border-left: 0;
      }

      &[data-sort] {
        cursor: pointer;
      }

      &.active[data-sort] {
        background-color: colors(white);
      }

      &.active[data-sort],
      &[data-sort]:hover {
        &:after {
          content: "\25bc";
          display: block;
          text-align: center;
        }
      }
    }
  }

  .subContent {
    padding: 0;
  }

  .subContentTable {
    display: none;

    table {
      width: 100%;
      border-bottom: 1px solid colors(grey, light);
    }

    tr {
      border-bottom: 1px solid colors(grey, light);
    }

    td {
      padding: 10px 0 10px 5px;
      vertical-align: middle;
      background-color: colors(grey, light);
    }
  }

  td {
    padding: 5px;
    margin: 0;
    border-bottom: 1px solid colors(grey, light);
    vertical-align: middle;
    line-height: 20px;
    white-space: nowrap;

    p {
      margin: 0;
    }

    img {
      margin: 0;
    }

    &.subContent {
      border: 0;
    }
  }
}
