.c-switch {
  $switch-toggle-size: 28px;
  $switch-toggle-bg: rgba(colors(white), 0.3);
  $switch-toggle-bg-active: colors(status, green);
  $switch-toggle-knob: colors(white);
  $switch-toggle-knob-active: colors(white);
  $switch-toggle-bg--on-white: colors(grey, light);

  &-toggle-wrapper {
    width: 2 * $switch-toggle-size;

    *,
    *:before,
    *:after {
      transition: 0.2s ease-in-out;
    }

    &--opposite {
      position: absolute;
      right: 0;
      margin-top: -14px;
      transform: translateY(50%);
    }
  }

  &-toggle,
  &-toggle:active {
    position: absolute;
    overflow: hidden;
    height: 0;
    width: 0;
    opacity: 0;
  }

  &-toggle-label {
    display: block;
    position: relative;
    padding: 0;
    font-size: 15px;
    line-height: 16px;
    width: 100%;
    height: $switch-toggle-size;
    border-radius: $border-radius;
    cursor: pointer;
    background-color: $switch-toggle-bg;

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      line-height: 34px;
      text-indent: 40px;
      height: $switch-toggle-size;
      width: $switch-toggle-size;
      border-radius: $border-radius-full;
      top: 0;
      left: 0;
      right: auto;
      background-color: $switch-toggle-knob;
      box-shadow: 0 0 2px $switch-toggle-bg, 0 0 0 1px $switch-toggle-bg;
    }

    &:after {
      content: attr(data-off);
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      padding: 5px 0;
      height: 100%;
      text-align: right;
      opacity: 1;
      white-space: nowrap;
      left: 70px;
      font-size: 13px;
      font-weight: bold;
      opacity: 1;
    }

    &--opposite {
      &:after {
        width: 60px;
        left: -80px;
      }
    }
  }

  &-toggle:checked + .c-switch-toggle-label {
    box-shadow: inset 0 0 0 20px $switch-toggle-bg-active, 0 0 0 1px $switch-toggle-bg-active;

    &:before {
      left: calc(100% - #{$switch-toggle-size});
      box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(colors(black), 0.3);
      background-color: $switch-toggle-knob;
    }

    &:after {
      content: attr(data-on);
      opacity: 1;
    }
  }

  /* Crashtest specific styles*/
  &-toggle-label--crashtest {
    background-color: rgba(colors(brand, support), 0.15);

    &:before {
      background-color: colors(brand, support);
    }
  }

  &-toggle:checked + .c-switch-toggle-label--crashtest {
    box-shadow: inset 0 0 0 20px colors(status, yellow), 0 0 0 1px colors(status, yellow);

    &:before {
      background-color: colors(white);
      box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(colors(brand, support), 0.15);
    }
  }

  &-toggle-wrapper--crashtest {
    position: absolute;
    right: 25px;
    bottom: 0;
    display: none;

    .c-switch-toggle-label {
      background-color: #f4f4f4;

      &:after {
        color: colors(white);
        width: 60px;
        left: -80px;
      }
    }
  }

  &-toggle-label--on-white {
    background-color: $switch-toggle-bg--on-white;

    &:before {
      box-shadow: 0 0 2px $switch-toggle-bg--on-white, 0 0 0 1px $switch-toggle-bg--on-white;
    }
  }

  &-toggle:checked + .c-switch-toggle-label--on-white,
  &-toggle:checked + .c-switch-toggle-label--on-gradient {
    box-shadow: inset 0 0 0 20px brand-colors(nav), 0 0 0 1px brand-colors(nav);
  }
}
