@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: colors(black) !important; // Black prints faster: h5bp.com/s
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  aside.layout__item,
  .c-pw-article-header,
  .c-site-search,
  .c-main-navigation,
  .c-site__navigation,
  .c-site__footer,
  .c-savings-banner,
  .c-sortbar,
  .c-filters,
  .c-tabbox__tabs,
  .c-comparison-list__compare-button-wrapper,
  .c-button,
  .c-button--cta,
  .c-button--disabled,
  .c-button--support,
  .c-comparison__highlight-checkbox + .layout,
  .js-remove-comparison-item,
  .c-comparison-list__more-info-toc,
  .c-response-form {
    display: none !important;
  }

  .c-comparison-list__more-info-content,
  article.layout__item,
  .c-site__content {
    width: 100% !important;
    border-left: none !important;
  }

  .c-site__header {
    border-bottom: 2px solid colors(grey);
  }

  .c-bencom-label {
    margin-top: 40px;
  }

  .c-comparison-list__item {
    border-top: 2px solid colors(grey);
  }

  .c-comparison__scrollcontainer,
  .c-comparison__coverage-section,
  .c-comparison__coverage-cell {
    min-width: 0 !important;
  }

  .c-comparison__category--features {
    height: auto !important;
    overflow: visible !important;
    opacity: 1 !important;
  }

  .o-panel {
    padding: 0;

    hr {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .o-container {
    width: 100%;
    max-width: inherit;
    padding: 0;
  }

  .content-text,
  .content-introtext {
    @include typography(default);
  }
}
