%c-icon-close,
.c-icon-close {
  background-color: colors(white);
  border-radius: $border-radius-full;
  position: absolute;
  top: 10px;
  right: 20px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  z-index: 98040;

  &--tooltip {
    top: auto;
    right: auto;
    float: right;
    position: relative;
    margin-top: -7px;
    margin-left: 4px;
    border-radius: $border-radius-small;
    width: 32px;
    height: 32px;
    background-color: colors(grey, x-light);

    &:hover {
      background-color: colors(brand, support);

      &::before,
      &::after {
        background-color: colors(white);
      }
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: colors(brand, support);
  }

  &::before {
    transform: rotate(45deg) scale(0.5);
    transform-origin: center center;
    height: 2px;
    top: 50%;
    left: 0;
    right: 0;
  }

  &::after {
    transform: rotate(-45deg) scale(0.5);
    transform-origin: center center;
    height: 2px;
    top: 50%;
    left: 0;
    right: 0;
  }
}

.c-icon-info {
  height: 11px;
  width: 5px;
  background: url(#{$assetPathImages}icons/info.svg) no-repeat center center;
  display: inline-block;
  margin-left: quarter($inuit-base-spacing-unit);

  &--border {
    border: 1px solid colors(grey, base);
    border-radius: $border-radius-full;
    padding: 9px 10px 10px;
    margin-bottom: -3px;

    &[data-tooltip-trigger] {
      cursor: pointer;
    }

    .c-inputfields__label & {
      margin-bottom: -6px;
    }

    .c-filter-section--input & {
      border-color: colors(white);
    }
  }

  .c-filter-section--input &:not(.c-icon-info--input) {
    background-image: url(#{$assetPathImages}icons/info-white.svg);
  }
}

.c-icon-info--input {
  position: absolute;
  width: 40px;
  height: auto;
  right: 0;
  top: 0;
  bottom: 0;
}

.c-title-icon {
  fill: currentColor;

  svg {
    position: relative;
    bottom: -4px;
    margin-right: 10px;
  }
}
