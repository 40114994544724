// ----------------------------------------------------------------------------
// Font-weight mixins
// ----------------------------------------------------------------------------

@mixin font-weight--regular {
  font-weight: 400;
}

@mixin font-weight--semi-bold {
  font-weight: bold;
}

@mixin font-weight--italic-bold {
  font-weight: bold;
  font-style: italic;
}
