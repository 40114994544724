.c-latest-news {
  gap: 20px;

  @include media-query(default) {
    flex-wrap: nowrap;
  }

  &__item {
    flex-grow: 1;
  }
}
