.select2-selection--single {
  height: 50px;

  .select2-selection__rendered {
    @include typography(zeta);
    background-color: colors(white);
    border-radius: $border-radius;
    border: 1px solid colors(grey, light);
    color: colors(grey, dark);
    padding: 14px 30px 14px 15px;
    width: 100%;
    display: block;
  }

  &:focus {
    outline: none;
  }
}