﻿.c-helpdesk-status {
  @include typography(zeta);
  z-index: 1;
  color: colors(grey, dark);
  fill: colors(brand, support);
  position: absolute;
  right: $inuit-base-spacing-unit;
  top: 4px;

  @include media-query(palm) {
    right: 0;
    left: 0;
    top: 0;
    transform: translateX(-100%);
  }

  [data-role="callcenter-statustext"] {
    display: none;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__whatsapp-content {
    padding: 20px 20px 160px;

    .fancybox-inner & {
      background-image: url(#{$assetPathImages}graphics/bencom-specialisten.png);
      background-position: right -20px bottom;
      background-size: 70%;
      background-repeat: no-repeat;
    }

    &--poliswijzer {
      .fancybox-inner & {
        background-image: url(#{$assetPathImages}graphics/poliswijzer/poliswijzer-specialisten.png);
      }
    }

    &--gaslicht {
      .fancybox-inner & {
        background-image: url(#{$assetPathImages}graphics/gaslicht/servicedesk_contactform.png);
        background-size: 53%;
        background-position: right 9px bottom;
      }
    }
  }
}

.c-helpdesk-icon-mobile {
  position: absolute;
  fill: colors(brand, support);
  right: 45px;
  top: 18px;
}

.c-helpdesk-wizard-popup {
  background-color: rgba(colors(white), 0.8);
  color: colors(grey, dark);
  border-radius: $border-radius-small;
}

.c-helpdesk-header__online {
  .c-main-navigation__active & svg {
    color: colors(status, green);
  }
}

.c-helpdesk-header__offline {
  .c-main-navigation__active & svg {
    color: colors(status, red);
  }
}

.c-helpdesk-header-button {
  background-color: rgba(colors(white), 0.1);
  border-radius: $border-radius;
  display: flex !important;
  align-items: center;
  padding: 10px 20px !important;
  white-space: nowrap;

  @include media-query(lap) {
    height: 50px;
    padding: 5px 10px !important;
  }

  @include media-query(palm) {
    height: 30px;
    padding: 5px 10px !important;
    background-color: transparent;
  }

  &__icon {
    svg {
      display: block;
      width: 30px;
      height: 30px;

      @include media-query(palm) {
        height: 25px;
        width: 25px;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }
}