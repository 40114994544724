//
// Mixin to get the correct typography settings (incl weight/italic settings)
//

@mixin typography($type) {

  @if $type == "alpha" {
    @include inuit-font-size($inuit-heading-size-1, $inuit-heading-size-1-lineheight);
    @include font-weight--italic-bold;
  }

  @if $type == "beta" {
    @include inuit-font-size($inuit-heading-size-2, $inuit-heading-size-2-lineheight);
    @include font-weight--italic-bold;
  }

  @if $type == "gamma" {
    @include inuit-font-size($inuit-heading-size-3, $inuit-heading-size-3-lineheight);
    @include font-weight--italic-bold;
  }

  @if $type == "delta" {
    @include inuit-font-size($inuit-heading-size-4, $inuit-heading-size-4-lineheight);
    font-style: normal;
  }

  @if $type == "epsilon" {
    @include inuit-font-size($inuit-heading-size-5, $inuit-heading-size-5-lineheight);
    font-style: normal;
  }

  @if $type == "zeta" {
    @include inuit-font-size($inuit-heading-size-6, $inuit-heading-size-6-lineheight);
    font-style: normal;
  }

  @if $type == "default" {
    @include inuit-font-size($inuit-base-font-size, $inuit-base-line-height);
    font-style: normal;
  }

  @if $type == "small" {
    @include inuit-font-size($small-font-size, $small-font-size-lineheight);
    font-style: normal;
  }
}
