.c-package-page {
  top: -60px;
  position: relative;

  &__section-header {
    background-color: rgba(gradients(gradient3, to), 0.5);
    padding-right: 60px;
    padding-left: 20px;
    border-radius: $border-radius;

    @include media-query(desk-wide) {
      padding-left: 40px;
    }

    @include media-query(lap) {
      padding-left: 30px;
    }

    @include media-query(palm) {
      padding-left: 20px;
      padding-right: 40px;
    }

    .c-filter-section__checkbox ~ &:after {
      right: 30px;

      @include media-query(palm) {
        right: 15px;
      }
    }

    + .c-filter-section__body {
      border-top: 0;
      padding-right: 10px;

      @include media-query(palm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__video-pointer {
    margin-left: 20px;
    display: inline-block;
    transform: rotate(120deg);
  }

  @include media-query(desk) {
    &__conversion-bar {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 50;
      box-shadow: 0 0 10px 0 rgba(colors(black), 0.4);
      will-change: transform;
      transition: transform 200ms linear;
      transform: translateY(calc(100% + 10px + #{$comparison-modal-open-close-height}));

      @include ie {
        display: none;
      }

      &--pinned.c-package-page__conversion-bar--not-top {
        transform: translateY(0%);

        @include ie {
          display: block;
        }
      }

      &--compare-bar-offset {
        margin-bottom: $comparison-modal-open-close-height;
      }
    }
  }

  @include media-query(palm) {
    .csspositionsticky &__conversion-bar--mobile-sticky {
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 101;
      width: calc(100vw + 10px);
      margin-top: -10px;
      margin-left: -20px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -10px;
        height: 10px;
        @include gradient(rgba(colors(black), 0), rgba(colors(black), 0.2), transparent);
      }

      @include media-query(lap) {
        margin-left: -20px;
      }
    }
  }

  &__sidebar {
    @include media-query(desk) {
      &-wrapper {
        width: 476px;
      }

      &-header,
      &-header--initial,
      &-content {
        width: 456px;
      }
    }

    @include media-query(desk-wide) {
      &-wrapper {
        width: 544px;
      }

      &-header,
      &-header--initial,
      &-content {
        width: 524px;
      }
    }

    @include media-query(desk) {
      &-header {
        position: relative;

        html.js .c-package-page__sidebar-header--initial:not(&) {
          position: fixed;
          top: 300px;

          + .c-package-page__sidebar-content {
            display: none;
          }
        }

        &--not-top {
          position: fixed;
          top: 90px !important;
        }
      }

      &-content {
        .c-package-page__sidebar-header--top + & {
          position: relative;
          top: 0 !important;
        }

        .c-package-page__sidebar-header--not-top + & {
          margin-top: 0 !important;
        }

        &--not-top {
          position: fixed;
        }
      }


      &-wrapper {
        &--not-top {
          position: sticky;
          top: 70px;

          .c-package-page__sidebar-header,
          .c-package-page__sidebar-content {
            position: relative;
            top: auto !important;
          }
        }
      }
    }
  }
}

.c-package-tabs {
  padding-top: 10px;
  position: sticky;
  top: 70px;
  color: colors(grey);
  background-color: colors(white);
  z-index: 2;
  display: flex;
  column-gap: 30px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    height: 20px;
    @include gradient(rgba(colors(white), 1), rgba(colors(white), 0), rgba(colors(white), 0));
    z-index: -1;
    border-radius: $border-radius;
  }

  &:after {
    content: "";
    position: absolute;
    left: -20px;
    right: 0;
    bottom: -1px;
    border-bottom: 1px solid colors(grey, light);
    z-index: -1;
  }

  @include media-query(desk-wide) {
    column-gap: 50px;
  }

  @include media-query(palm) {
    height: 0;
    overflow: hidden;
  }

  > * {
    border-bottom: 2px solid transparent;
    padding-bottom: 10px;
    transition: all 300ms;
    white-space: nowrap;
    margin-bottom: -1px;

    &:hover {
      color: colors(black);
      text-decoration: none;
      border-color: colors(grey, dark);
    }
  }

  .o-list-links__current {
    color: colors(black);
    border-color: colors(black);
  }

  + .c-tabbox {
    min-height: 400px;
  }
}

.c-package-block {
  @include media-query(lap) {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
  }

  &__details {
    @include media-query(lap) {
      flex-grow: 1;
      min-width: 60%;
    }
  }

  &__sale {
    border-radius: 0 0 $border-radius-large $border-radius-large;
    margin-top: -40px;
    padding: 40px 20px 20px;

    @include media-query(lap) {
      border-radius: 0 $border-radius-large $border-radius-large 0;
      margin-left: -40px;
      margin-top: 0;
      padding: 20px 20px 20px 60px;
    }
  }
}

$hexagon-size: 50px;

.c-provider-usp {
  position: relative;
  height: $hexagon-size;
  width: 1.732 * $hexagon-size;
  background: gradients(gradient3, from);
  margin: 0.5*$hexagon-size 10px 0.5*$hexagon-size;
  clip-path: circle(($hexagon-size - 2) at center);

  &--negative {
    filter: grayscale(100%);
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
  }

  &:before {
    transform: rotate(60deg);
  }

  &:after {
    transform: rotate(-60deg);
  }

  &__icon {
    position: absolute;
    @include center;
    z-index: 1;
    height: 100%;
  }

  &__label {
    padding-top: 10px;
    text-align: center;
  }
}