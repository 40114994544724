.c-button {
  @include inuit-font-size($button-font-size, $inuit-heading-size-6-lineheight);
  cursor: pointer;
  text-align: center;
  color: colors(white);
  fill: colors(white);
  display: inline-block;
  text-decoration: none;
  padding: 15px $inuit-base-spacing-unit;
  border-radius: $border-radius;
  background-color: colors(brand);
  position: relative;
  vertical-align: middle;
  transform: translateZ(0);
  backface-visibility: hidden;
  overflow: hidden;
  margin-bottom: $inuit-base-spacing-unit;
  transition-property: opacity, transform, background-color, color;
  transition-duration: $duration-fast;
  transition-timing-function: $swift-ease-in-timing-function;

  &[button] {
    overflow: hidden !important;
  }

  @include media-query(palm) {
    display: block;
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: colors(black);
    opacity: 0;
    transform: scale(0.5);
    transform-origin: 50% 50%;
    transition-property: opacity, transform, background-color, color;
    transition-duration: $duration-fast;
    transition-timing-function: $swift-ease-in-timing-function;
    border-radius: $border-radius-full;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover::before,
  &:active::before {
    transform: scale(1.2);
    opacity: 0.15;
  }

  &--small {
    @include typography(zeta);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: $border-radius-small;
  }

  &--large {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &--cta {
    background-color: colors(brand, cta);
  }

  &--support {
    background-color: colors(brand, support);
  }

  &--grey {
    color: colors(grey, dark);
    background-color: colors(grey, light);
  }

  &--white {
    color: colors(grey, dark);
    fill: colors(grey, dark);
    background-color: colors(white);

    &::before {
      background-color: transparent;
    }

    &:hover {
      background-color: rgba(colors(white), 0.9);
    }
  }

  &--ghost {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: inherit;

    &::before {
      background-color: colors(white);
    }
  }

  &--crashtest {
    input:checked + & {
      background-color: colors(status, yellow);
      color: colors(black);
      border-color: colors(status, yellow);
    }
  }

  &--filter {
    color: colors(grey, dark);
    background-color: transparent;
    border: 1px solid;
    padding-left: 15px;
    padding-right: 15px;

    &::before {
      background-color: colors(white);
    }

    &:hover,
    &-active {
      color: colors(white);
      background-color: brand-colors(nav, base);
    }
  }

  &--fullwidth {
    display: block;
    width: 100%;
  }

  &--autowidth {
    display: inline-block;
    width: auto;
  }

  &--disabled,
  &[disabled] {
    cursor: default;
    background-color: colors(grey, light);

    &::before {
      display: none;
    }
  }

  &--back {
    svg {
      transform: rotate(-90deg) translateX(2px) translateY(-13px);
    }
  }

  &--add {
    svg {
      position: relative;
      top: 2px;
      height: 14px;
      width: 14px;
      margin-right: 10px;
    }
  }

  &--sticky {
    position: sticky;
    left: 0;

    &__bottom {
      bottom: 0;
    }

    &__top {
      top: 0;
    }
  }

  &-group {
    display: flex;

    &-button {
      text-decoration: none;
      text-align: center;
      color: colors(grey, dark);
      padding: 5px;
      border: 1px solid colors(grey, light);
      flex: 1;
      transition: all 0.3s;

      &:first-of-type {
        border-top-left-radius: $border-radius-tiny; /* Rounds first button borders */
        border-bottom-left-radius: $border-radius-tiny; /* Rounds first button borders */
      }

      &:last-of-type {
        border-top-right-radius: $border-radius-tiny; /* Rounds last button borders */
        border-bottom-right-radius: $border-radius-tiny; /* Rounds last button borders */
      }

      &:not(:last-child) {
        border-right: none; /* Prevent double borders */
      }

      &:hover {
        border: solid 1px brand-colors(nav, base);
        box-shadow: 0 2px 4px 0 rgba(brand-colors(nav, base), 0.4);
      }

      &--current,
      &:focus,
      &:active,
      input:checked + & {
        font-weight: bold;
        border: solid 1px brand-colors(nav, base);
        box-shadow: 0 2px 4px 0 rgba(brand-colors(nav, base), 0.4);
        background-color: brand-colors(nav, base);
        color: colors(white);

        &:not(:last-child) {
          border-right: solid 1px brand-colors(nav, base);
        }
      }
    }
  }

  @keyframes showResubmitButton {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &--resubmit {
    position: sticky;
    z-index: 1;
    left: 50%;
    top: 400px;
    width: 400px;
    height: 0;
    color: colors(white);
    border-radius: $border-radius-full;
    font-weight: bold;
    text-align: center;
    @include typography(delta);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    opacity: 0;
    display: none;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-name: showResubmitButton;
    animation-timing-function: $swift-ease-in-out-timing-function;
    animation-duration: 750ms;
    animation-delay: 0s;

    &--visible {
      display: block;
      animation-fill-mode: forwards;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 0;
      top: 40px;
      z-index: -1;
      box-shadow: 0 0 150px 150px rgba(brand-colors(nav, base), 0.4);
    }
  }
}
