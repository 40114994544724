// ----------------------------------------------------------------------------
// Simple background image mixin that adds "background-repeat: no-repeat" by
// default, plus it puts the image folder as default so you can skip that part.
//
// Example:
//
// @include bg-image("icons/electricity.svg");
// ----------------------------------------------------------------------------

@mixin bg-image($file) {
  background-image: url($assetPathImages + $file);
  background-repeat: no-repeat;
}

@mixin circle-crop($size) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  clip-path: circle(calc($size * 0.5) at center);
  height: $size + 1;
  width: $size + 1;
}
