.c-inputfields {
  &__row {
    margin-bottom: $inuit-base-spacing-unit;
  }

  @include media-query(default) {
    &__input {
      &--s,
      &--m {
        width: 120px !important;

        &.c-inputfields__input--inline {
          width: 110px !important;
        }
      }

      &--l,
      &--xl {
        width: 320px !important;

        &.c-inputfields__input--inline {
          width: 200px !important;
        }
      }
    }
  }

  @include media-query(palm) {
    &__input {
      &--s,
      &--m {
        &.c-inputfields__input--inline {
          width: 36vw !important;
        }
      }

      &--l,
      &--xl {
        &.c-inputfields__input--inline {
          width: 48vw !important;
        }
      }
    }
  }

  &__label {
    @include typography(zeta);

    &--error {
      color: colors(status, red);

      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="search"],
      input[type="color"],
      input[type="date"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="number"],
      input[type="tel"],
      input[type="time"],
      input[type="url"],
      input[type="week"],
      input[list],
      input[type="file"],
      select,
      textarea {
        border: 1px solid colors(status, red);
      }
    }
  }

  &__input--radio,
  &__input--checkbox {
    @extend %u-visuallyhidden;

    ~ .c-inputfields__label {
      padding-left: 35px;
      display: inline-block;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: colors(white);
        border: 1px solid colors(grey, light);
      }

      &::after {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        height: 20px;
        width: 20px;
        opacity: 0;
        transform: scale(0);
        transition: all 100ms $swift-ease-in-out-timing-function;
      }

      &--tight {
        padding-left: 30px;
      }

      &--small {
        &::before {
          transform: scale(0.7);
          border-color: colors(grey);
        }
      }
    }

    ~ .c-inputfields__label--block {
      display: block;
    }

    ~ .c-inputfields__label--button {
      display: block;
      box-shadow: 0 2px 4px 0 rgba(colors(black), 0.3);
      border-radius: $border-radius;
      border: 1px solid colors(grey, light);
      padding: 14px 10px 14px 60px;

      @include media-query(portable) {
        padding-top: 10px;
        padding-bottom: 8px;
      }

      &::before,
      &::after {
        top: 50%;
        margin-top: -12px;
        left: 20px;
        height: 24px;
        width: 24px;
      }
    }

    ~ a.c-inputfields__label--button {
      &:before,
      &:after {
        display: none;
      }
    }

    &:checked ~ .c-inputfields__label--button {
      border-color: colors(brand, cta);
      box-shadow: 0 2px 4px 0 rgba(colors(brand, cta), 0.4);
    }

    ~ .c-inputfields__label--button-left-top {
      padding: 14px 10px 14px 60px;

      &::before,
      &::after {
        top: 40px;
        margin-top: 0;
        left: 20px;
      }
    }

    ~ .c-inputfields__label--button-right-top {
      padding: 14px 10px;

      &::before,
      &::after {
        top: 20px;
        margin-top: 0;
        left: auto;
        right: 20px;

        @include media-query(lap) {
          top: 10px;
          right: 10px;
        }
      }

      &--mobile {
        @include media-query(palm) {
          padding: 14px 10px;

          &::before,
          &::after {
            margin-top: 0;
            top: 20px;
            left: auto;
            right: 20px;
          }
        }
      }
    }

    ~ .c-inputfields__label--button-center-bottom {
      padding: 0 0 40px;

      &::before,
      &::after {
        bottom: 10px;
        margin-top: 0;
        left: 50%;
        top: auto;
        margin-left: -12px;
      }
    }

    ~ .c-inputfields__label--rangeslider {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 2;
      top: -6px;
      width: 20px;
      height: 20px;
      padding-left: 0;

      html.touchevents & {
        display: none;
      }
    }

    &:checked ~ .c-inputfields__label {
      &::after {
        opacity: 1;
        transform: scale(1);
      }

      &--small::after {
        transform: scale(0.7);
      }
    }

    &:focus ~ .c-inputfields__label {
      &::before {
        box-shadow: 0 0 4px 0 rgba(colors(brand, support), 0.3);
        border-color: rgba(colors(brand, support), 0.2);
      }
    }

    &:disabled {
      opacity: 0.5;

      + .c-inputfields__label {
        opacity: 0.5;
      }
    }
  }

  &__input--radio {
    ~ .c-inputfields__label {
      &::before {
        border-radius: $border-radius-full;
      }

      &::after {
        border-radius: $border-radius-full;
        background-color: colors(brand, cta);
      }
    }

    ~ .c-inputfields__label--cta {
      &::after {
        background-color: colors(brand);
      }
    }

    &:checked ~ .c-inputfields__label {
      &::after {
        transform: scale(0.6);
      }
    }

    &-dependent {
      display: none;
      padding-left: 35px;
    }

    &:checked ~ .c-inputfields__label ~ &-dependent {
      display: inline-block;
    }
  }

  &__input--checkbox {
    ~ .c-inputfields__label {
      &::before {
        border-radius: $border-radius-tiny;
      }

      &::after {
        background: url(#{$assetPathImages}icons/check-blue.svg) no-repeat center center;
      }
    }

    &:not(:checked) ~ .c-inputfields__label--button {
      &:hover {
        border-color: rgba(colors(brand, cta), 0.5);
        box-shadow: 0 2px 4px 0 rgba(colors(brand, cta), 0.1);

        &::after {
          opacity: 0.5;
          transform: scale(1);
        }
      }
    }
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list],
input[type="file"],
select,
textarea {
  &.error {
    border: 1px solid colors(status, red);
  }
}

label.error {
  //jQuery-validate
  @include typography(zeta);
  color: colors(status, red);
}

@keyframes input-pulse {
  0% {
    background-color: gradients(gradient3, from);
    border-color: rgba(colors(brand, support), 0.3);
  }

  25% {
    background-color: gradients(gradient3, from);
    border-color: rgba(colors(brand, support), 0.3);
  }

  100% {
    background-color: colors(white);
    border-color: colors(grey, light);
  }
}

@keyframes input-pulse-select-arrow {
  0% {
    background-color: gradients(gradient3, from);
  }

  25% {
    background-color: gradients(gradient3, from);
  }

  100% {
    background-color: colors(white);
  }
}

.c-input-loader {
  position: absolute;
  display: none;
  font-size: 10px;
  color: colors(grey, light);
  text-indent: -9999em;
  transform: scale(0.5) translateZ(0);
  animation-delay: -0.16s;
  right: 2.5em;
  top: 0;
  bottom: 0;

  &,
  &:before,
  &:after {
    border-radius: $border-radius-full;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: inputloader 1.4s infinite ease-in-out;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }

  &:after {
    left: 3.5em;
  }

  @keyframes inputloader {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  .c-input-loading + & {
    display: block;
  }
}

.c-input--pulse,
input.c-input--pulse {
  animation-name: input-pulse;
  animation-duration: 4s;
  animation-iteration-count: 1;

  ~ .o-select__arrow {
    animation-name: input-pulse-select-arrow;
    animation-duration: 4s;
    animation-iteration-count: 1;
  }
}

input.c-input--ghost {
  background-color: transparent;
  border-color: colors(white);
  color: colors(white);

  &::-webkit-input-placeholder {
    // Chrome/Opera/Safari
    color: colors(white);
  }

  &::-moz-placeholder {
    // Firefox 19+
    color: colors(white);
  }

  &:-ms-input-placeholder {
    // IE 10+
    color: colors(white);
  }
}

// Hides the instruction text for an input when an error is shown
.parsley-errors-list.filled + .js-helptext-hidden-on-error {
  display: none;
}

select.c-input--idin-prefill,
input.c-input--idin-prefill {
  background-color: lighten(colors(brand, cta), 48%);
}

.c-bg-color-idin-redelivery,
input.c-input--idin-redelivery {
  background-color: lighten(colors(status, marked), 10%);
}

.c-idin-redelivery-arrow {
  position: absolute;
  right: 0;
  transform: scale(1.01);
  top: -10px;
}

.c-signup-form {
  // Input & Textarea ------------------

  // Fields with standard width
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="search"],
  input[type="color"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="number"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  input[list],
  //input[type="file"],
  select,
  textarea {
    padding: 6px 15px 8px;
  }

  input[type="date"],
  input[type="datetime-local"] {
    min-height: 36px;
  }

  .c-form-calendar,
  .c-form-input-suffix {
    width: 50px;
    height: 36px;
    text-align: center;
    margin: auto;
    top: 0;
    right: 0;
  }

  .c-form-input-suffix {
    line-height: 36px;
  }

  .c-form-calendar {
    line-height: 41px;
  }
}

.c-familymember-block {
  position: relative;
  opacity: 0.7;
  background-color: colors(grey, x-light);
  border: 1px solid colors(grey, x-light);

  @include media-query(portable) {
    padding: 10px !important;
  }

  @include media-query(desk) {
    padding: 20px !important;
  }

  &--done {
    @include media-query(palm) {
      background-position: 10px center;
    }

    @include media-query(default) {
      background-position: 10px 30px;
    }

    @include bg-image("icons/check.svg");
    padding-left: 30px !important;
  }

  &--error {
    border-color: colors(status, red);
  }

  &--active {
    &:not(:first-child:last-child) {
      border-color: colors(brand, cta);
      opacity: 1;
    }

    &,
    ~ .c-familymember-block:not(.c-familymember-block--done) {
      cursor: default;
      pointer-events: none;
    }

    @include media-query(default) {
      &:not(.c-familymember-block--single) {
        .layout--flex__wrap & {
          order: 1;
          width: 100%;
        }

        &:after,
        &:before {
          content: "";
          bottom: -22px;
          left: 50%;
          border: solid transparent;
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          z-index: 1;
        }

        &:after {
          border-bottom-color: colors(grey, x-light);
          border-width: 15px;
          margin-left: -15px;
        }

        &:before {
          border-width: 16px;
          margin-left: -16px;
        }
      }
    }
  }
}
