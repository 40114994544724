.c-block-button {
  border-radius: $border-radius;
  background-color: colors(white);
  display: block;
  text-align: center;
  padding: $inuit-base-spacing-unit $inuit-base-spacing-unit double($inuit-base-spacing-unit);

  @include media-query(palm) {
    padding-left: quadruple($inuit-base-spacing-unit);
    padding-right: quadruple($inuit-base-spacing-unit);
  }

  @include media-query(desk-wide) {
    padding-left: double($inuit-base-spacing-unit);
    padding-right: double($inuit-base-spacing-unit);
  }

  &__alternative-link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: $inuit-base-spacing-unit;
  }
}
