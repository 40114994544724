svg {
  fill: currentColor;

  .u-svg-fill {
    fill: inherit;
  }

  .u-svg-stroke {
    stroke: currentColor;
  }
}

.u-text-color-svg-path {
  svg path {
    stroke: currentColor;
  }
}

.u-svg-block svg {
  display: block;
}

.u-svg-stretch-contain svg {
  height: 100%;
  width: 100%;
}