.u-border-radius {
  &-- {
    border-radius: $border-radius-tiny;
  }

  &- {
    border-radius: $border-radius-small;
  }
  border-radius: $border-radius;

  &\+ {
    border-radius: $border-radius-large;
  }

  &\+\+ {
    border-radius: $border-radius-huge;
  }

  &--full {
    border-radius: $border-radius-full;
  }

  &--none {
    border-radius: 0 !important;
  }

  &--top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &--right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &--left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
