﻿//
// Creates container classes: .o-container--*
// $containers is defined in 01-settings/layout
//

@include container-setup($containers);

.o-container {
  @include media-query(desk) {
    width: 75.625em;
  }

  @include media-query(desk-wide) {
    width: 86.25em;
  }
}
