// Input & Textarea ------------------

// Fields with standard width
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list],
//input[type="file"],
select,
textarea {
  @include typography(zeta);
  background-color: colors(white);
  border-radius: $border-radius;
  border: 1px solid colors(grey, light);
  color: colors(grey, dark);
  padding: 14px 15px;
  width: 100%;

  @include media-query(palm) {
    @include inuit-font-size($inuit-base-font-size, $inuit-heading-size-6-lineheight);
  }
}

input[type="date"],
input[type="datetime-local"] {
  min-height: 50px;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list] {
  &:read-only {
    background-color: colors(grey, x-light);
    box-shadow: none !important;
  }
}

input[name*="postcode"],
input[name*="Postal"],
input[name*="postal"] {
  text-transform: uppercase;
}


// Placeholders ------------------

::-webkit-input-placeholder { // Chrome/Opera/Safari
  color: colors(grey);
}

::-moz-placeholder { // Firefox 19+
  color: colors(grey);
}

input:-ms-input-placeholder { // IE 10+
  color: colors(grey);
}
