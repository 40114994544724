﻿@keyframes grow {
  0% {
    display: block;
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.c-filter-section {
  background-color: colors(white);
  margin-bottom: 3px;

  @include media-query(desk) {
    border-radius: $border-radius-small;
  }

  &--translucent {
    background-color: rgba(colors(white), 0.6);
    margin-bottom: 5px;

    .c-filter-section__checkbox {
      ~ .c-filter-section__header::after {
        background-image: url(#{$assetPathImages}icons/add.svg);
        background-size: contain;
      }

      &:checked {
        ~ .c-filter-section__header::after {
          transform: rotate(405deg);
        }
      }
    }

    .c-filter-section__header + .c-filter-section__body {
      border-top: none;
      padding-top: 0;
    }
  }

  &--input {
    margin-bottom: 10px;
    color: colors(white);
    background-color: brand-colors(nav, base);

    .c-filter-coverage__wizard {
      color: colors(white);
      text-decoration: underline;
      background: transparent;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__heading {
    @include typography(epsilon);
    font-weight: 400;
  }

  &--ontop {
    position: relative;
    z-index: 1;
  }

  &__arrow {
    @include media-query(desk) {
      &::after {
        content: "";
        position: absolute;
        right: -10px;
        top: 0;
        margin-top: 37px;
        width: 20px;
        height: 20px;
        background: colors(white);
        transform: rotate(-45deg);
      }
    }
  }

  &__header {
    padding: 15px $inuit-base-spacing-unit;
    position: relative;
    cursor: default;

    @include media-query(desk-wide) {
      padding: 15px 30px;
    }

    @media screen and (min-width: 80em) and (max-width: 89.9375em) {
      padding: 15px $inuit-base-spacing-unit;
    }

    + .c-filter-section__body {
      border-top: 1px solid colors(grey, light);
    }
  }

  &__body {
    padding: $inuit-base-spacing-unit $inuit-base-spacing-unit $inuit-base-spacing-unit;

    @include media-query(desk-wide) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (min-width: 80em) and (max-width: 89.9375em) {
      padding-left: $inuit-base-spacing-unit;
      padding-right: $inuit-base-spacing-unit;
    }
  }

  &__footer {
    margin-top: double($inuit-base-spacing-unit);
    padding-bottom: halve($inuit-base-spacing-unit);

    &::after {
      content: "";
      pointer-events: none;
      z-index: -1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 40px;
      background-color: colors(grey, x-light);
    }

    @include media-query(desk) {
      display: none;
    }
  }

  &__readonly-item {
    @include typography(zeta);
    color: colors(grey);

    &--edit-button {
      margin-left: -15px;
      padding-left: 15px;
      background-color: #fff;
    }

    &--hidden {
      display: none !important;
    }
  }

  &__readonly-item--splitter,
  .c-filter-section__readonly-group {
    &:after {
      content: "\002F";
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  &__sub-heading {
    @include font-weight--semi-bold;
    @include typography(default);
    display: inline-block;
  }

  &.c-filter-section--input {
    h4 {
      &:has(+ .o-select) {
        @include font-weight--regular;
      }
    }

    input:disabled,
    select:disabled {
      background-color: brand-colors(nav, base);
      border-color: brand-colors(nav, base);
      filter: contrast(0.6) brightness(1.2);
    }
  }

  &__checkbox {
    ~ .c-filter-section__header {
      cursor: pointer;

      .c-filter-section__readonly {
        display: block;
      }
    }

    ~ .c-filter-section__header::after {
      content: "";
      position: absolute;
      right: 30px;
      width: 16px;
      top: 0;
      height: 60px;
      background-image: url(#{$assetPathImages}icons/arrow-up.svg);
      background-position: center center;
      background-repeat: no-repeat;
      transition: $swift-ease-in-out;
      transform: scaleY(-1);

      @include media-query(palm) {
        right: 20px;
      }
    }

    ~ .c-filter-section__header--white::after {
      background-image: url(#{$assetPathImages}icons/arrow-up-white.svg);
    }

    .c-filter-section--input & ~ .c-filter-section__header::after {
      background-image: url(#{$assetPathImages}icons/arrow-up-white.svg);
    }

    ~ .c-filter-section__header--arrowinline::after {
      @include media-query(default) {
        position: static;
        height: 8px;
        display: inline-block;
        top: auto;
        right: auto;
        bottom: auto;
        margin-left: halve($inuit-base-spacing-unit);
      }
    }

    ~ .c-filter-section__body {
      display: none;
      transform: scaleY(0);
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    &:checked {
      ~ .c-filter-section__header {
        &::after {
          transform: scaleY(1);
        }

        .c-filter-section__readonly {
          display: none;
        }

        .c-input-values__change-link {
          display: none;
        }
      }

      ~ .c-filter-section__body {
        display: block;
        transform: scaleY(1);
        transform-origin: top left;
        opacity: 1;
        animation: grow 0.3s ease-in-out;

        &--instant {
          animation: none;
        }
      }
    }
  }
}
