// ----------------------------------------------------------------------------
// Component for a round badge that can contain very short text or numbers.
// Positioning should be done outside the component to ensure reusability
//
// You can set color/bgcolor with utilities
// Can be chained with for example .u-dropshadow
// ----------------------------------------------------------------------------

// stylelint-disable declaration-empty-line-before

.c-round-badge {
  $size: 3rem;
  width: $size;
  height: $size;
  line-height: $size;
  border-radius: $border-radius-full;
  text-align: center;
  border: 3px solid colors(white);
  font-weight: bold;
  font-style: italic;
  box-sizing: content-box; // to fix the incorrect vertical centering due to line-height
  font-size: 1.5em;

  &--fraction {
    font-size: 1em;

    &::first-letter {
      font-size: 1.5em;
    }
  }

  &--top-right {
    position: absolute;
    top: 5px;
    right: 15px;
  }
}
