.c-article {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $brand-face;
  }

  h2 {
    font-size: $inuit-heading-size-5;
    line-height: 35px;
    font-style: normal;
    font-family: $brand-face;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: $inuit-base-font-size;
    line-height: 35px;
    font-style: normal;
    font-family: $brand-face;
  }

  img {
    &:not([src*="UserFiles/Image/Logos"]) {
      border-radius: $border-radius;
    }
  }

  img[width] {
    // Make sure the image won't be wider then its parent
    max-width: 100%;
    height: auto;
  }

  img[align="left"],
  img[style*="float: left;"] {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  img[align="right"],
  img[style*="float: right;"] {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  a {
    color: colors(brand, cta);
  }

  a.c-button {
    color: colors(white);
  }
}
