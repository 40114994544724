﻿.c-site {
  position: relative;

  &__header-sticky {
    background-color: rgba(brand-colors(nav, hover), 0.95);
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    z-index: 6;
    will-change: transform;
    transition: transform 200ms linear;
    transform: translateY(-100%);
    opacity: 0;

    &--not-top {
      transform: translateY(0%);
      opacity: 1;
    }

    &--pinned {
      transition-property: transform, opacity;
    }
  }

  &__header-part {
    flex-basis: 0;

    &--hamburger {
      display: none;
    }

    &--account {
      text-align: right;
    }

    &--helpdesk {
      text-align: right;
    }

    &--logo {
      align-self: center;

      @include media-query(palm) {
        img {
          max-width: 100% !important;
        }
      }
    }

    @media screen and (max-width: 63.9375em) {
      flex-basis: auto;
      $logo-width: 202px;
      $logo-width-palm: 150px;

      @include media-query(palm) {
        &--hamburger {
          display: block;
          order: 1;
          width: calc(22% - #{$logo-width-palm} / 4);
          padding-top: 5px;
        }

        &--avatar {
          order: 4;
          width: 45px;
          align-self: flex-end;
          text-align: right;

          img {
            margin-left: 5px;
            max-height: 45px;
            filter: drop-shadow(0 0 6px rgba(colors(white), 0.3));
          }
        }
      }

      &--logo {
        width: $logo-width;
        order: 3;

        @include media-query(palm) {
          width: $logo-width-palm;
          order: 1;
        }
      }

      &--helpdesk {
        text-align: left;
        order: 2;
        width: calc(50% - #{$logo-width} / 2);

        @include media-query(palm) {
          flex-grow: 1;
          text-align: right;
        }
      }

      &--account {
        order: 4;
        width: calc(50% - #{$logo-width} / 2);

        @include media-query(palm) {
          width: calc(50% - #{$logo-width-palm} / 2);
        }
      }
    }
  }

  &__header--helpdesk-topbar {
    @include media-query(default) {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 30px;
        background-color: colors(grey, x-light);
      }
    }
  }

  &__header-inner {
    @include media-query(palm) {
      min-height: 64px;
    }

    @include media-query(default) {
      min-height: $header-height;
    }

    @include media-query(default) {
      padding-top: 50px;
    }

    &--sticky {
      height: 70px;

      @include media-query(palm) {
        height: 50px;
      }
    }
  }

  &__label-header {
    background-color: colors(grey, x-light);
    box-shadow: 0 -3px 5px 0 inset rgba(colors(black), 0.15);
    height: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &-link {
      max-width: 1920px;
      flex-grow: 1;
    }

    &--slidein {
      margin-top: -50px;
      animation: showLabelNav 750ms 1000ms 1 forwards ease-in-out;
    }
  }

  @keyframes showLabelNav {
    0% {
      margin-top: -50px;
    }

    100% {
      margin-top: 0;
    }
  }

  &__body {
    background-color: colors(grey, x-light);
  }

  @include media-query(default) {
    &__body--nav-offset {
      margin-top: 50px;
    }
  }

  &__body--compare {
    background: gradients(gradient3, from);
    background: linear-gradient(
      to bottom,
      gradients(gradient3, from) 0%,
      gradients(gradient3, to) 100vh,
      gradients(gradient3, to) 100%
    );
  }

  &__body--side-comparison {
    min-height: 100%;
    background: gradients(gradient2, from);
    background: linear-gradient(
      to bottom,
      gradients(gradient1, from) 0%,
      gradients(gradient1, to) 100vh,
      gradients(gradient1, to) 100%
    );
  }

  &__content {
    @include media-query(portable) {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 870px;
    }
  }

  &__sidebar-sticky {
    @include media-query(desk) {
      position: sticky;
      top: 100px;
    }
  }

  &__navigation {
    position: absolute;
    left: 0;
    right: 0;

    @include media-query(default) {
      top: $header-height;
      height: 50px;
      background-color: brand-colors(nav, base);
    }

    @include media-query(palm) {
      top: 64px;
      height: 0;
    }
  }

  &-contentpage {
    &--homebattery,
    &--solarpanels {
      background-color: #e1f4eb;
    }

    &__content {
      width: 62.5em;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &__header {
      @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));
      color: colors(white);

      @include media-query(default) {
        padding: 20px 0 60px;
      }

      @include media-query(palm) {
        padding: 10px 0 40px;
      }
    }

    &__christmas-header {
      @include bg-image("graphics/gaslicht/christmas-tree.svg");
      background-position: right 10px bottom;
      background-size: auto 80px;
    }

    &__header-input {
      background-color: rgba(colors(white), 0.2);
      border-radius: $border-radius;
      padding: 15px 20px;
      display: inline-block;
      white-space: nowrap;
      margin: 6px 0;

      @include media-query(palm) {
        display: block;
      }
    }

    &__wrapper {
      border-radius: $border-radius;
      margin-top: -40px;
      position: relative;
      z-index: 1;
      background-color: colors(white);

      @include media-query(palm) {
        padding: 20px;
        min-height: 25vh;
      }

      @include media-query(default) {
        padding: 40px;
        min-height: 50vh;
      }

      &--autoheight {
        min-height: 0;
      }
    }

    &__wrapper-edges {
      @include media-query(palm) {
        margin-left: -20px;
        margin-right: -20px;

        &--top {
          margin-top: -20px;
        }

        &--bottom {
          margin-bottom: -20px;
        }
      }

      @include media-query(default) {
        margin-left: -40px;
        margin-right: -40px;

        &--top {
          margin-top: -40px;
        }

        &--bottom {
          margin-bottom: -40px;
        }
      }

      &--clip {
        border-radius: $border-radius-small;
        overflow: hidden;
      }
    }

    &__footer {
      @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));
      color: colors(white);
      margin-top: -40px;
      position: relative;
    }

    &__footer-graphic {
      max-height: 150px;

      &-autoverzekering {
        max-height: 100px;
      }

      &-zorgverzekering {
        max-height: 150px;
      }

      &--woonverzekering {
        max-height: 125px;
      }

      &-reisverzekering {
        max-height: 150px;
      }
    }
  }
}
