﻿.c-sortbar {
  &__item {
    white-space: nowrap;

    @include media-query(default) {
      display: inline-block;
    }
  }

  &__label {
    @include typography(zeta);
    display: inline-block;
    vertical-align: middle;
  }

  &__select {
    @include typography(zeta);
    display: inline-block;
    width: auto;
    vertical-align: middle;
    margin-left: $inuit-base-spacing-unit;

    @include media-query(palm) {
      margin-left: 0;
      width: 100%;
    }
  }
}
