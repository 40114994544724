//
// Reset for lists
//

%o-list-base,
.o-list-base {
  margin: 0;
  padding: 0;

  &.layout {
    margin-left: -$inuit-base-spacing-unit;
  }

  > li {
    list-style: none;
  }
}

.o-list-style--none {
  list-style: none;
}

%o-list-inline,
.o-list-inline {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;

  > li {
    @include inuit-font-size($inuit-base-font-size, none);
    display: inline-block;
  }
}

.o-list-inline--delimited,
%o-list-inline--delimited {
  > li + li {
    &::before {
      content: attr(data-splitter);
      @include typography(small);
      padding: 0 20px;
    }
  }
}

.o-list-links {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin-bottom: halve($inuit-base-spacing-unit);
  }

  &__item,
  a {
    color: colors(brand, cta);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: colors(brand, support);
    }
  }

  &--cta {
    .o-list-links__item,
    a {
      color: colors(brand, cta);
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: colors(brand, support);
      }
    }

    .o-list-links__current {
      color: colors(grey, dark);
    }
  }
}

.o-list-bullets {
  &,
  ul {
    list-style: none;

    li {
      margin-bottom: $inuit-base-spacing-unit;
      position: relative;

      &::before {
        content: "\2022";
        position: absolute;
        left: -15px;
      }
    }
  }

  &--tight {
    &,
    ul {
      li {
        margin-bottom: quarter($inuit-base-spacing-unit);
      }
    }
  }
}

.o-list-arrows {
  &,
  ul {
    list-style: none;
    padding-left: 0;

    li {
      position: relative;
      margin-bottom: quarter($inuit-base-spacing-unit);
      padding-left: $inuit-base-spacing-unit;
      background-image: url(#{$assetPathImages}icons/arrow-right.svg);
      background-position: left 12px;
      background-repeat: no-repeat;
    }
  }

  &.zeta {
    li {
      background-position: left 7px;
    }
  }

  &--white {
    li {
      background-image: url(#{$assetPathImages}icons/arrow-right-white.svg);
    }
  }
}

.o-list-numbered-block {
  list-style: none;
  margin: 0;
  padding: 0;

  > li,
  &__item {
    padding-top: double($inuit-base-spacing-unit);
    position: relative;
    text-align: center;

    &::before {
      @include typography(epsilon);
      font-family: $brand-face;
      font-weight: bold;
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      margin-left: -20px;
      height: 35px;
      width: 35px;
      border-width: 2px;
      border-style: solid;
      content: "2";
      text-align: center;
      border-radius: $border-radius-full;
    }

    @for $item from 1 through 10 {
      &:nth-child(#{$item}) {
        &::before {
          content: "#{$item}";
        }
      }
    }
  }
}
