.c-button-rating-active {
  input:checked + &--green,
  html.no-touchevents input + &--green:hover {
    background-color: colors(status, green);
    color: colors(white);
  }

  input:checked + &--orange,
  html.no-touchevents input + &--orange:hover {
    background-color: colors(status, orange);
    color: colors(white);
  }

  input:checked + &--red,
  html.no-touchevents input + &--red:hover {
    background-color: colors(status, red);
    color: colors(white);
  }

  input:checked + &--support,
  html.no-touchevents input + &--support:hover {
    background-color: colors(brand, support);
    color: colors(white);
  }
}
