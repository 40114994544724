.c-cookiebanner {
  opacity: 0;
  box-sizing: border-box;
  border-radius: $border-radius-small;
  border: 2px solid colors(grey, light);
  width: 90%;
  box-shadow: -1px 1px 20px 8px rgba(colors(black), 0.5);
  left: 5%;
  bottom: 20px;
  padding: 20px;
  transition: opacity 600ms;

  &--show {
    display: block !important;
  }

  &--appear {
    opacity: 1;
  }

  @include media-query(portable) {
    width: calc(100% - 20px);
    left: 10px;
    padding: 10px;

    .c-tabbox__tab-classic {
      padding: 6px 5px;
    }
  }
  position: fixed;
  z-index: 3000;

  @include media-query(palm) {
    bottom: 10px;

    &__button {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 20px;
    }

    p.c-message {
      margin-bottom: 20px;
    }
  }

  &__button--selectall {
    background: colors(status, green);

    &:hover {
      box-shadow: none;
      background: rgba(colors(status, green), 0.9);
    }
  }

  &__button--selectspecific {
    font-size: 0.9em;
    padding: 5px 10px;
    display: block;

    &:before {
      content: "";
      display: none !important;
    }

    &:hover {
      background: colors(brand, support);
    }
  }

  table.CybotCookiebotDialogDetailBodyContentCookieTypeTable {
    @include typography(zeta);
    border-spacing: 0;

    tr td {
      border-bottom: 1px solid colors(grey, light);
    }

    tr:nth-of-type(even) td {
      background-color: colors(grey, x-light);
    }

    td,
    th {
      padding: 4px 5px;
    }

    th {
      text-align: left;
    }
  }

  &__consenttab {
    &--tabcontent {
      @include media-query(default) {
        height: 27vh;
      }
      @include media-query(palm) {
        max-height: 25vh;
      }
      overflow-y: auto;
      background: colors(white);
      padding: 10px 15px;

      p {
        max-width: 700px;
      }
    }

    &--checkboxwrapper {
      margin-bottom: 10px;
      max-width: 800px;

      div {
        display: inline-block;
      }
    }
  }
}
@media screen and (min-width: 75em) {
  .c-cookiebanner {
    transform: translate(-50%, 0%);
    left: 50%;
    max-width: 1200px;
  }
}

// This is the template, somehow shows sometimes
.CookieDeclaration {
  display: none;
  // This is for the privacy page at https://www.gaslicht.com/privacybeleid
  /* stylelint-disable */
  #cookieprivacypolicy & {
    display: block;
  }
}

.CookieDeclarationType {
  border-radius: $border-radius;
}

.CookieDeclarationTable tr:last-child .CookieDeclarationTableCell {
  border-bottom: none;
}