@use "sass:math";

.c-video-overlay {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: $border-radius;

  &:before {
    content: "";
    display: block;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(colors(black), 0.2);
  }

  &--light:before {
    background-color: rgba(colors(white), 0.2);
  }

  &:after {
    content: "";
    display: block;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    transition: 600ms;
    transform-origin: center center;
    @include bg-image("icons/play.svg");
    background-position: center center;
    width: 60px;
    height: 60px;
  }

  &:hover {
    &:after {
      transform: scale(1.2);
    }
  }

  &__bordered {
    border: 1px solid rgba(colors(white), 0.5);
  }

  &--aspectratio {
    height: 0;
    overflow: hidden;
    padding-top: math.div(591.44px, 1127.34px) * 100%;
    background: white;
    position: relative;
  }

  &__inside {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    @include center-vertical();
    text-align: center;
    width: 100%;
    opacity: 0.8;
  }
}