.c-solarpanel-giveaway {
  &-home-block {
    position: relative;
    color: colors(white);
    @include gradient(colors(solarpanels, from) 27%, colors(solarpanels, to) 97%, colors(solarpanels, from), -80deg);

    &__image {
      @include media-query(palm) {
        display: none;
      }

      @include media-query(default) {
        position: absolute;
        top: -10%;
        bottom: -10%;
        right: 0;
        width: 50vw;
        @include bg-image("graphics/incentive/gaslicht-sunpower-house.webp");
        background-repeat: no-repeat;
        background-size: auto 100%;
      }

      &--mobile {
        @include media-query(default) {
          display: none;
        }

        @include media-query(palm) {
          height: 196px;
          margin-top: -40px;
          position: relative;

          img {
            max-width: 355px;
          }

          &:before {
            content: "";
            position: absolute;
            bottom: 16px;
            left: -10px;
            right: -10px;
            border-bottom: 1px solid colors(white);
          }
        }
      }
    }

    &__logo {
      z-index: 1;
      position: absolute;
      background-color: rgba(colors(white), 0.9);
      border-radius: $border-radius-tiny;

      @include media-query(default) {
        bottom: 50px;
        right: 20px;
      }

      @include media-query(palm) {
        bottom: 47%;
        right: 10px;

        img {
          width: 155px;
        }
      }
    }

    &__action-tag {
      z-index: 1;
      background-color: colors(brand, base);
      border-radius: $border-radius-small;
      top: 90px;

      @include media-query(desk) {
        position: absolute;
        right: 20%;
      }

      @include media-query(lap) {
        position: absolute;
        right: 20px;
      }

      @include media-query(palm) {
        position: relative;
        top: 0;
        right: 0;
        display: inline-block;
      }
    }
  }

  &-banner {
    position: relative;
    padding: 20px;
    color: colors(white);
    border-radius: $border-radius-small;
    @include gradient(colors(solarpanels, from) 27%, colors(solarpanels, to) 97%, colors(solarpanels, from), -80deg);

    @include media-query(default) {
      max-width: 500px;
    }

    &--in-affiliate-and-form {
      display: flex;
      align-items: center;
      width: 50%;
      border-radius: $border-radius-small 0 0 $border-radius-small;

      @include media-query(palm) {
        border-radius: $border-radius-small $border-radius-small 0 0;
        width: 100%;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: -14px;
      left: 0;
      @include bg-image("graphics/incentive/gaslicht-sunpower-fullhouse.webp");
      background-position: bottom right;
      background-size: auto 150px;
    }

    &--mobile {
      max-width: 400px;

      &:before {
        bottom: -8px;
        background-size: auto 90px;
      }
    }

    &--share {
      max-width: 650px;

      &:before {
        bottom: -19px;
        background-size: auto 200px;
      }
    }

    &--external {
      max-width: unset;
      transition: background 1000ms;
    }

    &__bg {
      &--blue {
        background: linear-gradient(to bottom, #6498ae 0, #7783af 100%);
      }
    }

    &__logo {
      position: relative;
      border-radius: $border-radius-tiny;
      background: rgba(colors(white), 0.9);

      img {
        width: 115px;
      }
    }

    &__action-tag {
      z-index: 2;
      position: absolute;
      top: -10px;
      left: 25px;
      background-color: colors(brand, base);
      border-radius: $border-radius-small;
    }
  }

  //Incentive + Giveaway

  &-giveaway {
    position: relative;
    height: 342px;
    border-radius: $border-radius;
    background-color: rgba(colors(white), 0.1);
    @include bg-image("graphics/incentive/solarpanels-sunpower-p3.webp");
    background-position: left -300px top;
    transform: skew(-6deg);

    &-visual {
      position: absolute;
      transform: skew(6deg);
    }
  }

  &-plus-sign {
    position: absolute;
    height: 50px;
    width: 50px;
    top: calc(50% - 25px);
    right: calc(50% - 30px);
    outline: 5px solid rgba(colors(white), 0.2);
    text-align: center;
    font-size: 60px;
    line-height: 45px;
    font-weight: bold;
    border-radius: $border-radius-full;
    color: colors(grey);
    background: linear-gradient(#fbfeff, #e7f8ff);
  }
}

//CANAL DIGITAAL

.c-incentive {
  &__bg {
    background-color: colors(incentive, secondary);
  }

  &__home {
    background-color: colors(incentive, secondary);
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      display: block;
      background: linear-gradient(81deg, #282828 6%, #1d1d1d 66%);
      top: 100px;
      width: 1100px;
      height: 600px;
      transform: rotate(31deg);
    }
  }

  &__package-page {
    margin-bottom: 5px;

    @include media-query(portable) {
      position: absolute;
      width: 200px;
      left: -220px;
    }
  }

  &__list {
    list-style: none none;
    padding-left: 0;
    margin-bottom: 15px;

    li {
      position: relative;
      @include bg-image("graphics/incentive/canaldigitaal-bullet.svg");
      background-position: left 2px;
      padding-left: 25px;
      padding-bottom: 15px;
      line-height: 1.2;
    }
  }

  &-sidebar {
    background-color: colors(incentive, secondary);
    color: colors(white);
    position: relative;
  }

  &-inputwidget {
    background-color: colors(incentive-pw, primary);
    color: colors(white);
    position: relative;
    padding-top: 10px;
    border-radius: 0 0 $border-radius $border-radius;
  }

  &__button {
    background-color: colors(incentive, primary);
    color: colors(incentive, secondary);
    font-weight: bold;

    &--nlziet {
      color: colors(white);
      background-color: #ff427c;
      font-weight: normal;
    }

    &--canaldigitaal {
      color: colors(white);
      background-color: colors(incentive, primary);
      font-weight: normal;

      @include media-query(palm) {
        width: 100%;
      }
    }
  }

  &__button--secondary {
    background-color: colors(incentive, secondary);
    color: colors(colors(white));
  }

  &__logo-inputwidget--xxxs {
    height: 11px;
  }

  &__logo-inputwidget--xxs {
    height: 14px;
  }

  &__logo-inputwidget--xs {
    height: 16px;
  }

  &__logo-inputwidget--s {
    height: 20px;
  }

  &__logo-inputwidget--m {
    height: 24px;
  }

  &__logo-inputwidget--l {
    height: 28px;
  }

  &__logo-inputwidget--xl {
    height: 35px;
  }

  &__logo-inputwidget--xxl {
    height: 60px;
  }

  &-blokker-banner {
    padding: 20px;
    color: colors(white);
    border-radius: $border-radius-small;
    @include gradient(colors(solarpanels, from) 27%, colors(solarpanels, to) 97%, colors(solarpanels, from), -80deg);
  }

  &-blokker-giftcard {
    position: relative;
    transform: rotate(-5deg);
    height: 115px;
    min-height: 115px;
    box-shadow: 0 0 30px rgba(colors(black), 0.4);
  }

  &-blokker-badge {
    position: absolute;
    top: 0;
    left: 150px;
    z-index: 2;
  }

  &-blokker-hint {
    position: absolute;
    top: -15px;
    left: 215px;

    @include media-query(palm) {
      top: -33px;
      left: 225px;
    }

    @include media-query(desk-wide) {
      left: 235px;
    }

    &-pointer {
      transform: rotate(50deg);

      @include media-query(palm) {
        transform: rotate(110deg);
      }

      @include media-query(desk-wide) {
        transform: rotate(110deg);
      }
    }
  }

  &__canaldigitaal-channels {
    height: 25px;
  }

  @mixin canaldigitaal-smaller-devices {
    position: relative;
    height: 175px;
  }

  &__canaldigitaal-devices {
    z-index: 2;

    .c-incentive-sidebar & {
      @include canaldigitaal-smaller-devices();
    }

    @include media-query(desk) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__canaldigitaal-sticker-wrapper {
    position: absolute;
    text-align: center;
    background-color: #140087;
    color: colors(white);
    border-radius: $border-radius;
    clip-path: polygon(30% 0%, 100% 0, 100% 70%, 70% 100%, 0 100%, 0% 30%);
    z-index: 4;

    @include media-query(palm) {
      left: 20px;
      bottom: 0;
      width: 120px;
      height: 120px;
    }

    @include media-query(lap) {
      left: 25%;
      bottom: 170px;
      width: 130px;
      height: 130px;
    }

    @include media-query(desk) {
      bottom: 20px;
      left: -40px;
      width: 150px;
      height: 150px;
    }

    .c-incentive-sidebar & {
      left: 5px;
      bottom: -15px;
      width: 110px;
      height: 110px;
    }
  }

  &__canaldigitaal-sticker {
    padding-left: 10px;
    padding-right: 10px;
    color: colors(white);
    @include center-vertical;

    @include media-query(palm) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .c-incentive-sidebar & {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &-pw {
    position: relative;

    &-home-block {
      overflow: hidden;
      color: colors(white);
      background-color: colors(incentive-pw, primary);

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 20px;
        bottom: -60px;
        left: 0;
        @include bg-image("graphics/incentive/blokker-background.png");
        background-position: bottom right;
        background-size: auto 400px;
        opacity: 0.3;

        @include media-query(desk-wide) {
          right: 20%;
        }

        @include media-query(palm) {
          right: -65px;
          bottom: -40px;
          background-size: auto 300px;
        }
      }
    }

    &-banner {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 60%;
      padding: 20px;
      color: colors(white);
      background-color: colors(incentive-pw, primary);
      border-radius: $border-radius-small 0 0 $border-radius-small;

      @include media-query(palm) {
        border-radius: $border-radius-small $border-radius-small 0 0;
        width: 100%;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: -10px;
        bottom: -25px;
        left: 0;
        @include bg-image("graphics/incentive/blokker-background.png");
        background-position: bottom right;
        background-size: auto 200px;
        opacity: 0.2;
      }
    }

    &-blokker-giftcard {
      transform: rotate(-5deg);
      height: 180px;
      box-shadow: 0 0 30px rgba(colors(black), 0.4);

      @include media-query(palm) {
        height: 150px;
      }
    }

    &-amazon-tiles {
      display: flex;
      justify-content: center;
      align-items: stretch;
      align-content: center;
      gap: 10px;

      @include media-query(portable) {
        max-width: 300px;
        margin: 0 auto;
        flex-wrap: wrap;
      }

      &__product {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        color: colors(white);
        flex-basis: 25%;
        flex-shrink: 0;
        max-width: 140px;

        @include media-query(desk) {
          justify-content: center;
          aspect-ratio: 6/9;
        }

        @include media-query(portable) {
          flex-basis: 50%;
          padding: 10px;
        }

        &--delivery {
          background-image: linear-gradient(217deg, #02a9e1 0%, #2c88ae 100%);
        }

        &--video {
          background-image: linear-gradient(217deg, #ff6239 0%, #cc0d38 100%);
        }

        &--gaming {
          background-image: linear-gradient(217deg, #e41f64 0%, #a90068 100%);
        }

        &--photos {
          background-image: linear-gradient(217deg, #37c2b3 0%, #018578 100%);
        }
      }
    }
  }
}
