.select2-container--classic {
  @import "multiple";

  .select2-search__field {
    width: 100% !important;
  }

  .select2-dropdown {
    background-color: colors(white);
    border: 1px solid transparent;
  }

  .select2-dropdown--above {
    margin-bottom: 0;
  }

  .select2-dropdown--below {
    margin-top: 0;
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {
    @include typography(zeta);
    border-bottom: 1px solid colors(grey, light);

    &[role=group] {
      padding: 0;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: colors(brand, support);
    color: colors(white);
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--open .select2-dropdown {
    border-color: colors(grey, light);
  }
}
