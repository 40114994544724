.u-position {
  &--relative {
    position: relative;
  }

  &--absolute {
    position: absolute;
  }

  &--static {
    position: static;
  }

  &--fixed {
    position: fixed;
  }

  &--sticky {
    position: sticky;
  }

  &--top {
    top: 0;
  }

  &--right {
    right: 0;
  }

  &--bottom {
    bottom: 0;
  }

  &--left {
    left: 0;
  }

  &--full {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
