.select2-selection--multiple {
  .select2-selection__rendered {
    list-style: none;
    margin: 0;
  }

  .select2-selection__clear {
    display: none;
  }

  .select2-selection__choice {
    border: 1px solid colors(grey, dark);
    border-radius: $border-radius-small;
    background-color: colors(grey, x-light);
    border: none;
    cursor: default;
    padding: 10px;
    margin-top: 5px;
    @include typography(zeta);
  }

  .select2-selection__choice__remove {
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
    font-size: 28px;
    line-height: 19px;
    vertical-align: top;

    &:hover {
      color: colors(status, red);
    }
  }

  &:focus {
    outline: none;
  }
}
