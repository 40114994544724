
.c-loading {
  > * {
    opacity: 0.3;
  }

  @supports (-webkit-filter: blur(2px)) or (filter: blur(2px)) {
    filter: blur(5px);

    > * {
      opacity: 1;
    }
  }
}