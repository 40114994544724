.content-image {
  display: block;
  max-width: 50%;
  margin-right: halve($inuit-base-spacing-unit);

  @include media-query(palm) {
    max-width: 100%;
    width: 100%;
  }


  &[align="left"] {
    margin-right: $inuit-base-spacing-unit;
  }
}
