//
// Mixins to output containers and widths
//

@mixin container-setup($map) {
  @each $profile, $width in $map {
    .#{$profile} {
      position: relative;
      width: $width;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding-left: $inuit-base-spacing-unit;
      padding-right: $inuit-base-spacing-unit;

      @include media-query(palm) {
        padding-left: halve($inuit-base-spacing-unit);
        padding-right: halve($inuit-base-spacing-unit);
      }
    }
  }
}
