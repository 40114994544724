$comparison-modal-open-close-height: 45px;

.c-comparison-modal {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.7s ease;

  &.hidden {
    transform: translateY(calc(100% + #{$comparison-modal-open-close-height}));

    @include ie {
      display: none;
    }
  }

  &.closed {
    transform: translateY(100%);
  }

  &.open {
    transform: translateY(0);
  }

  &__wrapper {
    background-color: colors(grey, x-light);
  }

  &__button-wrapper {
    @include media-query(palm) {
      position: absolute;
      bottom: 10px;
    }

    @include media-query(default) {
      min-height: 102px; // to ensure a smoother transition when there are no packages selected
    }
  }

  &__button {
    @include media-query(default) {
      @include center-vertical;
    }
  }

  &__tab {
    @include media-query(default) {
      margin-right: 5px;
      padding-right: 5px;
      padding-left: 15px;
    }

    @include media-query(palm) {
      @include typography(zeta);
      padding: 12px 4px 13px;
    }

    &.current {
      .c-comparison-modal.open & {
        background-color: rgba(colors(white), 0.2);
      }
    }

    @include media-query(palm) {
      &.current {
        .c-comparison-modal.open & {
          padding-left: 15px;
          margin-right: 15px;
        }
      }

      &:not(.current) {
        .c-comparison-modal.open & {
          display: none;
        }
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__open-close {
    background-color: rgba(brand-colors(nav, base), 0.9);
    color: colors(white);
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: -$comparison-modal-open-close-height;
    line-height: $comparison-modal-open-close-height;

    &:hover {
      text-decoration: none;
    }
  }

  &__number-of-packages {
    @include size(20px);
    background-color: rgba(colors(black), 0.5);
    border-radius: $border-radius-full;
    display: inline-block;
  }

  &__arrow {
    @include bg-image("icons/arrow-up-white.svg");
    @include center-vertical;
    @include size(15px, 8px);
    content: "";
    display: inline-block;
    margin-left: 10px;
    position: absolute;
  }

  &.open &__arrow {
    transform: translateY(-50%) rotate(180deg);
  }

  &__packages {
    @include media-query(palm) {
      padding-bottom: 60px; // make space for the cta button on mobile
    }

    @include supports-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; // 3 equal-width columns

      @include media-query(palm) {
        grid-gap: 10px;
      }

      @include media-query(default) {
        grid-gap: 20px;
      }
    }

    @mixin fallback-styles {
      > div {
        float: left;
        width: 32%; // 32% + 2% + 32% + 2% + 32% = 100%
      }

      > div + div {
        // all divs except the 1st should get margin-left
        margin-left: 2%;
      }
    }

    @include ie {
      // IE 10 and 11
      @include fallback-styles;
    }

    @include does-not-support-grid {
      // Edge
      @include fallback-styles;
    }
  }

  &__package {
    background-color: colors(white);
    border-radius: $border-radius-small;
    border: 1px solid colors(grey, light);
    height: 100%;
    position: relative;

    &--loading {
      position: relative;
      text-align: center;

      &:before {
        content: "Pakket wordt toegevoegd...";
        left: 0;
        right: 0;
        @include typography(zeta);
        @include center-vertical;
      }
    }
  }

  &__logo {
    position: relative;

    @include media-query(palm) {
      min-height: 40px;
    }

    @include media-query(default) {
      min-height: 50px;
    }

    img {
      @include center;
      max-height: 100%;
    }
  }

  &__close {
    @include size(20px);
    background-color: colors(grey, dark);
    border-radius: 100%;
    display: block;
    line-height: 20px;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;

    img {
      @include center;
      width: 36%;
    }
  }
}
