.c-floating-provider-logo {
  position: relative;
  right: 0;
  margin-top: -30px;
  background: colors(white);
  padding: 7px;
  border: 1px solid colors(grey, x-light);
  float: right;
  text-align: center !important;
  box-shadow: 2px 2px 5px colors(grey, light);

  img {
    max-height: 50px;
    width: 100%;
  }
}
