﻿.c-main-navigation {
  @keyframes nav__item {
    from {
      opacity: 0;
      transform: translateY(100px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;

    @include media-query(default) {
      white-space: nowrap;
    }
  }

  &__hamburger {
    display: inline-block;
    margin-right: 15px;
    width: 26px;
    position: relative;
    vertical-align: middle;
    margin-bottom: 3px;

    @include media-query(lap) {
      width: 16px;
      margin-right: 10px;
    }

    @include media-query(palm) {
      display: none;
    }


    &,
    &:before,
    &:after {
      height: 3px;
      border-radius: $border-radius-tiny;
      background-color: colors(white);

      @include media-query(palm) {
        background-color: colors(brand, support);
      }

      @include media-query(lap) {
        height: 2px;
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
    }

    &:before {
      top: -9px;

      @include media-query(lap) {
        top: -7px;
      }
    }

    &:after {
      bottom: -9px;

      @include media-query(lap) {
        bottom: -7px;
      }
    }
  }

  &__list-item {
    @include typography(default);
    color: colors(white);
    letter-spacing: 0.4px;
    display: inline-block;
    height: 50px;
    vertical-align: top;

    @media screen and (max-width: 63.9375em) {
      @include inuit-font-size($inuit-heading-size-6, 24px);
      letter-spacing: 0;
    }

    &--sticky {
      @include typography(default);
      color: colors(white);
      display: inline-block;

      @include media-query(default) {
        text-align: left;
      }

      &-helpdesk {
        @include media-query(lap) {
          display: none;
        }
      }

      &-link,
      a {
        text-decoration: none;
        font-weight: bold;
        display: block;
        padding-top: 0;
        padding-bottom: 0;

        @include media-query(desk) {
          height: 60px;
          line-height: 50px;
          margin-top: 10px;
          padding-left: 12px;
          padding-right: 12px;
        }

        @include media-query(desk-wide) {
          padding-left: 15px;
          padding-right: 15px;
        }

        @include media-query(lap) {
          height: 60px;
          line-height: 50px;
          margin-top: 10px;
          padding-left: 12px;
          padding-right: 12px;
        }

        @include media-query(palm) {
          height: 45px;
          line-height: 35px;
          margin-top: 5px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }

    &-link,
    a {
      text-decoration: none;
      color: colors(brand, support);
      position: relative;
      transition: $swift-ease-in-out;
      height: 50px;
      padding: 11px 19px 9px;
      display: block;

      @include media-query(lap) {
        padding: 11px 14px 9px;
      }

      @include media-query(default) {
        color: colors(white);


        &.c-main-navigation__current {
          background-color: brand-colors(nav, hover);
          text-decoration: none;
          font-weight: bold;

          @include media-query(palm) {
            padding-right: 40px;
          }
        }

        &.c-main-navigation__active,
        &:hover {
          background-color: brand-colors(nav, hover);
          text-decoration: none;
        }
      }

      @media screen and (max-width: 63.9375em) {
        padding: 14px 8px 12px;
        font-weight: normal;
      }
    }

    @include media-query(desk) {
      &--wide {
        a,
        button {
          padding-left: 29px;
          padding-right: 29px;
        }
      }
    }
  }

  &__list-item-tab {
    border-radius: $border-radius $border-radius 0 0;

    @include media-query(palm) {
      border-radius: $border-radius-small $border-radius-small 0 0;
    }

    &.c-main-navigation__active {
      background-color: colors(white);
      color: brand-colors(nav, hover);
      z-index: 2;
      position: relative;
    }
  }

  &__item-arrow {
    a {
      position: relative;
      padding-right: 40px;

      @media screen and (max-width: 63.9375em) {
        padding-right: 25px;
      }

      @include media-query(default) {
        background-color: brand-colors(nav, hover);
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0;
        border-color: colors(white) transparent transparent;

        @media screen and (max-width: 63.9375em) {
          right: 10px;
          bottom: 23px;
        }
      }
    }
  }

  @include media-query(palm) {
    display: none;
    border-top: 1px solid colors(brand, support);
    padding-top: 70px;
    position: absolute;
    min-height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    background-color: colors(white);
    z-index: 3;
    transform: translateX(-100%);
    transform-origin: right top;
    opacity: 0;
    text-align: center;
    transition: $swift-ease-in-out;
    transition-duration: 500ms;

    &__list-item {
      @include typography(delta);
      display: block;
      padding: 20px 0;
      height: auto;

      &-link,
      a {
        width: 100%;
        display: block;
        padding: 0;
        height: auto;
        @include typography(delta);
        font-weight: bold;
      }

      &--mobile {
        padding: 0;
        display: inline-block;

        a {
          color: colors(white);
          @include typography(zeta);
          padding: 15px;

          &.c-main-navigation__active,
          &:hover {
            background-color: brand-colors(nav, hover);
          }
        }
      }
    }

    &__item-arrow {
      a {
        display: inline-block;
        padding-left: 0;
        padding-right: 15px;

        &::before {
          bottom: 10px;
          transform: rotate(-90deg);
          border-top-color: colors(brand, support);
          bottom: 10px;
          right: 0;
        }

        &:hover {
          &::before {
            border-color: colors(brand, cta) transparent transparent;
          }
        }
      }
    }

    .c-main-navigation__open:checked ~ .c-site__navigation &,
    &--active {
      transform: translateX(0%);
      opacity: 1;
    }

    &--active {
      .c-main-navigation__list-item {
        opacity: 0;
        animation: nav__item ease-in 1;
        animation-fill-mode: forwards;
        animation-timing-function: $swift-ease-in-out-timing-function;
        animation-duration: 500ms;
        animation-delay: 0s;

        @for $item from 1 through 10 {
          &:nth-child(#{$item} of .c-main-navigation__list-item) {
            animation-delay: #{($item) * 100 + 300}ms;
          }
        }
      }
    }
  }
}

.c-subnavigation {
  @include media-query(default) {
    .c-main-navigation__list-item + & {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      color: colors(grey, dark);
      background-color: colors(white);
      z-index: 1001;
      white-space: wrap;
      @include typography(default);
      box-shadow: 0 10px 35px 0 rgba(colors(black), 0.35);
    }

    .c-main-navigation__list-item:hover + & {
      display: block;
    }

    .c-site__navigation:has(.c-main-navigation__list-item:hover + &):after,
    .c-site__navigation:has(&:hover):after {
      content: "";
      position: absolute;
      top: 50px;
      height: 150vh;
      left: 0;
      right: 0;
      background-color: rgba(colors(black), 0.5);
      z-index: 1000;
      pointer-events: none;
    }

    .c-main-navigation__list-item:has(+ &) a:hover,
    .c-main-navigation__list-item:has(+ &:hover) a {
      background-color: brand-colors(nav, hover);

      &:before {
        content: "";
        $navArrowSize: 10px;
        position: absolute;
        bottom: -$navArrowSize;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -$navArrowSize;
        border-style: solid;
        border-width: $navArrowSize $navArrowSize 0 $navArrowSize;
        border-color: darken(brand-colors(nav, hover), 3%) transparent transparent transparent;
        pointer-events: none;
        z-index: 1100;

        @include media-query(palm) {
          display: none;
        }
      }
    }

    &:hover {
      display: block;
    }
  }

  @include media-query(palm) {
    .c-main-navigation__list-item:has(+ &) [data-subnavigation] {
      pointer-events: none;
    }
    position: absolute;
    min-height: 100vh;
    height: 100vh;
    padding-top: 30px;
    top: 0;
    overflow: hidden;
    z-index: 3;
    left: 0;
    right: 0;
    background-color: colors(white);
    transform: translateX(-100%) translateY(0%);
    transform-origin: left center;
    transition: $swift-ease-in-out;
    opacity: 0;
    display: block !important;
    white-space: wrap;
    @include typography(default);

    .c-main-navigation__list-item:hover + &,
    &:hover,
    &--active {
      transform: translateX(0%) translateY(0%);
      opacity: 1;
      height: auto;
    }
  }
}

