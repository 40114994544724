.c-wvhj {
  &__home {
    position: absolute;
    display: none;

    @media screen and (min-width: 64em) {
      display: block;
      left: -225px;
      top: -30px;

      img {
        width: 185px;
      }
    }

    @media screen and (min-width: 55em) and (max-width: 64em) {
      display: block;
      top: -10px;
      left: -200px;

      img {
        width: 170px;
      }
    }

    @media screen and (max-width: 73em) {
      display: none;
    }
  }

  &-thanks {
    border: 1px solid colors(grey, light);
    padding: 20px 30px 30px 20px;
    margin-bottom: 40px;

    //Overflow button when hiding price graphics
    &:not(:has(&__prices)) {
      @include media-query(desk) {
        height: 150px;
      }
    }

    @media screen and (max-width: 1150px) {
      padding-bottom: 0;
      padding-right: 20px;
    }

    &__cta {
      @media screen and (max-width: 1150px) {
        margin-bottom: 10px;
      }
    }

    &__prices {
      @media screen and (min-width: 1150px) {
        position: absolute;
        left: 15px;
        bottom: -50px;
        width: 425px;
      }
    }

    &__logo {
      float: left;
      margin-right: 20px;

      @include media-query(palm) {
        float: right;
        position: relative;
        margin-right: -38px;
        margin-top: -38px;
      }
    }
  }
}
