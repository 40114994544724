$meterOffset: 98.45px;

.c-benchmark {
  &-overlay,
  &-result {
    &__meter {
      svg {
        .c-benchmark__meter-pointer {
          --meter-offset: #{$meterOffset};
          transform: translate($meterOffset,$meterOffset) rotate(-180deg) translate((-$meterOffset),(-$meterOffset));
          transition: transform 750ms 100ms cubic-bezier(0.54,0.09,0.24,0.84);
        }
      }
    }
  }

  &-result {
    background-color: #F1F6FB;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @include media-query(default) {
      padding: 7px 0 9px;
      width: 100%;
      max-width: 150px;
    }

    &:hover {
      text-decoration: none;
    }

    &__title {
      line-height: 1;
      @include typography(zeta);
      white-space: nowrap;

      @include media-query(lap) {
        @include typography(small);
      }
    }

    &__percentage {
      font-family: $spec-face;
      color: colors(brand, support);
      line-height: 1;
    }

    &__meter {
      padding-top: 10px;

      svg {
        display: block;
        width: 45px;
        height: 34px;
      }

      &--small {
        svg {
          width: 33px;
          height: 25px;
        }
      }
    }

    &--no-score {
      opacity: 0.5;
      display: inline-flex;

      svg .c-benchmark__meter-pointer {
        transform: translate($meterOffset,$meterOffset) rotate(-180deg) translate(calc($meterOffset * -1),calc($meterOffset * -1));
        fill: #D1E1E9;
      }
    }
  }

  &-overlay {
    overflow-x: hidden;
    padding-bottom: 20px;
    min-height: 100vh;

    &__meter-bg {
      @include bg-image("graphics/gaslicht/benchmark-meter-bg.svg");
      background-position: bottom center;
      width: 640px;
      max-width: 100vw;
      height: 160px;
      margin-bottom: 60px;
      position: relative;

      @include media-query(palm) {
        height: 90px;
        margin-bottom: 35px;
        background-size: auto 120px;
      }
    }

    &__meter {
      position: absolute;
      bottom: -51px;
      left: 50%;
      transform: translateX(-50%);

      @include media-query(palm) {
        bottom: -38px;
      }

      &-score {
        width: 80px;
        height: 80px;
        padding-top: 19px;
        border-radius: $border-radius-full;
        @include inuit-font-size(36px, 36px);
        @include font-weight--italic-bold;
        color: colors(brand, support);
        background-color: colors(white);
        border: 2px solid colors(brand, support);
        position: absolute;
        bottom: 13px;
        left: 50%;
        margin-left: -41px;
        text-align: center;
        box-shadow: 0 2px 4px 0 rgba(colors(black), 0.1);
        transform: scale(0.75);
        transition: transform 500ms 750ms cubic-bezier(0.29,0.57,0.58,1.43);

        @include media-query(palm) {
          width: 50px;
          height: 50px;
          padding-top: 11px;
          margin-left: -26px;
          @include inuit-font-size(22px, 22px);
        }

        * {
          opacity: 0;
          transition: opacity 1000ms 0ms;
        }

        &--visible {
          transform: scale(1);

          * {
            opacity: 1;
          }
        }

        &__decimal {
          font-size: 0.5em;
          line-height: 1;
          vertical-align: super;
          position: relative;
          margin-left: 1px;
          top: 0.1em;
        }
      }

      svg {
        display: block;
        width: 200px;
        height: 151px;

        @include media-query(palm) {
          width: 150px;
          height: 113px;
        }
      }
    }

    &__package {
      background-color: rgba(colors(white), 0.6);
      padding: 20px 30px;
      margin: 0 20px 20px;
      border-radius: $border-radius-tiny;

      @include media-query(palm) {
        padding: 10px;
        margin: 0 10px 20px;
      }
    }

    &__thumb {
      @include media-query(palm) {
        padding: 0 10px;
      }

      @include media-query(default) {
        position: absolute;
      }

      &--rotated {
        transform: rotate(180deg);
      }
    }

    &__usp {
      background-color: colors(white);
      box-shadow: 0 2px 5px 0 rgba(colors(black), 0.2);
      border-radius: $border-radius-tiny;
      padding: 5px 10px 5px 20px;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      @include media-query(palm) {
        @include typography(zeta);
      }

      @include media-query(default) {
        white-space: nowrap;
        @include overflow--ellipsis;
        width: auto;
        max-width: 100%;
      }
    }
  }

  &-negative,
  &-positive {
    &:empty {
      min-height: 50px;

      &:before {
        color: colors(grey);
        font-style: italic;
      }
    }
  }

  &-positive {
    &:empty {
      &:before {
        content: attr(data-empty-benchmark-positive);
      }
    }
  }

  &-negative {
    &:empty {
      &:before {
        content: attr(data-empty-benchmark-negative);
      }
    }
  }
}
