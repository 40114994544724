﻿$top5WrapperBreakpoint: 570px;

.c-top5 {
  &-wrapper {
    max-width: 740px;
    position: relative;
    container-type: inline-size;
    container-name: top5wrapper;

    @include media-query(default) {
      padding-right: 20px;
    }
  }

  &__item {
    position: relative;
    display: block;
    color: colors(grey, dark);
  }

  &__item--disabled {
    filter: grayscale(1) opacity(0.6);

    & * {
      pointer-events: none;
    }
  }

  &__rank {
    text-align: center;
  }

  &__logo-wrapper {
    text-align: center;

    &--small {
      height: 30px;
      display: block;
    }
  }

  &__logo {
    max-height: 40px;
    width: auto;
    display: inline-block;
    max-width: 75px;

    &--small {
      max-height: 30px;
      max-width: 65px;
      width: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__packagename {
    font-weight: normal;

    @include media-query(palm) {
      text-align: right;
    }
  }

  &__premium {
    text-align: left;
  }

  &__cta {
    min-width: 40px;

    &:after {
      @include center;
      content: "";
      display: block;
      width: 14px;
      height: 10px;
      transform: translate(-50%,-50%) rotate(90deg);
      background-image: url(#{$assetPathImages}icons/arrow-up-white.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 14px 10px;
    }

    @include media-query(palm) {
      display: inline-block;
      width: auto;
       
      .c-button-top5__button & {
        height: 100%;
        width: 100%;
        opacity: 0;
      }
    }
  }

  &__curly-pointer {
    position: absolute;

    @include media-query(default) {
      transform: translateX(-22px) translateY(15px);
    }
    color: colors(grey);

    svg {
      height: 35px;
    }
  }

  &__compare-tooltip {
    @include media-query(default) {
      width: 700px;
    }

    .js-tooltip-trigger {
      display: none;
    }
  }
}


.c-button-top5 {
  display: grid;
  --top5-summary-minwidth: 0;
  grid-template-columns: [rank] max-content [logo] 95px [summary] minmax(var(--top5-summary-minwidth), 1fr) [discount] max-content [amount] max-content [button] max-content [grid-end];

  @container top5wrapper (min-width: #{$top5WrapperBreakpoint}) {
    & {
      --top5-summary-minwidth: 220px;
    }
  }

  &__row {
    display: grid;
    align-items: center;
    grid-template-columns: subgrid;
    grid-column: span 6;
    padding: 7px 10px;
    margin: 5px 0;

    @include media-query(default) {
      column-gap: 15px;
    }

    @include media-query(palm) {
      column-gap: 20px;
      padding-bottom: 2px;

      &:nth-child(n+4) {
        display: none;
      }
    }
  }

  &__rank {
    grid-column-start: rank;
    grid-row-start: 1;
    justify-self: center;
  }

  &__logo {
    grid-column-start: logo;
    grid-row-start: 1;
    justify-self: center;
  }

  &__summary {
    justify-self: start;

    @container top5wrapper (min-width: #{$top5WrapperBreakpoint}) {
      & {
        grid-column-start: summary;
        grid-row-start: 1;
      }
    }

    @container top5wrapper (max-width: #{$top5WrapperBreakpoint}) {
      & {
        grid-column: rank / end;
        grid-row-start: 2;
      }
    }
  }

  &__discount {
    grid-column-start: discount;
    grid-row-start: 1;
    justify-self: end;
  }

  &__amount {
    grid-column-start: amount;
    grid-row-start: 1;
    justify-self: end;
  }

  &__button {
    grid-column-start: button;
    justify-self: end;

    @container top5wrapper (max-width: #{$top5WrapperBreakpoint}) {
      & {
        grid-row: 1 / span 2;
      }
    }

    @include media-query(palm) {
      grid-column: 1 / -1;
      justify-self: stretch;
      align-self: stretch;
    }
  }
}