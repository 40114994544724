﻿.c-usp {
  &__item {
    padding-left: double($inuit-base-spacing-unit);
    position: relative;

    &::before {
      background: url(#{$assetPathImages}icons/check.svg) no-repeat center center;
      background-size: contain;
      content: "";
      display: block;
      height: 30px;
      left: 0;
      position: absolute;
      top: 0;
      width: $inuit-base-spacing-unit;
    }

    .c-usp--white & {
      &::before {
        background: url(#{$assetPathImages}icons/check-white.svg) no-repeat center center;
      }
    }

    &--white {
      &::before {
        height: 110px;
        background: url(#{$assetPathImages}icons/check-white.svg) no-repeat center center;
      }
    }

    &--no-check {
      &::before {
        background-image: none;
      }
    }

    &.layout__item {
      padding-left: $inuit-base-spacing-unit;

      &::before {
        left: $inuit-base-spacing-unit;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}
