.c-comparison-item-link {
  max-width: 0;
  overflow: hidden;
  opacity: 0;
  transition: 100ms all;
  transition-timing-function: $swift-ease-in-out-timing-function;
  position: relative;

  & ~ .c-inputfields__label {
    transition: max-width 300ms, color 150ms 50ms, letter-spacing 0ms;
    transition-timing-function: $swift-ease-in-out-timing-function;
    max-width: 100%;
  }

  &--visible {
    opacity: 1;
    max-width: 100%;
    transition: 0ms all;

    & ~ .c-inputfields__label {
      color: transparent;
      letter-spacing: -5px;
      max-width: 0;
      padding-left: 20px !important;
    }

    @include media-query(desk) {
      & ~ .c-inputfields__label {
        transition: max-width 300ms, color 150ms, letter-spacing 0ms 300ms;
      }
    }
  }
}

.c-comparison-list {
  $collapse-bar-height: 33px;

  &--init {
    min-height: 100vh;

    .c-comparison-list__item--result {
      display: none;
    }
  }

  &--loading {
    min-height: 2000px;
  }

  &-loader {
    display: none;
    position: sticky;
    height: 0;
    z-index: 1;
    top: 20vh;

    &--gaslicht {
      top: 30vh;
    }

    @include media-query(default) {
      width: 300px;
      left: 50%;
    }

    @include media-query(palm) {
      padding: 0 60px;
    }

    @include media-query(lap) {
      transform: translateX(-50%);
    }
  }

  &--loading .c-comparison-list__item--result,
  &--loading .c-comparison-list__item--loading {
    > * {
      opacity: 0.3;
    }

    @supports (-webkit-filter: blur(2px)) or (filter: blur(2px)) {
      filter: grayscale(100%) blur(2px) opacity(0.5);

      > * {
        opacity: 1;
      }
    }
  }

  &--hide-sort-wrapper .c-sort-wrapper {
    display: none;
  }

  @keyframes comparison-list__item {
    from {
      opacity: 0;
      transform: translateY(100px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes comparison-list__item--zero {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &__item--result {
    html.cssanimations & {
      opacity: 0;
      animation-name: comparison-list__item;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: $swift-ease-in-out-timing-function;
      animation-duration: 750ms;
      animation-delay: 0s;
    }
  }

  &__item--underlined::after {
    content: "";
    position: absolute;
    height: 0;
    bottom: -7px;
    left: -2px;
    right: -2px;
    border-top: 1px solid colors(white);

    @include media-query(desk-wide) {
      bottom: -13px;
    }
  }

  &__item--more-info {
    padding-bottom: $collapse-bar-height;

    > .u-border-radius {
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  &__item--mobiletest {
    border-width: 1px !important;
    border-style: solid;
    border-color: colors(grey, light);
  }

  &__item--zero {
    position: relative;
    border: 2px solid colors(status, orange);

    html.cssanimations & {
      opacity: 0;
      animation-name: comparison-list__item--zero;
      animation-delay: 0.3s !important;
    }
  }

  &__item--duplicate-result {
    display: none;
    animation-delay: 0s !important;
  }

  &__item--retention {
    border: 2px solid colors(brand, cta);
  }

  &__item--green {
    box-shadow: 0 0 8px 0 rgba(colors(status, green), 0.4);

    &.c-comparison-list__item--mobiletest {
      border-color: colors(status, green);
    }
  }

  &__item--blue {
    box-shadow: 0 0 8px 0 rgba(colors(brand, support), 0.4);

    &.c-comparison-list__item--mobiletest {
      border-color: colors(brand, support);
    }
  }

  &__item--crashtest {
    box-shadow: 0 0 8px 0 rgba(brand-colors(nav, base), 0.4);

    &.c-comparison-list__item--mobiletest {
      border-color: brand-colors(nav, base);
    }
  }

  &__item--instant {
    html.cssanimations & {
      animation-delay: 1ms !important;
      animation-duration: 1ms !important;
    }
  }

  &__item--blueprint {
    display: none;
    height: 209px;
    animation: blueprint-background 3s ease infinite;
    opacity: 0.5;
    background: linear-gradient(to left, colors(grey, x-light), colors(white));
    background-color: colors(grey, x-light);
    background-size: 400% 400%;
    border-radius: $border-radius;

    .c-comparison-list--loading-more ~ &,
    .c-comparison-list--loading & {
      display: block;
    }
  }

  &__item--disabled {
    filter: grayscale(1) opacity(0.6);

    & * {
      pointer-events: none;
    }

    & .c-button {
      pointer-events: initial !important;
    }
  }

  &__item--invalid {
    filter: grayscale(1) opacity(0.6);
    pointer-events: none;
  }

  &__item--grey {
    filter: grayscale(1) opacity(0.9);
  }

  $tab-border-color: colors(grey, light);
  $tab-border-alpha: 0.5;

  &__item--interactive:not(:has(.c-tabbox__tab-classic--current)) {
    .c-tabbox__tab-classic {
      transition: $swift-ease-in-out;
      background-color: transparent;
      opacity: 0.5;

      .no-touchevents & {
        border-color: transparent;
      }
    }

    .c-comparison-list__compare-button-wrapper:not(:has(:checked)) {
      .no-touchevents & {
        transition: $swift-ease-in-out;
        opacity: 0;
      }
    }

    &:hover {
      .c-tabbox__tab-classic {
        background-color: rgba(colors(grey, x-light), 0.4);
        opacity: 1;

        .no-touchevents & {
          border-color: rgba($tab-border-color, $tab-border-alpha);
        }
      }

      .c-comparison-list__compare-button-wrapper {
        .no-touchevents & {
          opacity: 1;
        }
      }
    }
  }

  &__summary {
    .c-comparison-list__item--more-info & {
      border-bottom: 0;
    }
  }

  &__upsell {
    background-color: rgba(colors(white), 0.6);
    padding: 20px 20px 0;
    border-radius: 0 0 $border-radius $border-radius;
    margin-top: -20px;

    @include media-query(palm) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .c-comparison-list__item--more-info & {
      padding-top: 0;
      margin-top: $collapse-bar-height;
    }
  }

  &__highlight-label {
    border-radius: $border-radius-small;
  }

  &__usp-label {
    border-radius: $border-radius 0 $border-radius-tiny;
    padding-left: 20px;
    padding-right: 10px;
    color: colors(white);
    position: absolute;
    z-index: 1;
    @include typography(zeta);
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }

    .c-comparison-list__item--retention &,
    .c-comparison-list__item--zero & {
      top: -1px;
      left: -1px;
    }
  }

  &__change-filters-message {
    color: colors(brand, support);
    display: block;
    position: absolute;

    @include media-query(palm) {
      top: 10px;
      left: 20px;
    }

    @include media-query(default) {
      top: 70px;
      left: 80px;
    }

    svg {
      position: absolute;
      bottom: 12px;
      right: -10px;
      transform-origin: bottom right;

      @include media-query(palm) {
        transform: scale(-0.5, 0.5);
      }

      @include media-query(default) {
        transform: scaleX(-1);
      }
    }
  }

  &__no-results-message {
    position: relative;

    &:before {
      content: ":(";
      line-height: 1.3;
      opacity: 1;
      color: rgba(gradients(gradient1, to), 0.7);
      display: inline-block;

      @include media-query(palm) {
        font-size: 50px;
      }

      @include media-query(lap) {
        font-size: 100px;
      }

      @include media-query(desk) {
        font-size: 200px;
      }
    }

    &--no-smile {
      &:before {
        display: none;
      }
    }

    .c-comparison-list--loading & {
      > * {
        opacity: 0.3;
      }

      @supports (-webkit-filter: blur(2px)) or (filter: blur(2px)) {
        filter: grayscale(100%) blur(2px);

        > * {
          opacity: 1;
        }
      }
    }
  }

  &__more-results-button {
    .c-comparison-list--loading-more ~ &,
    .c-comparison-list--loading ~ & {
      visibility: hidden;
      margin-bottom: 320px;
    }
  }

  &__remove-button {
    @include size(20px);
    background-color: colors(grey, dark);
    border-radius: 100%;
    line-height: 20px;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -9px;

    img {
      @include center;
      width: 36%;
    }
  }

  &__collection {
    transform: translateY(-19px);

    html.cssanimations & {
      opacity: 0;
      animation-name: comparison-list__item--zero;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: $swift-ease-in-out-timing-function;
      animation-duration: 750ms;
      animation-delay: 1s !important;
    }
  }

  &__counter {
    @include typography(beta);
    font-style: normal;

    &--original {
      position: relative;
      @include inuit-font-size($inuit-heading-size-4, $inuit-heading-size-4-lineheight);
      vertical-align: text-bottom;
      font-style: normal;
      padding-right: 4px;

      &:after {
        content: "";
        border: solid colors(grey, light);
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-45deg);
        position: relative;
        top: -3px;
      }
    }
  }

  @keyframes premium--new {
    from {
      transform: translateX(-50px);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &__premium--new {
    background-color: colors(white);
    z-index: 1;
    position: relative;
    padding-left: 30px;

    &[data-lazyload-classname] {
      transform: translateX(-75px);
      opacity: 0;
    }

    &--inview {
      animation-name: premium--new;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: $swift-ease-in-out-timing-function;
      animation-duration: 1000ms;
      animation-delay: 500ms;
    }

    &:after {
      content: "";
      border: solid colors(grey, light);
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 8px;
      transform: rotate(-45deg);
      position: absolute;
      top: 14px;
      left: 0;
    }
  }

  &__more-info {
    display: none;

    .c-comparison-list__item--more-info & {
      display: block;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        border-top: 1px solid colors(grey, light);
      }
    }
  }

  &__more-info-toc {
    padding-top: $inuit-base-spacing-unit;
    position: relative;

    @include media-query(palm) {
      text-align: center;

      &::after {
        border-bottom: 1px solid colors(grey, light);
        right: 0;
        left: $inuit-base-spacing-unit;
        top: auto;
      }
    }

    @include media-query(desk) {
      min-height: 250px;
      padding-right: $inuit-base-spacing-unit;
      padding-bottom: double($inuit-base-spacing-unit);

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -1px;
        border-right: 1px solid colors(grey, light);
      }
    }

    @include media-query(desk-wide) {
      padding-top: 1.5 * $inuit-base-spacing-unit;
    }

    &--empty {
      .c-tabbox__tabs {
        display: none;
      }

      @include media-query(lap) {
        display: none;

        + .c-comparison-list__more-info-content {
          width: 100% !important;
        }
      }
    }
  }

  &__all-info-button {
    @include media-query(desk) {
      position: absolute;
      bottom: 20px;
    }
  }

  &__more-info-content {
    padding-top: $inuit-base-spacing-unit;
    position: relative;
    min-height: 260px;

    @include media-query(desk-wide) {
      padding-left: double($inuit-base-spacing-unit);
      padding-top: 1.5 * $inuit-base-spacing-unit;
    }

    @include media-query(desk) {
      .layout__item + &.layout__item {
        border-left: 1px solid colors(grey, light);
      }
    }
  }

  &__more-info-button-arrow-blue svg path {
    stroke: #004a6b;
  }

  &__more-info-button-arrow {
    transform: rotate(0deg);
    transform-origin: center center;
    display: inline-block;
    vertical-align: middle;

    @include media-query(palm) {
      transform: rotate(90deg);
    }
  }

  &__collapse-button-wrapper {
    position: absolute;
    bottom: -$collapse-bar-height;
    height: $collapse-bar-height;
    left: 0;
    right: 0;
    text-align: center;
  }

  &__collapse-button-inner {
    display: block;
    width: 100%;
    height: $collapse-bar-height;
    padding-bottom: 3px;
    background-color: rgba(colors(white), 0.6);
    border-radius: 0 0 $border-radius $border-radius;

    .c-comparison-list__item--more-info:has(.c-comparison-list__upsell) & {
      border-radius: 0;
      border-bottom: 1px solid colors(grey, light);
    }
  }

  &__more-info-button {
    @include typography(zeta);
    backface-visibility: hidden;
    background-color: rgba(colors(white), 0.35);
    cursor: pointer;
    display: block;
    font-weight: bold;
    height: 32px;
    overflow: hidden;
    padding: 4px 0 8px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transform: translateZ(0);
    width: 100%;

    &::before {
      color: colors(brand, support);
      content: attr(data-more);
      display: inline-block;
      margin-right: 10px;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
    }

    &::after {
      background-color: colors(white);
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      text-decoration: none;
      top: 0;
      transition: all 200ms $swift-ease-in-out-timing-function;
      z-index: -1;

      @include media-query(palm) {
        opacity: 0.5;
      }
    }

    &:hover {
      &::after {
        opacity: 0.5;
      }
    }

    &:active {
      &::after {
        opacity: 1;
      }
    }

    .c-comparison-list__item--more-info & {
      &::before {
        content: attr(data-less);
      }
    }
  }

  &__rating {
    &--empty {
      @include inuit-font-size($inuit-heading-size-6, $small-font-size-lineheight);
      color: colors(grey);
      white-space: normal;
    }
  }

  &__usp-item {
    &--icon {
      display: inline-block;
      width: 12px;
      vertical-align: top;
      padding-top: 3px;

      svg {
        width: 10px;
        height: 14px;
      }
    }

    &--text {
      width: 80%;
      width: calc(100% - 15px);
      display: inline-block;
    }
  }

  &__notetext {
    position: absolute;
    // Pixel tweak to vertical align with the horizontal border in the result-block
    bottom: -19px;
    right: 0;

    &--opposite {
      left: 0;
      right: auto;
    }
  }

  &__results-not-visible {
    position: absolute;
    left: 50%;
    margin-left: 30px;
    display: none;
    bottom: 0;

    &--fixed {
      position: fixed;
      transform: translateY(-50%);
      top: 50vh;
      bottom: auto;
    }
  }

  &__provider-logo-wrapper {
    height: 50px; // make sure no logo exceeds 50px height
  }

  &__provider-logo {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;

    &--current {
      max-height: 70px;
    }

    &--small {
      max-height: 40px;
    }
  }

  &__phone-wrapper {
    height: 40px;
  }
  // Class used in the AB Test for the results page
  &__provider-logo--container {
    height: 29px;
    display: block;

    img {
      max-height: 29px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      img {
        height: auto;
        max-height: 29px;
      }
    }
  }

  &__provider-logo--s {
    height: 31px;
  }

  &__provider-logo--gl {
    max-height: 40px;
  }

  &__logo-watermark {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 230px;
    right: 175px;
    overflow: hidden;
    pointer-events: none;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 20px;
      right: 0;
      bottom: -20px;
      left: 0;
      @include bg-image("logos/logo-internal/logo-icon.svg");
      background-size: contain;
      background-position: right bottom;
      filter: grayscale(100%);
      transform: rotate(8deg);
      opacity: 0.07;
    }
  }
}

@keyframes resultsHeaderFadeIn {
  100% {
    opacity: 1;
  }
}

.c-results-header__fadein {
  display: inline-block;
  animation: resultsHeaderFadeIn 500ms ease-in 600ms forwards;
  opacity: 0;
}

.c-no-gas-message {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 150px;
    @include bg-image("icons/nogas.svg");
    background-position: right -20px top 20px;
    opacity: 0.1;
    background-size: contain;
  }
}

/*  c-business-to-consumer-message */
.c-switch-targetgroup-message {
  background-color: colors(white);
  border-radius: $border-radius;
  margin-bottom: 10px;
  position: relative;
  padding-top: 10px;
  border: 1px solid colors(grey, light);

  @include media-query(palm) {
    padding: 10px;
  }

  @include media-query(lap) {
    padding-left: 20px;
  }

  @include media-query(default) {
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 80px;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 125px;
      background-position: left center;
      background-size: 90px auto;
    }

    &--c2b:after {
      @include bg-image("icons/consumer-to-business.svg");
    }

    &--b2c:after {
      @include bg-image("icons/business-to-consumer.svg");
    }
  }
}
