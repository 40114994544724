﻿.c-cookiebar {
  z-index: 9999;
  position: fixed;
  left: 10px;
  right: 10px;
  transform: scale(0);
  bottom: -100px;
  transition: all 0.2s cubic-bezier(0.17, 0.67, 0.65, 1);

  &--open {
    bottom: 10px;
    display: block;
    transform: scale(1);
  }

  &__layer {
    position: relative;
    z-index: 999;
    margin: 0 auto;
    width: 800px;
    max-width: 100%;
    padding: 5px 10px;
    color: colors(white);
    border-radius: $border-radius-tiny;
    background-color: rgba(colors(black), 0.6);
    text-align: center;
  }

  &__button {
    display: inline-block;
    margin-left: 10px;
    background-color: colors(black);
    padding: 1px 10px;
    border-radius: $border-radius-tiny;
    text-transform: uppercase;
    font-size: $small-font-size;

    &:hover {
      text-decoration: none;
      background-color: colors(grey, dark);
    }
  }
}
