.c-filter-summary {
  $height: 30px;
  $height-mobile: 36px;

  @include font-weight--semi-bold;
  font-size: 0;
  display: inline-block;
  white-space: nowrap;
  background-color: colors(white);
  border-radius: $border-radius-small;
  color: colors(grey);
  margin-right: 5px;
  margin-bottom: 10px;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  height: $height;

  &--cta {
    background-color: colors(brand, cta);
    color: colors(white);

    .c-filter-summary__remove {
      @include bg-image("icons/cross-white.svg");
    }
  }

  @include media-query(palm) {
    height: $height-mobile;
  }

  &[hidden] {
    display: none;
  }

  &--white {
    color: colors(white);
    background-color: rgba(colors(white),0.1);
  }

  &__remove {
    @include bg-image("icons/cross.svg");
    background-size: 12px;
    background-position: center center;
    vertical-align: middle;
    border-right: 1px solid colors(grey, light);
    height: $height;
    width: $height;

    @include media-query(palm) {
      height: $height-mobile;
      width: $height-mobile;
    }

    &--white {
      @include bg-image("icons/cross-white.svg");
      border: none;
    }

    &:hover {
      background-color: colors(brand);
      @include bg-image("icons/cross-white.svg");
    }
  }

  &__search {
    width: 250px !important;
    background-color: rgba(255,255,255,0.6) !important;
    padding: 5px 5px 5px 35px !important;
    float: right !important;
    margin-bottom: 10px !important;
    border-radius: $border-radius-small !important;
    border: none !important;
    @include bg-image("icons/search-grey.svg");
    background-position: 5px center !important;
  }

  &__label {
    @include typography(zeta);
    @include overflow--ellipsis;
    padding: 5px 10px;
    white-space: nowrap;
    max-width: 250px;
    overflow: hidden;
    cursor: default;
    text-align: left;
    height: $height;

    @include media-query(palm) {
      height: $height-mobile;
    }

    &:hover {
      text-decoration: none;
    }

    &[data-hover-label] {
      &:before {
        content: attr(data-hover-label);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 30px;
        padding: 5px 10px;
        background-color: colors(white);
        text-align: center;
        z-index: 1;
        opacity: 0;
        transition: opacity 300ms;
        cursor: pointer;
      }

      &:hover {
        // To fit the label 'Aanpassen' when hovered
        min-width: 85px;

        &:before {
          opacity: 1;
        }
      }
    }

    &--white {
      background-color: rgba(colors(white),0.1);

      &[data-hover-label] {
        &:hover {
          color: transparent;
        }

        &:before {
          background-color: transparent;
          color: colors(white);
        }
      }
    }

    &--checkbox {
      padding-left: 35px;
      display: inline-block;
      position: relative;
      transition: all 300ms $swift-ease-in-out-timing-function;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -9px;
        left: 9px;
        height: 18px;
        width: 18px;
      }

      &::before {
        background-color: colors(white);
        border: 1px solid colors(grey, light);
        border-radius: $border-radius-tiny;
      }

      &::after {
        opacity: 0;
        transition: all 300ms $swift-ease-in-out-timing-function;
        background: url(#{$assetPathImages}icons/check-grey.svg) no-repeat center center;
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(colors(white), 0.5);

        &::after {
          opacity: 0.75;
        }
      }
    }
  }

  &__remove-all {
    &:has(+ .js-filter-summary:empty) {
      display: none;
    }

    @include typography(zeta);
    height: $height;
    background-color: colors(brand, support);
    border-radius: $border-radius-small;
    color: colors(white);
    display: inline-block;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 10px;
    vertical-align: top;
    position: relative;
    padding-left: 35px;

    &:hover {
      background-color: colors(brand);
      text-decoration: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      @include bg-image("icons/cross-white.svg");
      background-size: 12px;
      background-position: center center;
      vertical-align: middle;
      width: $height;

      @include media-query(palm) {
        width: $height-mobile;
      }
    }

    .icon-trash {
      display: none;
    }
  }
}
