// classic way hiding content, but keep space of content.
.u-visibility--hidden {
  visibility: hidden;
}

//
// Visuallyhidden: used to visually hide content.
//
%u-visuallyhidden,
.u-visuallyhidden {
  @include u-visuallyhidden();
}

@each $breakpoint in $breakpoints {
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {
    .u-#{$alias}-visuallyhidden {
      @include u-visuallyhidden();
    }
  }
}

// Hide content if:
// 1) js is available
// 2) js is not available

html.js .u-js-hidden {
  @extend %u-visuallyhidden;
} // 1

html.no-js .u-js-visible {
  @extend %u-visuallyhidden;
} // 2

//
// no scrolling / maximize height to viewport height
//
.u-lockscroll {
  height: 100vh;
  overflow: hidden;
}

//
// upgrade z-index (best used icm position absolute or relative
//
.u-zindex--1 {
  z-index: 1;
}

//
// wrapper for <table/> to make it scrollable (on mobile devices especially)
//
// stylelint-disable
.scrollWrapper, /* legacy for contentitems */
.u-scroll-wrapper {
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), -webkit-linear-gradient(right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%) right, -webkit-radial-gradient(left, rgba(0, 0, 0, 0.2), transparent 70%), -webkit-radial-gradient(right, rgba(0, 0, 0, 0.2), transparent 70%) right;
  background-repeat: no-repeat;
  background-size: 40px 100%, 40px 100%, 12px 100%, 12px 100%;
  background-attachment: local, local, scroll, scroll;
  padding: 0 0 10px;
}

.u-clearfix {
  @include clearfix();
}

.u-empty-hide:empty { display:none; }

.u-empty-hide:-moz-only-whitespace { display: none; }

.u-childless-hide:not(:has(*)) { display:none; }

.u-opacity--0 {
  opacity: 0;
}

.u-opacity--25 {
  opacity: 0.25;
}

.u-opacity--50 {
  opacity: 0.5;
}

.u-opacity--75 {
  opacity: 0.75;
}

.u-opacity--90 {
  opacity: 0.9;
}

.u-opacity--100 {
  opacity: 1;
}

.u-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @for $i from 1 through 5 {
    &--#{$i} {
      -webkit-line-clamp: $i;
    }
  }
}