﻿.c-spec {
  @include inuit-font-size($inuit-heading-size-5, $inuit-heading-size-3-lineheight);
  font-family: $spec-face;
  font-style: normal;


  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-6, $inuit-heading-size-6-lineheight);
  }

  &--amount {
    @include inuit-font-size($inuit-heading-size-3, $inuit-heading-size-3-lineheight);

    @include media-query(palm) {
      @include inuit-font-size($inuit-base-font-size, $inuit-heading-size-6-lineheight);
    }
  }

  &__decimals {
    font-size: 0.6em;
    line-height: 1;
    vertical-align: super;
    position: relative;
    margin-left: 2px;
    top: 0.1em;
  }

  &--rating {
    @include inuit-font-size($inuit-heading-size-1, $inuit-heading-size-1-lineheight);

    @include media-query(palm) {
      @include inuit-font-size($inuit-heading-size-5, $inuit-heading-size-5-lineheight);
    }
  }

  &--rating-tab {
    @include typography(default);
    line-height: 1;

    .c-spec__decimals {
      @include typography(small);
      margin-left: 1px;
      top: 0.3em;
    }
  }
}
