.c-number-input {
  &__button-min,
  &__button-plus {
    text-align: center;
    font-size: 30px;
    line-height: 20px;
    border: 1px solid colors(grey, light);
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: colors(white);
    color: colors(grey, dark);

    &:hover {
      text-decoration: none;
    }

    &:active {
      background-color: colors(grey, light);
    }

    &[disabled] {
      opacity: 0.3;
    }
  }

  &__button-min {
    border-right: none;
  }

  &__button-plus {
    border-left: none;
  }

  &__button--large {
    padding-top: 18px;
    padding-bottom: 20px;

    @include media-query(palm) {
      padding-top: 12px;
      padding-bottom: 16px;
    }
  }
}
