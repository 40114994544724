.c-account-code {
  display: inline-block;
  vertical-align: middle;

  &__submit {
    display: inline-block;
    max-width: 0;
    opacity: 0;
    height: 50px;
    overflow: hidden;
    background-image: url(#{$assetPathImages}icons/check.svg);
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    transition: all 300ms;
    border-radius: $border-radius-small;

    @include typography(delta);

    &--ready {
      border: 3px solid transparent;
      max-width: 30px;
      padding-right: 30px;
      padding-left: 10px;
      opacity: 1;


      &:hover {
        text-decoration: none;
        border-color: rgba(colors(white), 0.4);
        background-color: rgba(colors(white), 0.1);
      }
    }
  }
}

input.c-account-code__input {
  white-space: nowrap;
  width: 35px;
  padding: 0;
  height: 50px;
  margin-right: 4px;
  text-align: center;
  @include typography(gamma);
  color: colors(white);
  font-style: normal;
  border: none;
  border-radius: $border-radius-small;
  background-color: rgba(colors(white), 0.2);
  cursor: pointer;
  caret-color: transparent;
  transition: all 300ms;

  &:focus {
    background-color: rgba(colors(white), 0.6);
  }

  &::selection {
    background-color: transparent;
  }

  &::-webkit-input-placeholder {
    color: rgba(colors(white), 0.5);
  }

  &:focus::-webkit-input-placeholder {
    color: colors(white);
  }
}
