.c-action-header {
  position: absolute;
  left: 520px;
  right: 330px;
  top: 10px;
  text-align: center;

  @include media-query(desk) {
    padding-left: 40px;
  }

  @include media-query(desk-wide) {
    padding-left: 120px;
  }

  @media screen and (max-width: 69em) {
    left: 240px;

    + .c-bencom-label .c-bencom-label__ankeiler {
      display: none;
    }
  }

  &--be {
    left: 490px;

    @media screen and (max-width: 69em) {
      left: 280px;
    }
  }

  &--int {
    left: 470px;

    @include media-query(desk-wide) {
      left: 430px;
    }

    @media screen and (max-width: 69em) {
      left: 280px;
    }
  }

  &--home {
    display: none;
    //Originally 60em, changed because of additional "Stroom" tab
    @media screen and (max-width: 74em) {
      display: block;
    }
  }

  &__logo {
    height: 80px;
    width: 80px;
    display: inline-block;
  }

  @include media-query(lap) {
    top: 10px;

    &__logo {
      height: 75px;
      width: 75px;
      position: relative;

      &--small {
        height: 60px;
        width: 60px;
      }
    }

    &__cta {
      top: -5px;
      position: relative;
    }
  }

  @include media-query(palm) {
    right: 90px;
    left: 160px;
    top: 7px;

    &__logo {
      height: 50px;
      width: 50px;

      &--small {
        height: 40px;
        width: 40px;
      }
    }

    &__cta {
      top: -10px;
      position: relative;
    }
  }

  &__pw-arrow {
    &:before {
      @include media-query(desk) {
        content: "";
        @include bg-image("icons/curly-pointer-down2.svg");
        background-size: contain;
        transform: scaleY(-1) rotate(110deg);
        display: block;
        position: absolute;
        transform-origin: bottom left;
        width: 30px;
        height: 70px;
        left: -20px;
        bottom: -13px;
        opacity: 0.5;
      }

      @include media-query(desk-wide) {
        width: 50px;
        height: 90px;
        left: 30px;
      }
    }
  }
}