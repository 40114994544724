.c-tag {
  @include inuit-font-size($inuit-heading-size-6, $inuit-heading-size-6-lineheight);
  padding: 3px 10px 2px;
  display: inline-block;
  border-radius: $border-radius-tiny;

  &--border {
    border: 1px solid;
  }

  &--small {
    padding: 0 7px 1px;
    margin-top: 2px;
    white-space: nowrap;
    @include typography(small);
  }

  &--hover {
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      opacity: 0;
      transition: opacity 150ms;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 0 7px inset rgba(colors(grey), 0.5);
    }

    &:hover {
      text-decoration: none;

      &:before {
        opacity: 0.5;
      }
    }

    &:active {
      &:before {
        opacity: 1;
      }
    }
  }

  &--green {
    color: colors(status,green);
    background-color: desaturate(lighten(colors(status, green), 70%), 55%);

    &-bg {
      background-color: colors(status,green);
      color: colors(white);
    }
  }

  &--blue {
    @include font-weight--semi-bold;
    color: colors(brand, support);
    background-color: lighten(colors(brand, cta), 48%);

    &-bg {
      background-color: colors(brand, support);
      color: colors(white);
    }
  }

  &--orange {
    @include font-weight--semi-bold;
    color: colors(status, orange);
    background-color: lighten(colors(status, orange), 48%);

    &-bg {
      background-color: colors(status, orange);
      color: colors(white);
    }
  }

  &--red {
    @include font-weight--semi-bold;
    color: colors(status, red);
    background-color: lighten(colors(status, red), 48%);

    &-bg {
      background-color: colors(status, red);
      color: colors(white);
    }
  }

  &--grey {
    color: colors(grey, dark);
    background-color: colors(grey, x-light);

    &-bg {
      background-color: colors(grey, dark);
      color: colors(white);
    }
  }

  &--white {
    color: colors(white);
    background-color: rgba(colors(white), 0.15);
  }

  &--strikethrough {
    background: linear-gradient(-45deg, colors(grey, x-light) 17.5%, colors(white) 17.5%, colors(white) 32.5%, colors(grey, x-light) 32.5%, colors(grey, x-light) 67.5%, colors(white) 67.5%, colors(white) 82.5%, colors(grey, x-light) 82.5%);
    background-size: 10px 10px;
    color: colors(grey);
  }

  $purple: #4f60b1;
  $purple-light: #E8ECFD;

  &--purple {
    color: $purple;
    background-color: $purple-light;

    &-bg {
      background-color: $purple;
      color: colors(white);
    }
  }

  $darkcyan: #54868F;

  &--darkcyan {
    color: $darkcyan;
    background-color: lighten($darkcyan, 48%);

    &-bg {
      color: colors(white);
      background-color: $darkcyan;
    }
  }

  //Used in Travel to set min-width for coverage tags
  &--min-width {
    min-width: 110px;
  }

  &--coverage {
    padding: 4px 12px 3px;
    @include media-query(desk) {
      padding: 5px 8px 4px;
    }
    @include media-query(desk-wide) {
      padding: 5px 12px 4px;
    }
  }

  &--extra-coverage {
    position: relative;
    padding-right: 15px;

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      right: -4px;
      @include bg-image("icons/coverage-plus.svg");
      background-position: center center;
      height: 16px;
      width: 16px;
    }
  }

  &--coverage-double-line {
    padding-top: 12px;
    padding-bottom: 11px;
  }
}

.c-coverages-plus-sign {
  color: gradients(gradient3, to);
}