// Global Reset Styles ------------------

input,
textarea,
select,
option,
optgroup,
button,
legend,
fieldset {
  box-sizing: border-box;
  outline: none;
  font-family: $base-face;
  vertical-align: top;
}


input:invalid {
  box-shadow: none;
}



// Fields with standard height
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list] {
  appearance: none;
}

// Other

textarea {
  appearance: none;
  overflow: auto;
}


input[type="search"] {
  appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  vertical-align: middle;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

// Remove the yellow background when using autocomplete in Chrome
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

select {
  appearance: none;
}
