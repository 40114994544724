.c-gl-doorsticker {
  &__wrapper {
    @include media-query(palm) {
      margin-bottom: 40px;
      text-align: center;
    }

    @include media-query(default) {
      text-align: right;
      margin-bottom: -50px;
    }
  }

  &__image {
    transform: rotate(-5deg);
    border-radius: $border-radius-small;

    @include media-query(palm) {
      max-height: 180px;
    }
  }
}
