.c-customer-experiences {
  &__review-sidebar {
    @include media-query(default) {
      float: right;
      min-width: 230px;
      padding-left: 10px;
    }
  }

  &__form {
    max-width: 400px;
  }

  &__moderator-reply {
    background-color: rgba(brand-colors(nav, base), 0.7);
    border-radius: $border-radius-small;
    color: colors(white);
  }

  &__thumb {
    color: gradients(gradient1, to);
    padding-right: 5px;
    display: inline-block;
    vertical-align: middle;

    svg {
      width: 17px;
      height: 17px;
    }
  }

  &__thumb-big {
    color: colors(status, green);
    padding-right: 5px;
    display: inline-block;
    vertical-align: middle;

    svg {
      display: block;
      width: 25px;
      height: 25px;
    }
  }
}

