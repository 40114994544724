@keyframes fadeLazyLoad {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

[data-lazyload-src] {
  opacity: 0;
  @include typography(small);

  &[data-loaded] {
    animation-name: fadeLazyLoad;
    animation-duration: 600ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

.c-lazyload-alt {
  @include typography(small);
  color: colors(grey);
  position: absolute;
  margin-top: -20px;
  display: block;

  [data-lazyload-src][data-loaded] + & {
    display: none;
  }
}
