// ----------------------------------------------------------------------------
// Feature queries
//
// More info: https://davidwalsh.name/css-supports
//
// Example:
//
// .grid {
//   @include supports-grid {
//     ...
//   }
//
//   @include does-not-support-grid {
//     ...
//   }
// }
// ----------------------------------------------------------------------------

// Feature query that detects if a browser supports CSS Grid
@mixin supports-grid {
  @supports (grid-auto-rows: 1px) {
    @content;
  }
}

// Feature query that detects if a browser does not support CSS Grid
@mixin does-not-support-grid {
  @supports not (grid-auto-rows: 1px) {
    @content;
  }
}
