//
// Alignment, vertical and horizontal
//

//
// Absolute-block alignment
//

.u-flex--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.u-flex--vertical-center {
  display: flex;
  align-items: center;
}

.u-block--center {
  @include center;
}

.u-block--vertical-center {
  @include center-vertical;
}

.u-block--horizontal-center {
  @include center-horizontal;
}

.u-block--top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.u-block--bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

//
// Inline-block alignment
//
.u-vertical-align--bottom {
  vertical-align: bottom !important;
}

.u-vertical-align--middle {
  vertical-align: middle;
}

.u-vertical-align--top {
  vertical-align: top;
}

.u-vertical-align--baseline {
  vertical-align: baseline;
}
//
// Text alignment
//

.u-text-align--right {
  text-align: right;
}

.u-text-align--left {
  text-align: left;
}

.u-text-align--center {
  text-align: center;
}

@include media-query(palm) {
  .u-palm-text-align--left {
    text-align: left;
  }

  .u-palm-text-align--center {
    text-align: center;
  }

  .u-palm-text-align--right {
    text-align: right;
  }
}

@include media-query(lap) {
  .u-lap-text-align--left {
    text-align: left;
  }

  .u-lap-text-align--center {
    text-align: center;
  }
}

@include media-query(portable) {
  .u-portable-text-align--left {
    text-align: left;
  }

  .u-portable-text-align--center {
    text-align: center;
  }
}

@include media-query(desk) {
  .u-desk-text-align--left {
    text-align: left;
  }

  .u-desk-text-align--center {
    text-align: center;
  }

  .u-desk-text-align--right {
    text-align: right;
  }
}

//
// Float alignment
//

.u-float--right {
  float: right;
}

.u-float--left {
  float: left;
}

.u-float--none {
  float: none;
}

@include media-query(palm) {
  .u-palm-float--none {
    float: none;
  }
}

@include media-query(lap) {
  .u-lap-float--none {
    float: none;
  }
}

@include media-query(desk) {
  .u-desk-float--none {
    float: none;
  }
}

.u-perfect-square {
  aspect-ratio: 1/1;
}

.u-perfect-circle {
  aspect-ratio: 1/1;
  border-radius: $border-radius-full;
}

.u-width--auto {
  width: auto !important;
}