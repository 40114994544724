//
// General Typography
//

body {
  font-family: $base-face;
}


//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  @include typography(alpha);
  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-2, $inuit-heading-size-2-lineheight);
  }
}

h2 {
  @include typography(beta);
  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-3, $inuit-heading-size-3-lineheight);
  }
}

h3 {
  @include typography(gamma);
  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-4, $inuit-heading-size-4-lineheight);
  }
}

h4 {
  @include typography(delta);
}

h5 {
  @include typography(epsilon);
}

h6 {
  @include typography(zeta);
}

strong,
b {
  font-weight: bold;
}

small {
  @include typography(small);
}

button,
a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}