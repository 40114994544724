﻿@include media-query(palm) {
  html.hiddenscroll .c-comparison__scrollwrapper-outer {
    transform: translateX(0);
  }
}

.c-comparison {
  &__search-icon {
    position: absolute;
    left: 12px;
    top: 14px;
    fill: colors(white);
  }

  &__coverage-section {
    margin-left: 20px;

    @include media-query(palm) {
      margin-top: 10px;
    }

    &--topinfo {
      margin-bottom: 20px;

      + .c-comparison__coverage-section .c-comparison__coverage-cell--header + .c-comparison__coverage-cell--header {
        &::before {
          display: none;
        }
      }
    }
  }

  &__filter {
    @include media-query(desk) {
      &-input {
        width: 400px !important;
        max-width: 100%;
      }
    }

    @include media-query(palm) {
      text-align: center;

      &--floating {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding-left: 20px;
        padding-right: 20px;
        @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));
        padding-top: 10px;
        z-index: 9;

        + .c-comparison__filter-dummy-placeholder {
          height: 75px;
        }
      }
    }
  }

  @for $item from 1 through 10 {
    &__scrollcontainer[data-packagecount="#{$item}"] {
      min-width: #{($item + 1) * 320 + 20}px;

      @include media-query(palm) {
        min-width: #{($item) * 220 + 20}px;
      }

      @include media-query(default) {
        .c-comparison__coverage-section--header {
          min-width: #{($item + 1) * 300}px;

          &::before,
          &::after {
            right: #{($item + 1) * -20}px;
          }
        }
      }

      .c-comparison__coverage-section {
        margin-right: #{($item) * 20}px;
      }
    }
  }

  &__coverage-cell {
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 300px;

    @include media-query(palm) {
      min-width: 200px;
      padding-top: 20px;
    }

    @for $item from 2 through 12 {
      &:nth-child(#{$item}) {
        transform: translateX(#{($item - 2) * 20}px);
        padding-left: 20px !important; // important to overrule the default layout-rule
        padding-right: 20px;
      }
    }

    @include media-query(palm) {
      &--filler {
        display: none;
      }

      &--label {
        margin-left: -20px;
        padding-top: 0;
        padding-bottom: 0;

        @for $item from 1 through 10 {
          .c-comparison__scrollcontainer[data-packagecount="#{$item}"] & {
            width: calc(100% + #{($item) * 20 + 20}px) !important;
            margin-right: -#{($item * 20)}px !important;   
          }
        }
        
        h3,
        h4 {
          position: sticky;
          white-space: nowrap;
          width: 100vw;
          z-index: 1;
          left: 0;
          text-align: center;
          pointer-events: none;
        }

        h3 {
          background-color: rgba(colors(white), 0.2);
          border-top: 1px solid rgba(colors(white), 0.5);
          border-bottom: 1px solid rgba(colors(white), 0.5);
        }

        h4 {
          background: rgba(colors(grey, x-light), 0.8);
          font-size: $small-font-size;
          font-style: italic;
          color: colors(grey, dark);
          line-height: 18px;
        }
      }
    }

    &--label {
      margin-right: 20px;
    }

    &--header {
      padding-top: 10px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        border-top: 1px solid colors(grey, light);
        top: 0;
        left: 20px;
        right: 20px;

        @include media-query(palm) {
          display: none;
        }
      }

      &.c-comparison__coverage-cell--label {
        &::before {
          left: 0;
          right: 0;
          border-color: rgba(colors(white), 0.5);
        }
      }
    }
  }

  &__scrollwrapper {
    overflow-x: scroll;
    position: relative;

    html.hiddenscroll & {
      width: 100vw;
    }

    html.hiddenscroll.fancybox-wrap &,
    html.no-hiddenscroll & {
      width: 100%;
    }

    .c-comparison__shade-left,
    .c-comparison__shade-right {
      display: block;
      position: fixed;
      z-index: 10;
      transition: $swift-ease-in-out;
    }

    @include media-query(default) {
      .c-comparison__shade-left,
      .c-comparison__shade-right {
        top: 50vh;
        margin-top: -100px;
        height: 200px;
        width: 50px;
        background-color: rgba(colors(grey, x-light), 0.9);
        text-align: center;
      }

      .c-comparison__shade-left {
        transform: translateX(-50px);
        left: 0;

        svg {
          transform: rotate(-90deg) scale(2);
        }
      }

      .c-comparison__shade-right {
        transform: translateX(50px);
        right: 0;

        svg {
          transform: rotate(90deg) scale(2);
        }
      }

      &--left .c-comparison__shade-left {
        transform: translateX(0);
      }

      &--right .c-comparison__shade-right {
        transform: translateX(0);
      }
    }

    @include media-query(palm) {
      .c-comparison__shade-left,
      .c-comparison__shade-right {
        bottom: 0;
        top: 0;
        width: 15px;

        svg {
          display: none;
        }
      }

      .c-comparison__shade-left {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        transform: translateX(-15px);
        left: 0;
        background: linear-gradient(to right, rgba(colors(black), 0.2) 0%, rgba(colors(black), 0) 100%);
      }

      .c-comparison__shade-right {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        transform: translateX(15px);
        right: 0;
        background: linear-gradient(to right, rgba(colors(black), 0) 0%, rgba(colors(black), 0.2) 100%);
      }

      &--left .c-comparison__shade-left {
        transform: translateX(0);
      }

      &--right .c-comparison__shade-right {
        transform: translateX(0);
      }
    }
  }
}

.c-comparison__highlight {
  position: relative;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -5px;
    right: -5px;
    bottom: -2px;
    background-color: colors(status, marked);
    z-index: -1;
    opacity: 1;
    transform: scaleX(1) scaleY(1);
    transform-origin: left center;
    transition: $swift-ease-in-out;
    transition-duration: $duration-medium;
    border-radius: $border-radius-tiny;
  }

  &-checkbox {
    position: absolute;
    left: 0;

    &:checked {
      ~ .c-comparison__scrollwrapper-outer .c-comparison__category--no-differences {
        display: none;
      }
    }
  }

  &-label {
    padding-top: 16px;
    padding-bottom: 14px;
    padding-left: 45px !important;
    display: inline-block !important;

    @include media-query(palm) {
      display: block !important;
    }

    &::before,
    &::after {
      left: 15px !important;
      top: 15px !important;
    }

    &::before {
      background-color: transparent !important;
      border-color: colors(grey, dark) !important;
    }

    &::after {
      filter: grayscale(100%) contrast(0%) brightness(50%);
    }
  }
}

@include media-query(palm) {
  .c-comparison__coverage-cell__image--fixed-height {
    position: relative;
    height: 60px;

    img {
      @include center;
    }
  }
}

@include media-query(default) {
  .c-comparison__coverage-section--header {
    z-index: 5;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -1px;
      z-index: -1;
      transform: scale(0);
    }

    &::before {
      content: "";
      position: absolute;
      top: -100vh;
      height: 100vh;
      left: 0;
      right: 0;
      transform: scale(0);
    }

    .c-comparison__coverage-cell__image--fixed-height {
      position: relative;

      img {
        @include center;
        max-height: 100%;
      }
    }

    &.c-comparison-head--not-floating {
      .c-comparison__coverage-cell__image--fixed-height {
        height: 100px;
      }
    }

    &.c-comparison-head--floating {
      position: fixed;
      padding-top: 0;
      left: -20px;
      right: 0;
      top: 70px;
      padding-left: 20px;

      .c-comparison__coverage-cell__image--fixed-height {
        height: 50px;
      }

      .c-comparison__coverage-cell__image img {
        max-width: 50px;
        max-height: 50px;
        margin-left: 10px;
      }

      &::before {
        background-color: colors(white);
        transform: scale(1);
      }

      &::after {
        background-color: rgba(colors(white), 0.8);
        border-bottom: 1px solid colors(grey);
        transform: scale(1);
      }

      .c-comparison__coverage-cell--label {
        h4 {
          color: colors(grey, dark) !important;
        }
      }

      + .c-comparison__coverage-section {
        margin-top: 180px;
      }
    }
  }
}

.c-comparison__category {
  @include media-query(default) {
    padding-right: 20px;
  }

  &--header {
    min-height: 30px;

    @include media-query(palm) {
      min-height: 42px;
    }
  }

  .c-comparison__category-checkbox ~ &--features {
    height: 0;
    overflow: hidden;
    opacity: 0;
  }

  .c-comparison__category-checkbox:checked ~ &--features {
    height: auto;
    overflow: visible;
    opacity: 1;
  }

  .c-comparison__category-checkbox ~ &--header .c-comparison__coverage-cell--label h3::after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 8px;
    margin-left: halve($inuit-base-spacing-unit);
    background-image: url(#{$assetPathImages}icons/arrow-up-white.svg);
    background-position: center center;
    background-repeat: no-repeat;
    transform: scaleY(-1);
  }

  .c-comparison__category-checkbox:checked ~ &--header {
    .c-comparison__coverage-cell--label h3::after {
      transform: scaleY(1);
    }
  }
}
