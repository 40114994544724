.c-gl-landing-page {
  @include media-query(default) {
    @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));

    &--package-page {
      position: relative;

      @include media-query(desk) {
        min-height: calc(100vh - #{$header-height} - 50px - 80px);
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include bg-image("graphics/gaslicht/bg-birds-clouds.png");
        background-size: contain;
        background-position: top center;
        mix-blend-mode: luminosity;
      }

      &::after {
        content: "";
        position: absolute;
        height: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        @include bg-image("graphics/gaslicht/hoogspanningsmasten.svg");
        background-position: bottom center;
        background-size: auto 100%;
        mix-blend-mode: multiply;
        opacity: 0.4;
      }
    }

    &--homebattery,
    &--solarpanels {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      &-right {
        &:before {
          background-position: right bottom;
        }
      }
    }

    &--homebattery {
      &:before {
        @include bg-image("graphics/gaslicht/homebattery-bg.svg");
        background-position: bottom center;
        background-size: auto 780px;
        opacity: 0.6;
      }
    }

    &--solarpanels {
      &:before {
        @include bg-image("graphics/gaslicht/solarpanels-bg.svg");
        background-position: 25% bottom;

        @include media-query(lap) {
          background-position: -25vw bottom;
        }
      }
    }
  }

  &--package-page {
    @include media-query(palm) {
      @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));
    }
  }

  &--energyinsight {
    @include media-query(default) {
      background-image: linear-gradient(180deg, brand-colors(nav, base) 0%, #136DCD 50px, #53AEE9 100%);
    }

    @include media-query(palm) {
      background-image: linear-gradient(180deg,#136DCD 0%, #53AEE9 100%);
    }
  }

  p {
    margin-bottom: 0; // can't use a class here cause content is coming from CMS
  }

  &__header {
    @include media-query(palm) {
      @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));
    }

    @include media-query(default) {
      min-height: 150px;
    }
  }

  &__content {
    @include bg-image("graphics/gaslicht/landing-page-footer.svg");
    background-position: right bottom;
    background-size: 75%;

    @include media-query(palm) {
      background-color: gradients(gradient1, from);
      background-size: 140% auto;
      background-position: 10px bottom;
    }

    &--error {
      @include bg-image("graphics/gaslicht/landing-page-error.svg");
      background-position: 20px bottom;
      background-size: 80% auto;

      @include media-query(lap) {
        background-size: 98% auto;
        background-position: right bottom;
      }
    }

    &--nobg {
      background-image: none;
    }
  }
}