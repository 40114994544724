$items-per-row: 6;
$items-interval: $items-per-row * 2 - 1;
$items-width: 150px;
$items-margin: 15px;

.c-providers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto 20px;
  width: $items-per-row * (2 * $items-margin + $items-width);
  max-width: 100%;

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $items-margin;
    width: $items-width;
    height: 80px;
    border: 1px solid colors(grey, light);
    border-radius: $border-radius;

    @include media-query(desk) {
      &:nth-child(#{$items-interval}n + #{$items-per-row + 1}):not(:last-of-type) {
        margin-left: 100px;
      }

      &:nth-child(#{$items-interval}n + #{$items-interval}),
      &:nth-child(#{$items-interval}n + #{$items-per-row + 1}) + &:last-of-type {
        margin-right: 100px;
      }
    }

    @include media-query(palm) {
      margin: 5px;
    }

    &--top3 {
      border-width: 2px;
      border-color: inherit;
      width: 180px;
      height: 95px;

      @include media-query(palm) {
        margin-top: 40px;
      }

      &:nth-of-type(1) {
        &,
        .c-providers__score-bubble .c-providers__svg {
          color: #ECBF0B;
        }
      }

      &:nth-of-type(2) {
        &,
        .c-providers__score-bubble .c-providers__svg {
          color: #B7B7B7;
        }
      }

      &:nth-of-type(3) {
        &,
        .c-providers__score-bubble .c-providers__svg {
          color: #CD8D53;
        }
      }
    }

    @include media-query(palm) {
      width: 30%;
      height: 60px;
    }
  }

  &__logo {
    max-height: 60%;
    max-width: 70%;

    &--fixed-square {
      max-height: 100%;
    }

    @include media-query(palm) {
      max-height: 40px;
      max-width: 80px;
    }
  }

  &__svg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &__score-bubble {
    @include typography(default);
    font-weight: bold;
    position: absolute;
    text-align: center;
    right: -1em;
    bottom: -1em;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    z-index: 1;
    color: colors(white);

    .c-providers__svg {
      color: brand-colors(nav, base);
    }

    @include media-query(palm) {
      display: none;
    }
  }

  &__trophy-badge {
    @include typography(zeta);
    font-weight: bold;
    position: absolute;
    text-align: center;
    right: -1.6em;
    top: -1.6em;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    z-index: 1;

    @include media-query(palm) {
      margin-left: -1.6rem;
      right: auto;
      left: 50%;
      top: -3em;
    }

    &:before {
      @include typography(small);
      font-weight: bold;
      position: absolute;
      top: 0.87rem;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      color: colors(white);

      .c-providers__item--top3:nth-of-type(1) & {
        content: "1";
      }

      .c-providers__item--top3:nth-of-type(2) & {
        content: "2";
      }

      .c-providers__item--top3:nth-of-type(3) & {
        content: "3";
      }
    }
  }
}

