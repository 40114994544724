.c-link {
  @include typography(zeta);
  color: inherit;
  font-weight: bold;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }

  &--cta {
    color: colors(brand, cta);

    &:hover {
      color: colors(brand, support);
    }
  }

  &--text {
    font-weight: normal;
    text-decoration: none;

    &:hover {
      color: colors(brand, cta);
      text-decoration: none;
    }
  }

  &--underline {
    font-weight: normal;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &--hover-underline {
    &:hover {
      text-decoration: underline;
    }
  }

  &--hover-no-underline {
    &:hover {
      text-decoration: none;
    }
  }

  a & {
    cursor: pointer;
  }

  &--arrow {
    padding-right: 20px;
    position: relative;

    &:after {
      content: "";
      @include bg-image("icons/arrow-up.svg");
      @include size(15px, 8px);
      position: absolute;
      transform: translateY(-50%) rotate(-180deg);
      right: 0;
      top: 50%;
    }

    .o-toggle__trigger:checked + &-toggle,
    &.open {
      &:after {
        transform: translateY(-50%) rotate(0deg);
      }
    }

    &-blue {
      &:after {
        transform-origin: center center;
        background-position: center center;
        @include bg-image("icons/arrow-up-blue.svg");
      }
    }
  }

  &--back-arrow {
    @include bg-image("icons/arrow-left.svg");
    background-repeat: no-repeat;
    background-position: left 6px;
  }

  &__reset {
    @include bg-image("icons/trash-grey.svg");
    @include inuit-font-size(
      14px,
      15px
    ); // line-height is to make sure the background-image fits inside the parent
    color: colors(gray, base);
    display: inline-block;
    background-position: left center;
    padding-left: 25px; // width of image + 10px
    &:hover {
      text-decoration: none;
    }

    &--white {
      @include bg-image("icons/trash-white.svg");
      color: colors(white);
    }
  }

  li & {
    vertical-align: text-top;
  }
}
