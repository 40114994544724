.o-grid {
  display: grid;
  grid-template-columns: auto auto; 
  // gap: 10px 20px;

  // &--3cols {
  //   grid-template-columns: auto auto auto; 
  // }

  // &--4cols {
  //   grid-template-columns: auto auto auto auto; 
  // }

  // &--5cols {
  //   grid-template-columns: auto auto auto auto auto; 
  // }

  // &--6cols {
  //   grid-template-columns: auto auto auto auto auto auto; 
  // }
  
  &--inline {
    display: inline-grid; 
  }

  &--small {
    gap: 5px 10px;
  }
}