﻿/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */

$fancybox-imagepath: $assetPathImages + "vendor/Fancybox/";

.fancybox-slide-right {
  @keyframes slide-right--transition {
    from {
      opacity: 0;
      transform: scaleX(0);
    }

    to {
      opacity: 1;
      transform: scaleX(1);
    }
  }

  &--opening,
  &--closing {
    transform-origin: right center;
    animation-name: slide-right--transition;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.01,0.28,0,0.85);
    animation-duration: 400ms;
    animation-delay: 0s;
  }

  &--opening {
    animation-fill-mode: forwards;
  }

  &--closing {
    animation-direction: reverse;
    animation-fill-mode: backwards;
  }
}

.c-popup-content {
  padding: $inuit-base-spacing-unit $inuit-base-spacing-unit
    halve($inuit-base-spacing-unit);

  h3 {
    width: calc(100% - 42px);
  }
}

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1020;
}

.fancybox-skin {
  position: relative;
  background: colors(white);
  color: colors(grey, dark);
  text-shadow: none;
  border-radius: 0;
}

.fancybox-opened {
  z-index: 1030;
}

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 35px 0 rgba(colors(black), 0.2);
}

.fancybox-border-radius.fancybox-opened .fancybox-skin {
  border-radius: $border-radius;
}

.fancybox-outer,
.fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: colors(grey, dark);
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}

.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

.js-fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url("#{$fancybox-imagepath}fancybox_sprite.png");
}

.js-fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 1060;
}

.js-fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("#{$fancybox-imagepath}fancybox_loading.gif") center center
    no-repeat;
}

.fancybox-close {
  @extend %c-icon-close;
  background-color: colors(white);
  background-image: none;

  @include media-query(palm) {
    top: -19px;
    right: -19px;
  }


  &-outset {
    &--left {
      left: auto !important;
      right: -21px !important;

      @include media-query(palm) {
        right: 10px !important;
      }
    }

    &--right {
      right: auto !important;
      left: -21px !important;

      @include media-query(palm) {
        left: 10px !important;
      }
    }
    top: 25px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.35);
    }

    &:active {
      transform: scale(0.98);
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("#{$fancybox-imagepath}blank.gif"); /* helps IE */
  -webkit-tap-highlight-color: rgba(colors(black), 0);
  z-index: 1040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 1040;
  visibility: hidden;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}

.fancybox-lock body {
  overflow: hidden !important;
}

.fancybox-lock-test {
  overflow-y: hidden !important;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 1010;
  background-color: rgba(colors(grey, x-light), 0.9);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 1050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 1050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent; /* Fallback for web browsers that doesn"t support RGBa */
  background: rgba(colors(black), 0.8);
  border-radius: $border-radius;
  text-shadow: 0 1px 2px rgba(colors(black), 0.8);
  color: colors(white);
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: colors(white);
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: colors(white);
  padding: 10px;
  background: colors(black);
  background: rgba(colors(black), 0.8);
}
