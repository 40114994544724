.u-display--inline {
  display: inline;
}

.u-display--inline-block {
  display: inline-block;
}

.u-display--block {
  display: block;
}

.u-display--none {
  display: none;
}

.u-display--flex {
  display: flex;
}

.u-display--touchevents {
  display: none;
}

.touchevents .u-display--touchevents {
  display: initial;
}
@each $breakpoint in $breakpoints {
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {
    .u-#{$alias}-display--inline {
      display: inline !important;
    }

    .u-#{$alias}-display--inline-block {
      display: inline-block !important;
    }

    .u-#{$alias}-display--block {
      display: block !important;
    }

    .u-#{$alias}-display--none {
      display: none !important;
    }
  }
}
