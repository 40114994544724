﻿.c-bencom-specialisten {
  position: absolute;
  right: 10px;
  bottom: 0;

  &--in-block {
    bottom: 0 !important;

    @include media-query(lap) {
      width: 45%;
    }

    @include media-query(desk) {
      width: 240px;
    }

    @include media-query(desk-wide) {
      width: auto;
    }
  }

  &--small {
    @include media-query(lap) {
      width: 35%;
    }
  }

  &--big {
    @include media-query(lap) {
      width: 240px;
    }
  }
}
