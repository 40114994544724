html.no-placeholder .o-floating-label {
  transform: translateY(-15px);
  z-index: 1;
}

.o-floating-label {
  background: colors(white);
  color: colors(grey, dark);
  left: $inuit-base-spacing-unit - quarter($inuit-base-spacing-unit);
  opacity: 0;
  padding: 0 quarter($inuit-base-spacing-unit);
  position: absolute;
  top: 0;
  transform: translateY(10px);
  transition: $swift-ease-in-out;
  z-index: 1;
  font-size: 14px;

  &--show {
    transform: translateY(-15px);
    z-index: 1;
    opacity: 1;
  }

  &--select {
    transform: translateY(-12px);
    z-index: 1;
    opacity: 1;
  }
}