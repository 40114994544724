//
//  Generate color classes, like: .u-text-color--primary {}
//

@function mergeColorSets($sets) {
  $colorsets: ();
  @each $set in $sets {
    $colorsets: join($colorsets, $set);
  }
  @return $colorsets;
}

$colorSets: ($colors, $gradients, $brand-colors);
$colorsets: mergeColorSets($colorSets);

@each $name, $color in $colorsets {
  @each $key, $val in $color {
    @if ($key == "base") {
      .u-text-color-#{"" + $name} {
        color: $val;
      }

      .u-bg-color-#{"" + $name} {
        background-color: $val;
      }
    }

    .u-text-color-#{"" + $name}--#{"" + $key} {
      color: $val;
    }

    .u-bg-color-#{"" + $name}--#{"" + $key} {
      background-color: $val;
    }
  }
}

//
//  Generate gradients. Only applies when there's a 'from' & 'to' key.
//
@each $name, $color in $gradients {
  @each $key, $val in $color {
    @if ($key == "from") {
      $from: $val !global;
    }

    @if ($key == "to") {
      .u-#{$name} {
        //top-to-bottom
        background: $from;
        background: linear-gradient(to bottom, rgba($from, 1) 0%, rgba($val, 1) 100%);
      }

      .u-#{$name}--inverse {
        //bottom-to-top
        background: $val;
        background: linear-gradient(to bottom, rgba($val, 1) 0%, rgba($from, 1) 100%);
      }

      .u-#{$name}--ltr {
        //left-to-right
        background: $from;
        background: linear-gradient(to right, rgba($from, 1) 0%, rgba($val, 1) 100%);
      }

      .u-#{$name}--rtl {
        //right-to-left
        background: $from;
        background: linear-gradient(to right, rgba($val, 1) 0%, rgba($from, 1) 100%);
      }
    }
  }
}

.u-luminosity {
  mix-blend-mode: luminosity;
}

.u-exclusion {
  mix-blend-mode: exclusion;
}

.u-bg-color-white--translucent--60 {
  background-color: rgba(colors(white), 0.6);
}

.u-bg-color-white--translucent--90 {
  background-color: rgba(colors(white), 0.9);
}
