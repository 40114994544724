.c-rating-circle {
  @include inuit-font-size(40px, none);

  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-5, none);
  }
  display: inline-block;
  width: 5.2rem;
  height: 5.2rem;
  line-height: 5.2rem;
  border-radius: $border-radius-full;
  text-align: center;
  border: 2px solid;
  color: colors(brand, support);
  background-color: colors(white);

  @include media-query(palm) {
    width: 3.1rem;
    height: 3.1rem;
    line-height: 3.1rem;
  }

  &--tab {
    @include typography(default);
    width: 2.6rem;
    height: 2.6rem;
    line-height: 2.4rem;
    border-width: 1px;
  }
}

.c-rating-total {
  position: relative;
  display: inline-block;
  z-index: 1;
  width: 7rem;
  height: 7rem;
  line-height: 7rem;
  text-align: center;
  color: colors(white);

  @include media-query(palm) {
    width: 3.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
  }

  &:not(.c-rating-total--transparent):after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 15%;
    bottom: 15%;
    left: 15%;
    right: 15%;
    border-radius: $border-radius-full;
    background-color: gradients(gradient2, from);
  }

  &__svg {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: gradients(gradient2, from);
    z-index: -1;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &--tab {
    width: 3.8rem;
    height: 3.8rem;
    line-height: 3.8rem;
  }
}

.c-rating-wrapper {
  flex-basis: 295px;
}

.c-rating-list {
  &__wrapper {
    position: relative;

    @include media-query(default) {
      column-count: 2;
      column-gap: 30px;
    }
  }

  &__total-pointer {
    color: colors(brand, support);
    position: absolute;
    left: -100px;
    top: 0;

    @include media-query(palm) {
      left: -90px;
      top: -60px;
      transform: rotate(23deg);
    }
  }

  &__item {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  &__meter {
    display: block;
    width: 100%;
    height: 5px;
    appearance: none;

    &::-webkit-progress-bar {
      border-radius: $border-radius-small;
      background-color: colors(grey, light);
    }

    &::-webkit-progress-value {
      border-radius: $border-radius-small;
      background-color: brand-colors(nav);
    }
  }
}