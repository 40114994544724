//
// Generic Platform styling
//

// removes default border around fieldsets

html {
  scrollbar-gutter: stable;
  scroll-padding-top: 100px;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

blockquote {
  margin: 0;
}

small {
  display: inline-block;
}

p {
  margin-top: 0;
  margin-bottom: $inuit-base-spacing-unit;
}

ul {
  margin-top: 0;
  margin-bottom: $inuit-base-spacing-unit;
  padding-left: $inuit-base-spacing-unit;
}