.select2-container--provider {

  @import "single";

  select + & {
    width: 100% !important;
  }

  .select2-search__field {
    width: 100% !important;
  }

  .select2-dropdown {
    background-color: colors(white);
    border: none;
    box-shadow: 0 5px 25px 0 rgba(colors(black), 0.5);
    border-radius: $border-radius;
    overflow: hidden;
    z-index: 5;
  }

  .select2-dropdown--above {
    margin-bottom: 50px;
  }

  .select2-dropdown--below {
    margin-top: -50px;
  }

  .select2-results > .select2-results__options {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px 0;
  }

  .select2-results__option {
    @include typography(zeta);
    transition: all 100ms ease;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    padding: 0 15px;

    &[role=group] {
      padding: 0;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    border-color: colors(grey, light);
    background-color: colors(grey, x-light);
  }

  .select2-results__option--selected:not(:first-child) {
    border-color: colors(grey, light);
    background-color: colors(grey, x-light);
    font-weight: bold;
    border-left: 5px solid colors(grey) !important;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--open .select2-dropdown {
    border-color: colors(grey, light);
  }
}
