﻿.c-loading-overlay {
  background-color: rgba(colors(white), 0.7);
  bottom: 0;
  font-size: 40px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 99;
}