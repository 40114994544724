// ----------------------------------------------------------------------------
// Compare widget
// ----------------------------------------------------------------------------

.c-gl-input-box--vertical {
  [disabled] {
    &,
    & ~ * {
      opacity: 0.2;
    }
  }
}

.c-gl-compare-widget {
  @mixin slide-down-effect {
    transform: scaleY(1);
    transform-origin: top left;
    opacity: 1;
    animation: o-toggle-grow 0.3s ease-in-out;
  }

  &__usage-link {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    line-height: 30px;
  }

  &__usage-box {
    display: none;
    vertical-align: middle;

    &.estimate {
      line-height: 1; // to override the 30px global default
      padding: 12px;
    }
  }

  &__input:checked {
    ~ .c-gl-compare-widget__usage-box {
      display: block;

      &.estimate {
        @include slide-down-effect;
      }
    }

    + .c-gl-compare-widget__usage-link {
      display: block;
    }
  }

  &__person-wrapper--old {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .c-gl-compare-widget__person {
      flex: 0 1 26px;
    }
  }

  &__person-wrapper {
    border: 1px solid colors(grey, light);
    border-radius: $border-radius;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;

    @include media-query(desk) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &__person {
    color: colors(grey, light);
    cursor: pointer;

    svg {
      fill: currentColor;
      display: block;
    }

    &.active,
    &.hover-active {
      color: colors(grey, dark);
    }
  }

  &__electricity-usage,
  &__gas-usage,
  &__electricity-redelivery {
    color: colors(grey, base);
    font-size: 14px;
    cursor: pointer;
    white-space: nowrap;
    padding-top: 1px;
  }

  // Abstract mixin for setting electricity icon as background
  @mixin electricity-icon-bg {
    @include bg-image("icons/electricity.svg");
    padding-left: 20px;
  }

  // Abstract mixin for setting gas icon as background
  @mixin gas-icon-bg {
    @include bg-image("icons/gas.svg");
    padding-left: 20px;
  }

  // Abstract mixin for setting gas icon as background
  @mixin redelivery-icon-bg {
    @include bg-image("icons/solar-panel.svg");
    padding-left: 30px;
  }

  &__electricity-usage {
    @include electricity-icon-bg;
    background-position: left center;
  }

  &__gas-usage {
    @include gas-icon-bg;
    background-position: left center;
  }

  &__electricity-redelivery {
    @include redelivery-icon-bg;
    background-position: left 3px;
  }

  @at-root .c-gl-compare-widget__electricity-input,
  input.c-gl-compare-widget__electricity-input {
    @include electricity-icon-bg;
    background-position: 10px center;
    padding-right: 44px;
    text-align: right;
    
    &__with-pricing {
      padding-right: 60px;
    }
  }

  @at-root .c-gl-compare-widget__gas-input,
  input.c-gl-compare-widget__gas-input {
    @include gas-icon-bg;
    background-position: 10px center;
    padding-right: 36px;
    text-align: right;

    &__with-pricing {
      padding-right: 52px;
    }

    &[disabled] {
      &,
      & ~ * {
        opacity: 0.2;
      }
    }
  }

  @at-root .c-gl-compare-widget__redelivery-input,
  input.c-gl-compare-widget__redelivery-input {
    @include redelivery-icon-bg;
    background-position: 5px center;
    padding-right: 44px;
    text-align: right;

    &__with-pricing {
      padding-right: 60px;
    }
  }

  &__usage-unit {
    @include center-vertical;
    @include inuit-font-size(14px, 15px);
    color: colors(grey, base);
    right: 10px;
    pointer-events: none;

    &--left {
      left: 10px;
      right: auto;
    }
  }

  &__usage-business {
    @include slide-down-effect;
    border: 1px solid colors(status, green);
    border-radius: $border-radius;
    margin-bottom: 10px;
  }

  &__gas-input-wrapper {
    width: 50%;

    html.touchevents & {
      @media screen and (min-width: 64.0625em) and (max-width: 74.9375em) {
        width: 40%;
      }
      @media screen and (min-width: 47em) and (max-width: 56em) {
        width: 40%;
      }
      @media screen and (max-width: 25em) {
        width: 40%;
      }
    }
  }

  &__no-gas-wrapper {
    width: 50%;

    html.touchevents & {
      @media screen and (min-width: 64.0625em) and (max-width: 74.9375em) {
        width: 60%;
      }
      @media screen and (min-width: 45em) and (max-width: 56em) {
        width: 60%;
      }
      @media screen and (max-width: 25em) {
        width: 60%;
      }
    }
  }

  &__current-supplier {
    position: relative;
    margin-bottom: 10px;

    @include media-query(desk) {
      .c-filter-section__body & {
        padding-bottom: 30px;
      }
    }
  }

  &__move {
    position: absolute;
    white-space: nowrap;
    right: 0;

    @include media-query(desk) {
      .c-filter-section__body & {
        bottom: 0;
      }
    }
  }

  &__move-label {
    margin-left: 5px;
    color: colors(grey);
  }

  &__no-gas-info-icon {
    margin-bottom: -6px;

    html.no-touchevents & {
      display: none;
    }
  }

  &__no-gas-label {
    margin-left: 5px;
    color: colors(grey);

    html.no-touchevents & {
      border-bottom: 1px dashed;
    }

    @include media-query(palm) {
      @include typography(zeta);
    }

    .c-filter-section__body & {
      @include typography(small);

      @include media-query(portable) {
        @include typography(zeta);
      }

      @include media-query(desk-wide) {
        @include typography(zeta);
      }
    }

    .c-homepage__compareform-col & {
      @include media-query(lap) {
        @include typography(small);
      }

      @media screen and (min-width: 72em) {
        @include typography(zeta);
      }
    }
  }

  &__reset {
    @include bg-image("icons/trash-grey.svg");
    @include inuit-font-size(12px, 15px); // line-height is to make sure the background-image fits inside the parent
    color: colors(gray, base);
    display: inline-block;
    background-position: left center;
    padding-left: 25px; // width of image + 10px
    &:hover {
      text-decoration: none;
    }
  }

  &__animation {
    @include slide-down-effect;
  }

  @at-root input.c-gl-compare-widget__solarpanel-input {
    text-align: center;
    padding-right: 2px;
    padding-left: 2px;
  }

  &__solarpanel-min,
  &__solarpanel-plus {
    @include media-query(default) {
      &--large {
        padding-top: 18px;
        padding-bottom: 20px;
      }
    }
    text-align: center;
    font-size: 30px;
    line-height: 20px;
    border: 1px solid colors(grey, light);
    width: 100%;
    padding-top: 14px;
    padding-bottom: 14px;

    &:hover {
      text-decoration: none;
    }

    &:active {
      background-color: colors(grey, light);
    }

    &[disabled] {
      opacity: 0.3;
    }
  }

  &__solarpanel-min {
    border-right: none;
  }

  &__solarpanel-plus {
    border-left: none;
  }
}
