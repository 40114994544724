// Input & Textarea ------------------

// Fields with standard width

input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="color"],
input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
input[list],
select,
textarea {
  &:focus {
    box-shadow: 0 0 4px 0 rgba(colors(brand, support), 0.3);
    border-color: rgba(colors(brand, support), 0.2);
  }

  &.o-input--large {
    @include media-query(default) {
      padding: 18px 20px 20px;
    }
  }

  &.o-input--small {
    padding: 8px 15px 10px;
  }

  &.o-input--stripped {
    border: none;
  }

  &.o-input--rounded {
    border-radius: $border-radius;
  }
}

textarea {
  resize: vertical;
}

// Select ------------------

.o-select {
  position: relative;

  select {
    padding-right: 30px;
  }

  select::-ms-expand {
    display: none;
  }

  &__arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -15px;
    z-index: 1;
    height: 30px;
    line-height: 30px;
    width: 25px;
    text-align: right;
    pointer-events: none;
  }
}

.o-input-combined {
  border-radius: $base-radius;
  border: 1px solid colors(grey, light);

  &--split {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      bottom: 10px;
      width: 0;
      border-left: 1px solid colors(grey, light);
    }
  }
}

[data-dependson-input]:not([data-dependson-initialised]) {
  display: none !important;
}

