[role="button"],
[type="submit"],
[type="reset"],
[type="button"],
button {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(colors(black), 0);
}

// Reset `button` and button-style `input` default styles
[type="submit"],
[type="reset"],
[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: inherit;
  overflow: visible;
  padding: 0;
  margin: 0;
  appearance: button;
  user-select: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// Make `a` like a button
[role="button"] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  user-select: none;
}
