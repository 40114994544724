.c-landing-page {
  @include media-query(default) {
    @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));
  }

  &__header {
    @include media-query(palm) {
      @include gradient(gradients(gradient1, from), gradients(gradient1, to), gradients(gradient1, from));
    }

    @include media-query(default) {
      min-height: 150px;
    }
  }

  &__content {
    @include media-query(palm) {
      background-color: gradients(gradient1, from);
    }
  }

  &__idt-bg-image,
  &__pw-car-bg-image,
  &__be-phone {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;

    &--kind {
      top: auto;
      bottom: -50%;
    }
  }

  &__idt-bg-image {
    filter: brightness(1.1) hue-rotate(320deg) opacity(0.4);
  }

  &__be-phone {
    filter: brightness(1.1) hue-rotate(310deg) opacity(0.6);
  }


  &__idt-helpdesk-arrow {
    position: relative;
    padding-top: 60px;
    padding-right: 40px;

    &:before {
      content: "";
      @include bg-image("icons/curly-pointer-down2.svg");
      background-size: contain;
      transform: scaleY(-1) rotate(-90deg);
      display: block;
      position: absolute;
      transform-origin: bottom left;
      width: 30px;
      height: 80px;
      right: -40px;
      bottom: 40px;
      opacity: 0.8;
    }
  }

  &__idt-picture-wrapper {
    display: flex;
    align-self: stretch;
  }

  &__idt-picture[data-loaded] {
    align-self: flex-end;
  }

  &__pointer {
    position: absolute;
    margin-left: $inuit-base-spacing-unit;
    transform: scaleY(-1) rotate(140deg);
  }
}
