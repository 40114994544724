// ----------------------------------------------------------------------------
// The main heading of the home page
// ----------------------------------------------------------------------------

.c-homepage {
  &-header {
    @include media-query(default) {
      padding-top: 30px;
      padding-bottom: 80px;
    }

    @include media-query(palm) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  $custom-breakpoint: 64em;

  &__badge-button {
    display: block;
    background-color: brand-colors(nav);
    color: colors(white);
    z-index: 1;
    position: relative;
    overflow: hidden;
    padding: 15px 45px 15px 15px;
    border-radius: $border-radius $border-radius 0 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: brand-colors(nav, hover);
      z-index: -1;
      transform: translateZ(0) scale(0, 1);
      transform-origin: right center;
      transition: transform 1250ms $swift-ease-out-timing-function;
      -webkit-backface-visibility: hidden;
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      right: 45px;
    }

    &:hover {
      text-decoration: none;

      &:before {
        transform: translateZ(0) scale(1, 1);
      }
    }

    &-shorten {
      @media screen and (max-width: 21em) {
        display: none;
      }
    }

    &-year {
      @media screen and (max-width: 21em) {
        display: none;
      }
    }

    &-cta {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 45px;
      background-color: brand-colors(nav, hover);

      &:before {
        @include center;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%) rotate(90deg);
        background-image: url(#{$assetPathImages}icons/arrow-up-white.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 10px auto;
      }
    }
  }

  &__main-heading {
    color: colors(white);
    margin-bottom: 15px;

    @include media-query(palm) {
      @include inuit-font-size(28px, 1.2);
    }

    @include media-query(lap) {
      max-width: 60%;
    }

    @include media-query(default) {
      @include inuit-font-size(37px, 1.2);
    }
  }

  @media screen and (max-width: 63.9375em) {
    @include inuit-font-size(28px);
    max-width: 50%;
  }

  &__award-col,
  &__compareform-col {
    @include media-query(lap) {
      width: 30%;
    }

    @media screen and (max-width: $custom-breakpoint) {
      width: 40%;
    }

    @include media-query(desk) {
      width: 35%;
    }

    @include media-query(desk-wide) {
      width: 30%;
    }
  }

  &__compareform-col {
    @include media-query(palm) {
      width: 100%;
      background: gradients(gradient1, to);
      background: linear-gradient(to bottom, gradients(gradient1, to) 0%, gradients(gradient1, from) 100%);
      padding: 0 10px 0 30px;
    }
  }

  &__ekomi-col,
  &__top5-col {
    @include media-query(lap) {
      width: 70%;
    }

    @media screen and (max-width: $custom-breakpoint) {
      width: 60%;
    }

    @include media-query(palm) {
      width: 100%;
    }

    @include media-query(desk) {
      width: 65%;
    }

    @include media-query(desk-wide) {
      width: 70%;
    }
  }

  &__top5-col {
    @include media-query(palm) {
      padding-left: 30px;
    }
  }

  &__ekomi-col {
    position: relative;

    @include media-query(portable) {
      border-bottom: 1px solid colors(grey, light);
    }
  }

  &__compareform-col--new {
    min-width: 300px;
    max-width: 400px;


    @include media-query(lap) {
      max-width: 330px;
    }

    .c-homepage__compareform {
      margin-top: 0;
      position: relative;
    }
  }

  // Offsetting the compare widget
  &__compareform {
    @include media-query(default) {
      margin-top: -110px;
    }

    .c-idt-mobile-internet__button {
      text-align: left;
      padding-left: 80px;

      &-icon {
        content: "";
        width: 18px;
        height: 24px;
        background-image: url(#{$assetPathImages}icons/simcard.svg);
        background-repeat: no-repeat;
        background-size: 18px auto;
        position: absolute;
        top: calc(50% - 12px);
        left: 30px;
      }
    }

    &--border-radius {
      @include media-query(default) {
        border-radius: $border-radius $border-radius 0 $border-radius;
      }

      @include media-query(palm) {
        border-radius: $border-radius $border-radius 0 0;
      }
    }
  }

  &__palm-text-white {
    @include media-query(palm) {
      color: colors(white);
    }
  }
}
