
.c-account-loading {
  > * {
    opacity: 0.3;
  }

  @supports (-webkit-filter: blur(2px)) or (filter: blur(2px)) {
    filter: blur(5px);
    pointer-events: none;

    > * {
      opacity: 1;
    }
  }
}


.c-account-popup {
  min-height: 100vh;

  &__header {
    @include media-query(palm) {
      min-height: 64px;
    }

    @include media-query(default) {
      min-height: $header-height;
    }
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include bg-image("graphics/bencompare/logo-ben.svg");
    background-position: 0 0;
  }

  &__navigation {
    height: 50px;
    background-color: colors(bencompare, light);
    display: flex;

    a.c-main-navigation__active,
    a:hover {
      background-color: colors(bencompare, base) !important;
    }

    &-logout {
      flex-grow: 1;
      text-align: right;

      a.c-account-popup__navigation-logout-link {
        @include bg-image("icons/logout.svg");
        background-position: 15px center;
        padding-left: 45px;
        display: inline-block;
      }
    }
  }

  &__body {
    @include gradient( rgba(colors(bencompare, light),0.2),  rgba(colors(bencompare, base),0.2),  rgba(colors(bencompare, light),0.2), 90deg);
    min-height: calc(100vh - #{$header-height} - 50px);
    position: relative;

    &:before {
      content: "";
      top: 0;
      right: 0;
      left: 0;
      height: 5px;
      position: absolute;
      background-color: colors(brand, bencompare);
      z-index: 1;
      transform: scaleX(0);
      transform-origin: left top;
      transition: transform var(--account-loading-time) $swift-ease-in-out-timing-function;
    }

    &.c-account-loading {
      &:before {
        transform: scaleX(1);
      }
    }
  }

  &__app-promo {
    padding: 0 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 720px - 15px);
    text-align: center;
    z-index: 2000;
    color: colors(white);
    transform: scale(0);
    opacity: 0;

    &--visible {
      transition: opacity 3000ms 1000ms $swift-ease-out-timing-function;
      opacity: 1;
      transform: scale(1);
    }

    @media screen and (max-width: 56.25em) {
      display: none;
    }

    &-text {
      text-align: center;
      opacity: 0;
      transform: translateY(150px);
      transition: 750ms 250ms $swift-ease-in-out-timing-function;
    }

    &-example {
      width: 15vw;
    }

    &:hover {
      .c-account-popup__app-promo-text {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
