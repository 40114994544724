.u-dropshadow {
  box-shadow: 0 5px 25px 0 rgba(colors(black), 0.2);

  &--small {
    box-shadow: 0 0 8px 0 rgba(colors(black), 0.2);
  }
}

// Adds an box-shadow on hover, with a subtile fade animation. Also contains an active-state on mousedown/touch.
// Can also be used in combination with .u-dropshow since the hover dropshadow is heavier.
.u-dropshadow--hover {
  @include dropshadow--hover;
}

.u-text-shadow {
  @include text-shadow;
}

.u-text-shadow--heavy {
  @include text-shadow--heavy;
}
