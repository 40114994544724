//
// Font sizes
//

.alpha {
  @include typography(alpha);
  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-2, $inuit-heading-size-2-lineheight);
  }
}

.beta {
  @include typography(beta);
  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-3, $inuit-heading-size-3-lineheight);
  }
}

.gamma {
  @include typography(gamma);
  @include media-query(palm) {
    @include inuit-font-size($inuit-heading-size-4, $inuit-heading-size-4-lineheight);
  }
}

.delta {
  @include typography(delta);
}

.epsilon {
  @include typography(epsilon);
}

.zeta {
  @include typography(zeta);
}

.default-font-size {
  @include typography(default);
}

.small {
  @include typography(small);
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-pointer {
  cursor: pointer;
}

// usecase: reduce line-heights for some multi-line purpose font-sizes like default-font-size, delta, epsilon
.u-base-line-height {
  line-height: 1.2 !important;
}

@each $breakpoint in $breakpoints {
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {
    //    .#{$alias}-alpha { @include typography(alpha); }
    //    .#{$alias}-beta { @include typography(beta); }
    .#{$alias}-gamma {
      @include typography(gamma);
    }

    .#{$alias}-delta {
      @include typography(delta);
    }

    .#{$alias}-epsilon {
      @include typography(epsilon);
    }

    .#{$alias}-zeta {
      @include typography(zeta);
    }

    .#{$alias}-default-font-size {
      @include typography(default);
    }

    .#{$alias}-small {
      @include typography(small);
    }
  }
}

.u-weight--normal {
  @include font-weight--regular;
}

.u-weight--bold {
  @include font-weight--semi-bold;
}

.u-weight--italic-bold {
  @include font-weight--italic-bold;
}

.u-font-style--italic {
  font-style: italic;
}

.u-font-style--normal {
  font-style: normal;
}

.u-first-letter-transform--uppercase::first-letter {
  text-transform: uppercase;
}

.u-text-transform--uppercase {
  text-transform: uppercase;

  &[type="text"]::-webkit-input-placeholder {
    text-transform: none;
  }

  &[type="text"]::-moz-placeholder {
    text-transform: none;
  }

  &[type="text"]:-ms-input-placeholder {
    text-transform: none;
  }

  &[type="text"]:placeholder {
    text-transform: none;
  }
}

.u-text-transform--lowercase {
  text-transform: lowercase;
}

.u-text-transform--capitalize {
  text-transform: capitalize;
}

.u-text-decoration--underline {
  text-decoration: underline;
}

.u-text-decoration--dashed-underline {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-underline-offset: 0.2rem;
  text-decoration-thickness: from-font;
}

.u-text-decoration--none {
  text-decoration: none;
}

.u-hover-text-decoration--none:hover {
  text-decoration: none;
}

.u-overflow--ellipsis {
  @include overflow--ellipsis;
}

@include media-query(palm) {
  .u-palm-overflow--ellipsis {
    @include overflow--ellipsis;
  }
}

@include media-query(default) {
  .u-default-overflow--ellipsis {
    @include overflow--ellipsis;
  }
}

@include media-query(desk) {
  .u-desk-overflow--ellipsis {
    @include overflow--ellipsis;
  }
}

.u-white-space--nowrap {
  white-space: nowrap;
}

.u-desk-white-space--nowrap {
  @include media-query(desk) {
    white-space: nowrap;
  }
}

.u-default-white-space--nowrap {
  @include media-query(default) {
    white-space: nowrap;
  }
}

.u-palm-white-space--nowrap {
  @include media-query(palm) {
    white-space: nowrap;
  }
}

.u-palm-white-space--normal {
  @include media-query(palm) {
    white-space: normal;
  }
}
