﻿.c-ben-woldring {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: 100px;
  max-height: 100%;
  padding-top: 3px;

  @include media-query(lap) {
    margin-right: 5px;
  }

  @include media-query(palm) {
    display: none;
  }
}

.c-bencom-labels {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;

  @include media-query(palm) {
    flex-direction: column;
    gap: 10px;
  }

  &__item {
    background: linear-gradient(to bottom, rgba(gradients(gradient3, from), 1) 0%, rgba(gradients(gradient3, to), 1) 100%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 300px;

    @include media-query(desk) {
      border-radius: $border-radius-large;
      padding: 30px;
    }

    @include media-query(lap) {
      border-radius: $border-radius;
      padding: 15px;
    }

    @include media-query(palm) {
      border-radius: $border-radius;
      flex-direction: row;
      padding: 10px;
      width: auto;
      gap: 10px;
      align-items: center;
    }
  }

  &__icon {
    align-self: center;

    @include media-query(desk) {
      padding-top: 20px;

      svg {
        max-height: 132px;
        max-width: 132px;
      }
    }

    @include media-query(lap) {
      svg {
        max-height: 90px;
        max-width: 90px;
      }
    }

    @include media-query(palm) {
      height: 40px;

      svg {
        max-height: 40px;
        max-width: 40px;
      }
    }

    &--gradient1 {
      stop-color: gradients(gradient1, from);
    }

    &--gradient2 {
      stop-color: gradients(gradient1, to);
    }

    &--gradient1-to {
      fill: gradients(gradient1, to);
    }
  }

  &__logo {
    height: 36px;
    width: auto !important;

    &-wrapper {
      text-align: center;

      @include media-query(palm) {
        width: 130px;
        flex-shrink: 0;
      }
    }
  }

  &__links {
    flex-grow: 1;
  }
}
