.layout {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -20px;
  flex-wrap: wrap;

  &__item {
    display: inline-block;
    width: 100%;
    padding-left: 20px;
    @include inuit-font-size($inuit-base-font-size, none);
  }

  &--small {
    margin-left: -10px;

    > .layout__item {
      padding-left: 10px;
    }
  }

  &--flush {
    margin-left: 0;

    > .layout__item {
      padding-left: 0;
    }
  }

  &--rev {
    flex-direction: row-reverse;
  }

  &--middle {
    align-items: center;
  }

  @include media-query(default) {
    &-default--middle {
      align-items: center;
    }
  }

  &--bottom {
    align-items: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  &--auto > &__item {
    flex-basis: content;
  }
}
