.c-button-wizard {
  input:checked + & {
    background-color: colors(white);
    color: colors(black);
    border-color: colors(white);
  }
}

@keyframes c-wizard-appear {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes c-wizard-disappear {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

.c-wizard-appear {
  opacity: 0;
  transform: translateY(50px);
  animation-name: c-wizard-appear;
  animation-duration: 700ms;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-timing-function: $swift-ease-out-timing-function;
  animation-fill-mode: forwards;
}

.c-wizard-steps__step {
  display: block;
  white-space: nowrap;
  padding: 4px 20px 5px;
  background-color: rgba(colors(white), 0.25);
  transition: padding 200ms;
  position: relative;

  &-bg-color-nav {
    background-color: brand-colors(nav);

    &:active {
      background-color: colors(white);
    }
  }

  @include media-query(desk) {
    min-width: 220px;
  }

  @include media-query(palm) {
    display: inline-block;
    font-size: 0;
    padding: 4px 15px 5px;
    background-color: rgba(colors(white), 0.5);
  }

  &--active,
  &--active ~ & {
    color: colors(white);
    font-weight: normal;
    background-color: rgba(colors(white), 0.05);

    @include media-query(palm) {
      background-color: rgba(colors(white), 0.25);
    }
  }

  &--active {
    padding: 7px 10px 8px 30px;

    @include media-query(default) {
      margin-left: -$border-radius-small;
      margin-right: -10px;
      box-shadow: 0 0 8px 0 rgba(colors(black), 0.2);
      z-index: 1;
      border-bottom-left-radius: $border-radius-small;
      border-top-left-radius: $border-radius-small;

      &:before {
        content: "";
        @include bg-image("icons/bg-arrow-right.svg");
        z-index: 1;
        top: 0;
        bottom: 0;
        right: -6px;
        width: 10px;
        position: absolute;
        background-position: center right;
        background-size: cover;
      }
    }
  }

  &--active,
  &:hover {
    text-decoration: none;
    background-color: colors(white);
    color: colors(black);
  }

  &--hidden {
    display: none !important;
  }
}

.c-cost-tile {
  background-color: rgba(colors(white), 0.5);
  padding: 10px;
  color: colors(black);
  text-align: center;
  height: 160px;
  position: relative;
  box-shadow: 0 1px 20px 0 rgba(colors(black), 0.05);
  border-radius: $border-radius;

  &__inactive {
    @include dropshadow--hover;
  }

  &__activated {
    background-color: colors(white);
  }

  &__remove {
    @include size(20px);
    background-color: colors(grey, dark);
    border-radius: $border-radius-full;
    line-height: 20px;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;

    img {
      @include center;
      width: 36%;
    }
  }
}