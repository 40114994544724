@mixin text-shadow {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@mixin text-shadow--heavy {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.63);
}

@mixin dropshadow--hover {
  position: relative;
  transition: all 100ms;
  transform-origin: top left;
  color: colors(grey, dark);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: $swift-ease-in-out;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: inherit;

    @include media-query(palm) {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &:hover {
    text-decoration: none;

    &::after {
      opacity: 1;
    }
  }

  &:active {
    transform: translateX(2px) translateY(2px) scale(0.99);
  }
}
