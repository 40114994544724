﻿.c-ratingsnumbers {
  &__label {
    @include typography(small);
    color: colors(grey, dark);
    bottom: quarter(-$inuit-base-spacing-unit);
    position: absolute;
    text-transform: lowercase;
    white-space: nowrap;

    &--negative {
      left: 10px;
      text-align: left;

      @include media-query(portable) {
        top: 10px;
        bottom: auto;
        transform-origin: top left;
      }
    }

    &--positive {
      right: 0;
      text-align: right;

      @include media-query(portable) {
        bottom: 5px;
        transform-origin: bottom right;
      }

      @include media-query(palm) {
        right: -5px;
      }

      @include media-query(lap) {
        right: 5px;
      }
    }

    @include media-query(portable) {
      transform: rotate(90deg);
    }
  }

  &__button {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}
