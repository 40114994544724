﻿.c-site-search {
  white-space: nowrap;
  position: absolute;
  right: $inuit-base-spacing-unit;
  top: halve($inuit-base-spacing-unit);

  @include media-query(palm) {
    display: none;
    z-index: 3;
    left: 0;
    right: 0;
    top: 45px;

    .c-main-navigation--active ~ & {
      display: block !important;
    }
  }

  &__checkbox-enable {
    @include media-query(palm) {
      + .c-site-search {
        display: none;
      }

      &:checked + .c-site-search {
        display: block;
        z-index: 5;
      }

      &:checked ~ .c-main-navigation {
        padding-top: 20px !important;
      }
    }
  }

  &__button {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 0;
    transform: translateY(2px);

    svg {
      fill: colors(grey, light);
    }

    @include media-query(palm) {
      top: 10px;
      right: 20px;
    }
  }

  &__label {
    display: none;
  }

  &__suggestions {
    position: absolute;
    bottom: -40px;
    padding: 10px;
    right: 0;
    max-height: 0;
    transform: scaleY(0);
    transform-origin: top center;
    overflow: hidden;
    transition: $swift-ease-in-out;

    @include media-query(palm) {
      left: 0;
      white-space: normal;
    }
  }
}

input.c-site-search__input {
  padding: 5px 25px 5px 5px;
  border: 0;
  display: inline-block;
  color: colors(white);
  text-align: left;
  transition: $swift-ease-in-out;
  width: 80px;
  background-color: transparent;

  @include media-query(default) {
    font-weight: bold;
  }

  @include media-query(lap) {
    width: 0;
    z-index: 1;
    position: relative;
  }

  @include media-query(palm) {
    width: 100%;
    background-color: brand-colors(nav);
    padding: 10px 20px;
    font-size: 20px;
    border-radius: 0;
  }

  .c-site-search__checkbox-enable:checked + .c-site-search &,
  &--prefilled,
  &:focus,
  &--focussed {
    width: 150px;
    padding-left: 10px;
    background-color: lighten(brand-colors(nav, base), 7%);

    @include media-query(palm) {
      width: 100%;
    }

    @include media-query(lap) {
      z-index: 0;
    }

    ~ .c-site-search__suggestions {
      max-height: 50px;
      transform: scaleY(1);
      opacity: 1;
    }

    + .c-site-search__button {
      svg {
        fill: colors(white);
      }
    }

    &::-webkit-input-placeholder {
      // Chrome/Opera/Safari
      opacity: 0;
    }

    &::-moz-placeholder {
      // Firefox 19+
      opacity: 0;
    }

    &:-ms-input-placeholder {
      // IE 10+
      opacity: 0;
    }
  }

  &::-webkit-input-placeholder {
    // Chrome/Opera/Safari
    font-weight: normal;
    text-align: center;
    color: colors(white);
  }

  &::-moz-placeholder {
    // Firefox 19+
    font-weight: normal;
    text-align: center;
    color: colors(white);
  }

  &:-ms-input-placeholder {
    // IE 10+
    font-weight: normal;
    text-align: center;
    color: colors(white);
  }
}

.c-search-result {
  b {
    position: relative;
    display: inline-block;
    z-index: 1;

    &::before {
      background-color: colors(status, yellow);
      border-radius: $border-radius-tiny;
      bottom: 0;
      content: "";
      left: -3px;
      opacity: 0.3;
      position: absolute;
      right: -3px;
      top: 0;
      z-index: -1;
    }
  }
}
