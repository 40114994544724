// *------------------------------------*\
//    #SETTINGS
// *------------------------------------*/

// High-level base settings.
$inuit-base-font-size: 16px;
$inuit-base-line-height: 30px;

$inuit-base-spacing-unit: 20px;

$inuit-base-text-color: colors(grey, dark);
$inuit-base-background-color: colors(white);

$base-radius: 0;

// *------------------------------------*\
//    #HEADINGS
// *------------------------------------*/

//
// Headings 1–6.
//

$inuit-heading-size-1: 48px;
$inuit-heading-size-1-lineheight: 50px;

$inuit-heading-size-2: 32px;
$inuit-heading-size-2-lineheight: 35px;

$inuit-heading-size-3: 28px;
$inuit-heading-size-3-lineheight: 28px;

$inuit-heading-size-4: 23px;
$inuit-heading-size-4-lineheight: 30px;

$inuit-heading-size-5: 20px;
$inuit-heading-size-5-lineheight: 30px;

$inuit-heading-size-6: 14px;
$inuit-heading-size-6-lineheight: 20px;

$small-font-size: 12px;
$small-font-size-lineheight: 16px;

$button-font-size: 18px;

///*------------------------------------*\
//    #SETTINGS-RESPONSIVE
//\*------------------------------------*/

// Hold our breakpoint aliases and conditions in a list.
//
// These can be invoked later on via the `media-query()` mixin found in
// `_tools.responsive`.

$breakpoints: (
  "palm" "screen and (max-width: 46.9375em)",/* 751px*/
  "lap" "screen and (min-width: 47em) and (max-width: 74.9375em)",/* 752px - 1199px*/
  "default" "screen and (min-width: 47em), print",/* 752px*/
  "portable" "screen and (max-width: 74.9375em)",/* 1199px*/
  "desk" "screen and (min-width: 75em), print",/* 1200px*/
  "desk-wide" "screen and (min-width: 90.0625em)" /* 1440px*/
);

// If we have included this file, set a variable to tell the rest of the
// framework that we have some responsive settings.
$inuit-responsive-settings: true;
$inuit-enable-list-inline--delimited: true;

// *------------------------------------*\
//   #LAYOUT
// *------------------------------------*/

// The inuitcss layout system uses `box-sizing: border-box;` and
// `display: inline-block;` to create an extremely powerful, flexible
// alternative to the traditional grid system. Combine the layout items with
// the widths found in `trumps.widths`.
//
// Predefine the variables below in order to alter and enable specific features.
$inuit-layout-namespace: $inuit-namespace;

//$inuit-layout-gutter:           20px;

//$inuit-enable-layout--tiny:     false;
$inuit-enable-layout--small: true;
//$inuit-enable-layout--large:    false;
//$inuit-enable-layout--huge:     false;
$inuit-enable-layout--flush: true;
$inuit-enable-layout--rev: true;
$inuit-enable-layout--middle: true;
$inuit-enable-layout--bottom: true;
//$inuit-enable-layout--right:    false;
$inuit-enable-layout--center: true;
$inuit-enable-layout--auto: true;

// *------------------------------------*\
//    #WIDTHS
// *------------------------------------*/

// A series of width helper classes that you can use to size things like grid
// systems. Classes can take a fraction-like format (e.g. `.2/3`) or a spoken-
// word format (e.g. `two-thirds`). Toggle formats by overriding the
// `$inuit-use-fractions` variable defined below.

$inuit-widths-columns: (1, 2, 3, 4, 5, 10, 24);

$inuit-widths-columns-responsive: $inuit-widths-columns;
// *------------------------------------*\
// #SPACING
// *------------------------------------*/
// Margin and padding helper classes. Use these to tweak layout on a micro
// level.

// `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
$inuit-enable-margins: true;
$inuit-enable-margins--tiny: true;
$inuit-enable-margins--small: true;
$inuit-enable-margins--large: true;
$inuit-enable-margins--huge: true;
$inuit-enable-margins--negative: false;
$inuit-enable-margins--negative-tiny: false;
$inuit-enable-margins--negative-small: true;
$inuit-enable-margins--negative-large: true;
$inuit-enable-margins--negative-huge: false;
$inuit-enable-margins--none: true;

$inuit-enable-paddings: true;
$inuit-enable-paddings--tiny: true;
$inuit-enable-paddings--small: true;
$inuit-enable-paddings--large: true;
$inuit-enable-paddings--huge: true;

$inuit-enable-paddings--none: true;

// *------------------------------------*\
// #SPACING-RESPONSIVE
// *------------------------------------*/
// Margin and padding helper classes. Use these to tweak layout on a micro
// level.

// `.(m|p)(t|r|b|l|h|v)(-|+|0) {}` = margin/padding top/right/bottom/left/horizontal/vertical less/more/none
// Predefine the variables below in order to alter and enable specific features.
$inuit-enable-responsive-margins: true;
$inuit-enable-responsive-margins--tiny: true;
$inuit-enable-responsive-margins--small: true;
$inuit-enable-responsive-margins--large: true;
$inuit-enable-responsive-margins--huge: true;
$inuit-enable-responsive-margins--negative: false;
$inuit-enable-responsive-margins--negative-tiny: false;
$inuit-enable-responsive-margins--negative-small: false;
$inuit-enable-responsive-margins--negative-large: false;
$inuit-enable-responsive-margins--negative-huge: false;
$inuit-enable-responsive-margins--none: true;

$inuit-enable-responsive-paddings: true;
$inuit-enable-responsive-paddings--tiny: true;
$inuit-enable-responsive-paddings--small: true;
$inuit-enable-responsive-paddings--large: true;
$inuit-enable-responsive-paddings--huge: true;

$inuit-enable-responsive-paddings--none: true;

// Needed to fix inuitcss mixin
// $inuit-generate-spacing-alias: null;
