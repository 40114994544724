// ----------------------------------------------------------------------------
// Utility that converts colored elements into "black and white" images
// ----------------------------------------------------------------------------

.u-grayscale {
  filter: grayscale(100%);
}

.u-whiten {
  filter: grayscale(100%) brightness(100) opacity(0.5);
}