hr {
  border: 0;
  border-bottom: 1px solid colors(grey, light);
}

ul {
  list-style-type: disc;
}

abbr[title] {
  text-decoration: none;
}
