// ----------------------------------------------------------------------------
// Ekomi tweaks
// ----------------------------------------------------------------------------

.c-ekomi {
  &__home-header {
    position: absolute;
    left: -250px;
    top: 30px;
    width: 230px;
    color: colors(white);

    @media screen and (min-width: 60em) and (max-width: 64em) {
      top: 10px;
      left: -210px;
    }

    @media screen and (max-width: 60em) {
      display: none;
    }
  }

  @include media-query(default) {
    @include center-vertical;
    -webkit-backface-visibility: hidden;
  }

  &-wrapper-right & {
    right: 0;
  }

  &__logo {
    width: 80px;

    &--resultheader {
      @include media-query(palm) {
        width: 60px;
        padding-right: 10px;
      }
    }
  }

  &__rating {
    @include inuit-font-size($inuit-heading-size-2, $inuit-heading-size-3-lineheight);
    font-family: $spec-face;
    font-style: normal;
  }

  &__visual-ben {

    @include media-query(desk) {
      height: 174px;
      width: 149px;
    }

    @include media-query(lap) {
      height: 141px;
      width: 120px;
    }

    @include media-query(palm) {
      height: 110px;
      width: 94px;
    }
  }
}

.c-speech-bubble {
  padding: 20px;
  position: relative;
  background: colors(white);
  border-radius: $border-radius;
  height: 130px;
  width: 300px;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: colors(white);
    border-left: 0;
    margin-top: -20px;
    margin-left: -20px;

    @include media-query(palm) {
      content: unset;
    }
  }
}
