﻿@include media-query(portable) {
  $filter-bar-width: 420px;
  $filter-button-height: 80px;

  .c-filters {
    display: block;
    position: fixed;
    top: $filter-button-height;
    left: 0;
    right: 0;
    height: calc(100vh - #{$filter-button-height});
    background: gradients(gradient3, from);
    background: linear-gradient(to top, gradients(gradient3, from) 0%, gradients(gradient3, to) 100vh, gradients(gradient3, to) 100%);
    z-index: 5;
    transform: translateX(-100%);
    transform-origin: right top;
    opacity: 0;
    transition: $swift-ease-in-out;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-left: 0;
    max-width: $filter-bar-width;
    box-shadow: 0 0 5px 5px rgba(colors(black), 0.25);

    &__checkbox {
      position: absolute;
      top: 0;
    }

    .c-filters__checkbox:checked ~ & {
      transform: translate3d(0%,0,0);
      opacity: 1;
    }

    &--bottom {
      top: 0;
    }
  }

  .c-filters-top-button,
  .c-filters-bottom-button {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 101;
    height: $filter-button-height;
    box-shadow: 0 0 5px 5px rgba(colors(black), 0.25);
    max-width: $filter-bar-width;
    transition: $swift-ease-in-out;
    opacity: 0;

    .c-filters__checkbox:checked ~ & {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  .c-filters-top-button {
    top: 0;
    transform: translateY(-150%);
  }

  .c-filters-bottom-button {
    bottom: 0;
    transform: translateY(150%);
  }

  .c-filters-background {
    /* avoid scrolling */
    overflow: hidden;
    height: 100vh;

    &__label {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(gradients(gradient3,from), 1) 0%, rgba(gradients(gradient3,to), 1) 100%);
      z-index: 4;
      opacity: 0;
      transform: scaleX(0);
      transform-origin: left bottom;
      transition: opacity 500ms;
    }

    & &__label {
      /* Make layer visible */
      transform: scaleX(1);
      opacity: 0.5;
    }
  }

  .c-filter-floating-button {
    position: fixed;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    transform: translateY(-20px);
    transition: transform 0.7s ease;

    &--compare-bar-offset {
      transform: translateY(-55px);
    }

    .c-button {
      box-shadow: 0 0 10px 0 rgba(colors(black), 1);
    }
  }
}
