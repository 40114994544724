﻿/* This is the core CSS of Tooltipster */
.c-tooltip-trigger {
  cursor: default;
}

.c-tooltip-trigger a,
.c-tooltip-trigger button,
a.c-tooltip-trigger,
button.c-tooltip-trigger,
label.c-tooltip-trigger {
  cursor: pointer;
}

.c-tooltip-trigger--text {
  font-weight: bold;
  font-style: italic;
}

.c-tooltip {
  display: none;
}

.tooltipster-base {
  /* this ensures that a constrained height set by functionPosition,
  if greater that the natural height of the tooltip, will be enforced
  in browsers that support display:flex */
  display: flex;
  pointer-events: none;
  /* this may be overriden in JS for fixed position origins */
  position: absolute;
}

.tooltipster-box {
  /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
  and flex-basis auto for IE11- (at least) */
  flex: 1 1 auto;
}

.tooltipster-content {
  /* prevents an overflow if the user adds padding to the div */
  box-sizing: border-box;
  /* these make sure we'll be able to detect any overflow */
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.tooltipster-ruler {
  /* these let us test the size of the tooltip without overflowing the window */
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
  opacity: 0;
  transition-property: opacity;
}

.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

/* grow */

.tooltipster-grow {
  transform: scale(0.5,0.5);
  opacity: 0;
  backface-visibility: hidden;
}

.tooltipster-grow.tooltipster-show {
  transform: scale(1,1);
  opacity: 1;
  transition-timing-function: $swift-ease-out-timing-function;
}

/* swing */

.tooltipster-swing {
  opacity: 0;
  transform: rotateZ(4deg);
  transition-property: transform;
}

.tooltipster-swing.tooltipster-show {
  opacity: 1;
  transform: rotateZ(0deg);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
  transition-property: top;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}

.tooltipster-fall.tooltipster-dying {
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}

/* slide */

.tooltipster-slide {
  transition-property: left;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
}

.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}

.tooltipster-slide.tooltipster-dying {
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* scale */

@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tooltipster-update-scale {
  animation: tooltipster-scaling 600ms;
}

/**
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 *
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins.
 */

/* .tooltipster-box */

.tooltipster-sidetip {
  .tooltipster-box {
    background: #565656;
    border: 2px solid black;
    border-radius: $border-radius-tiny;
  }

  &.tooltipster-bottom .tooltipster-box {
    margin-top: 8px;
  }

  &.tooltipster-left .tooltipster-box {
    margin-right: 8px;
  }

  &.tooltipster-right .tooltipster-box {
    margin-left: 8px;
  }

  &.tooltipster-top .tooltipster-box {
    margin-bottom: 8px;
  }

  /* .tooltipster-content */

  .tooltipster-content {
    color: white;
    line-height: 18px;
    padding: 6px 14px;
  }

  /* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
  corresponds to the arrow we want to display */

  .tooltipster-arrow {
    overflow: hidden;
    position: absolute;
  }

  &.tooltipster-bottom .tooltipster-arrow {
    height: 10px;
    /* half the width, for centering */
    margin-left: -10px;
    top: 0;
    width: 20px;
  }

  &.tooltipster-left .tooltipster-arrow {
    height: 20px;
    margin-top: -10px;
    right: 0;
    /* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
    been positioned yet */
    top: 0;
    width: 10px;
  }

  &.tooltipster-right .tooltipster-arrow {
    height: 20px;
    margin-top: -10px;
    left: 0;
    /* same as .tooltipster-left .tooltipster-arrow */
    top: 0;
    width: 10px;
  }

  &.tooltipster-top .tooltipster-arrow {
    bottom: 0;
    height: 10px;
    margin-left: -10px;
    width: 20px;
  }

  /* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

  .tooltipster-arrow-background,
  .tooltipster-arrow-border {
    height: 0;
    position: absolute;
    width: 0;
  }

  /* .tooltipster-arrow-background */

  .tooltipster-arrow-background {
    border: 10px solid transparent;
  }

  &.tooltipster-bottom .tooltipster-arrow-background {
    border-bottom-color: #565656;
    left: 0;
    top: 3px;
  }

  &.tooltipster-left .tooltipster-arrow-background {
    border-left-color: #565656;
    left: -3px;
    top: 0;
  }

  &.tooltipster-right .tooltipster-arrow-background {
    border-right-color: #565656;
    left: 3px;
    top: 0;
  }

  &.tooltipster-top .tooltipster-arrow-background {
    border-top-color: #565656;
    left: 0;
    top: -3px;
  }

  /* .tooltipster-arrow-border */

  .tooltipster-arrow-border {
    border: 10px solid transparent;
    left: 0;
    top: 0;
  }

  &.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: black;
  }

  &.tooltipster-left .tooltipster-arrow-border {
    border-left-color: black;
  }

  &.tooltipster-right .tooltipster-arrow-border {
    border-right-color: black;
  }

  &.tooltipster-top .tooltipster-arrow-border {
    border-top-color: black;
  }

  /* tooltipster-arrow-uncropped */

  .tooltipster-arrow-uncropped {
    position: relative;
  }

  &.tooltipster-bottom .tooltipster-arrow-uncropped {
    top: -10px;
  }

  &.tooltipster-right .tooltipster-arrow-uncropped {
    left: -10px;
  }
}

/** THEME SHADOW */

.tooltipster-sidetip.tooltipster-shadow .tooltipster-box {
  border: none;
  background: colors(white);
  box-shadow: 0 10px 35px 0 rgba(colors(black), 0.35);
  margin-left: $inuit-base-spacing-unit;
  margin-right: $inuit-base-spacing-unit;
  border-radius: $border-radius-small;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-bottom .tooltipster-box {
  margin-top: 10px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-left .tooltipster-box {
  margin-right: 10px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-box {
  margin-left: 10px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-top .tooltipster-box {
  margin-bottom: 10px;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-content {
  color: colors(grey,dark);
  @include typography(zeta);
  padding: $inuit-base-spacing-unit;
  //max-width:575px;
  @include media-query(palm) {
    max-width: 100%;
  }
}

.tooltipster-small-padding .tooltipster-content {
  padding: 0 10px 10px !important;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-arrow {
  height: 10px;
  margin-left: -10px;
  width: 20px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-left .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-left: 0;
  margin-top: -10px;
  width: 10px;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-arrow-background {
  display: none;
}

.tooltipster-sidetip.tooltipster-shadow .tooltipster-arrow-border {
  border: 10px solid transparent;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: colors(white);
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-left .tooltipster-arrow-border {
  border-left-color: colors(white);
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-arrow-border {
  border-right-color: colors(white);
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-top .tooltipster-arrow-border {
  border-top-color: colors(white);
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px;
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-dark .tooltipster-box {
  background: colors(brand,support);

  .tooltipster-content {
    color: colors(white);
  }
}

.tooltipster-sidetip.tooltipster-shadow.tooltipster-dark.tooltipster-right .tooltipster-arrow-border {
  border-right-color: colors(brand,support);
}

@keyframes blackoutFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.c-tooltip--blackout {
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  background-color: rgba(colors(grey, dark), 0.9);
  z-index: 2;
  opacity: 0;
  animation: blackoutFadeIn 500ms 1 forwards;

  @include media-query(palm) {
    top: 0px;
  }
  @include media-query(default) {
    top: 150px;
  }

  &-sticky {
    top: 0;
    bottom: 0;
    position: fixed;

    @include media-query(default) {
      background-color: rgba(colors(black), 0.4);
      transform-origin: top center;
      animation-duration: 0ms;
    }

    html:has(&) {
      overflow: hidden;
    }
  }

}

.tooltipster-account {
  .tooltipster-box {
    box-shadow: none !important;

    @include media-query(default) {
      max-height: calc(100vh - 175px);
    }
  }

  .tooltipster-content {
    padding: 0 !important;
    font-size: 1rem !important;

    @include media-query(default) {
      max-height: calc(100vh - 175px);
    }
  }

  &.tooltipster-shadow .tooltipster-box {
    border-radius: 0 0 $border-radius $border-radius !important;
  }
}

.tooltipster-navigation {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    background-color: rgba(colors(black), 0.5);
    z-index: -1;
    pointer-events: none;
  }

  &__arrow {
    $navArrowSize: 10px;
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    margin-left: -$navArrowSize;
    border-style: solid;
    border-width: $navArrowSize $navArrowSize 0 $navArrowSize;
    border-color: darken(brand-colors(nav, hover), 3%) transparent transparent transparent;
    pointer-events: none;
    z-index: 2;

    @include media-query(palm) {
      display: none;
    }
  }

  .tooltipster-box {
    border-radius: 0 0 !important;
  }

  .tooltipster-content {
    padding: 0 !important;
    font-size: 1rem !important;

    @media screen and (max-width: 83.75em) {
      .o-container {
        width: 100%;
      }
    }
  }
}
