//.parsley-error,
textarea.parsley-error,
input.parsley-error,
select.parsley-error {
  border: 1px solid colors(status, red);
}

.parsley-errors-list {
  @include typography(zeta);
  color: colors(status, red);
  list-style: none none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    margin: 3px 0;
    display: block;
  }
}
