﻿.compare-table, /*legacy for contentitems*/
.c-compare-table {
  margin: 0 auto;
  width: 99%;
  max-width: 99%;
  background: gradients(gradient1, from);
  background: linear-gradient(to right, rgba(gradients(gradient1, from), 1) 0%, rgba(gradients(gradient1, to), 1) 100%);
  border-top: 1px solid colors(grey, x-light);

  [style*="00ff00"] {
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: $border-radius-tiny;
    color: colors(status, green) !important;
    background-color: desaturate(lighten(colors(status, green), 70%), 55%);
  }

  th {
    background-color: colors(white);
    box-shadow: 0 3px 5px 0 rgba(colors(black), 0.2);
  }

  td {
    @include typography(delta);
    color: colors(white);
    box-shadow: 0 0 3px 0 rgba(colors(white), 0.2);
    font-style: italic;

    @include media-query(palm) {
      background-color: gradients(gradient1, to);
    }

    img[src*="vink.jpg"] {
      position: absolute;
    }

    .compare-table__check {
      position: absolute;
      margin-left: 10px;
      margin-top: 10px;
      background-color: transparent !important;
      height: auto !important;
    }
  }

  th,
  td {
    text-align: center;
    padding: halve($inuit-base-spacing-unit) $inuit-base-spacing-unit;
    position: relative;

    [style="color:#008000;"] {
      background-color: colors(status, marked);
      color: colors(status, green);
      display: inline-block;
      padding: 1px 5px 2px;
    }

    &:first-child {
      @include typography(default);
      text-align: right;
      font-style: normal;
      font-weight: normal;
    }
  }

  tr:last-child {
    th,
    td {
      @include typography(epsilon);
    }
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  a {
    color: colors(white);
    text-decoration: underline;

    &:hover {
      color: colors(white);
      text-decoration: none;
    }
  }
}
