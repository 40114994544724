﻿// ----------------------------------------------------------------------------
// Generic logo wrapper that automatically centers a logo inside a div with
// the class .c-logo and maintains the proper dimensions
// ----------------------------------------------------------------------------

.c-logo {
  position: relative;
  display: block;

  img {
    @include center;
    height: auto;
    max-height: 70%;
    max-width: 70%;
  }
}

// ----------------------------------------------------------------------------

.c-bencom-label {
  @include center-vertical;

  @include media-query(default) {
    float: left;
    white-space: nowrap;
  }

  &__logo {
    display: inline-block;

    @include media-query(default) {
      margin-right: quarter($inuit-base-spacing-unit);
    }

    @include media-query(palm) {
      &--smaller {
        width: 48vw;
      }
    }
  }

  &__ankeiler {
    @include typography(zeta);
    font-weight: bold;
    display: inline-block;
    color: colors(brand, support);

    @media screen and (max-width: 57.875em) {
      display: none;
    }
  }
}

.c-bencom-25year {
  @include typography(zeta);
  z-index: 1;
  color: colors(grey, dark);
  fill: colors(brand, support);
  position: absolute;
  right: $inuit-base-spacing-unit;
  top: 7px;
  margin-right: 100px;
  padding: 20px 40px 20px 20px;

  &:hover {
    text-decoration: none;
  }

  @include bg-image("graphics/confetti.svg");
  background-size: contain;
  background-position: right center;

  @include media-query(palm) {
    right: 0;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    margin-right: 0;
    padding-right: 0;
  }
}