.c-solarpanels-radiobutton-message {
  margin-top: 25px;
  position: relative;
  background-color: lighten(colors(brand, cta), 48%);
  padding: 20px;
  border-radius: $border-radius-small;
  margin-bottom: 20px;
  display: none;

  &--arrow-left::after {
    left: 20%;
  }

  &--arrow-right::after {
    right: 20%;
  }

  &--arrow-left,
  &--arrow-right {
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      width: 20px;
      height: 20px;
      background: lighten(colors(brand, cta), 48%);
      transform: rotate(-45deg);
    }
  }
}

.c-solarpanels-infoblock {
  background-color: rgba(colors(white), 0.7);
}

.c-solarpanels-background {
  @include media-query(desk) {
    @include bg-image("graphics/gaslicht/bg-solarpanels.svg");
    background-position: right bottom;
    background-size: auto 500px;
    padding-bottom: 200px;
  }
  @include media-query(portable) {
    padding-bottom: 40px;
  }
}

.c-solarpanels-cashback-badge {
  text-align: center;
  position: absolute;
  right: 50px;
  top: -60px;
  background-color: colors(status, orange);
  color: colors(white);
  border-radius: $border-radius-full;
  height: 120px;
  width: 120px;

  @include media-query(portable) {
    right: 20px;
    top: -40px;
  }

  &__wrapper {
    @include center;
  }

  &__amount {
    @include typography(gamma);
    font-family: $spec-face;
    font-style: normal;
  }

  &__label {
    @include typography(epsilon);
  }
}

.c-solarpanels-streetview-spacer {
  @include media-query(palm) {
    height: 25px;

    img ~ & {
      display: none;
    }
  }
}

.c-solarpanel-image-wrapper {
  height: 90px;

  img {
    max-height: 65px;
    max-width: 75px;
  }

  &--big {
    img {
      max-height: 40px;
      max-width: 190px;
    }
  }
}

.c-solarpanel-packagetag {
  border-radius: $border-radius $border-radius 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-weight: bold;
}

.c-solarpanel-brandtag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: colors(white);
  position: absolute;
  padding: 5px;
  opacity: 0.93;
  border: 1px solid colors(grey, light);
  border-radius: $border-radius-small;

  &--solarpanel {
    top: 130px;
    right: 7%;
    height: 35px;
    min-width: 100px;

    @include media-query(palm) {
      top: 70px;
      right: 10px;
      padding: 0 5px;
      min-width: 75px;
      max-width: 115px;
      height: 30px;
    }

    img {
      max-height: 20px;
      max-width: 110px;
    }
  }

  &--inverter {
    bottom: 0;
    left: 20px;

    @include media-query(palm) {
      bottom: 8px;
    }

    img {
      max-height: 25px;
      max-width: 80px;
    }
  }
}

@keyframes solarpanel-inverters-slidedown {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

input.c-solarpanels-fadeout[type="radio"] + label {
  opacity: 0.5;
  transition: opacity 500ms;

  .c-solarpanel-amount,
  .c-inverter-amount {
    color: colors(grey, dark);
  }

  &:hover {
    opacity: 1;
    transition: opacity 500ms;
  }
}

input.c-inverter__input[type="radio"]:checked + label,
input.c-inverter__input[type="checkbox"]:checked + label {
  opacity: 1;
  transition: opacity 500ms;

  .c-inverter-amount {
    color: colors(status, green);
  }
}

.c-solarpanels__inverters {
  display: none;

  html.cssanimations & {
    opacity: 0;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-name: solarpanel-inverters-slidedown;
    animation-timing-function: $swift-ease-in-out-timing-function;
    animation-duration: 750ms;
    animation-delay: 0s;
  }
}

.c-solarpanels__inverters--slidedown {
  display: block;

  html.cssanimations & {
    animation-fill-mode: forwards;
  }
}

.c-solarpanels-plus-sign {
  color: gradients(gradient1, from);
}
