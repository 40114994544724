button {
  text-align: inherit;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

a {
  color: inherit;
}

label {
  display: block;
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  a {
    color: inherit;
  }
}

