.c-header-notification {
  background-color: rgba(colors(black), 0.15);
  color: colors(white);
  padding: 10px 20px 4px;
  
  @include media-query(palm) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &:hover {
    text-decoration: none;
  }
}
