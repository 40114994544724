﻿.c-star-rating {
  position: relative;
  height: 18px;
  width: 100px;

  &--small {
    transform: scale(0.8);
    transform-origin: left center;
  }

  &__empty {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(#{$assetPathImages}icons/star-empty.svg) repeat-x left center;
  }

  &__full {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: url(#{$assetPathImages}icons/star-full.svg) repeat-x left center;
  }
}
