.c-incentive-account {
  &-welcome {
    display: block;
    background-color: colors(incentive, secondary);
    color: colors(white);
    font-weight: bold;
    text-align: left;
    border-radius: $border-radius-small;
    padding: 8px 10px 12px 20px;
    @include typography(default);
    transition: $swift-ease-in-out;

    &--nlziet {
      @include gradient(#565656, #313131, #313131, 90deg);
      color: #F2F2F5;
    }

    &:hover {
      box-shadow: 0 0 5px 2px rgba(colors(black), 0.3);
      text-decoration: none;
    }

    &:active {
      transform: scale(0.95);
    }
  }

  &-vouchers {
    background-color: colors(incentive, secondary);
    color: colors(white);
    border-radius: $border-radius-tiny;
    position: relative;
    z-index: 1;

    &--nlziet {
      @include gradient(#565656, #313131, #313131, 90deg);
      color: #F2F2F5;
    }
  }

  &-voucher {
    background-color: colors(white);
    color: colors(grey, dark);
    border-radius: $border-radius-small;
    line-height: 10px;
    padding: 10px 20px;
    transition: 150ms background-color $swift-ease-in-out-timing-function;

    &--nlziet {
      @include gradient(#565656, #313131, #313131, 90deg);
      color: #F2F2F5;
    }

    @include media-query(palm) {
      min-width: 120px;
    }

    @include media-query(default) {
      min-width: 200px;
    }

    &--pulse {
      background-color: colors(status, green);
      color: colors(white);

      .c-incentive-account-voucher__copy {
        font-size: 0;

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: attr(data-copy-success);
          @include typography(zeta);
          line-height: 30px;
          left: 0;
          right: 0;
          white-space: nowrap;
          color: colors(white);
          cursor: default;
        }
      }
    }

    &--error {
      background-color: rgba(colors(status, red), 0.6);

      .c-incentive-account-voucher__code {
        color: colors(white);
      }

      .c-incentive-account-voucher__copy {
        font-size: 0;

        &:hover {
          text-decoration: none;
        }

        &:before {
          content: attr(data-copy-error);
          @include typography(zeta);
          line-height: 30px;
          left: 0;
          right: 0;
          white-space: nowrap;
          color: colors(white);
          cursor: default;
        }
      }
    }

    &__code {
      display: inline-block;
      @include typography(epsilon);

      @include media-query(default) {
        letter-spacing: 2px;
      }
    }

    &__copy {
      color: colors(brand, cta);
      @include typography(zeta);
      line-height: 30px;
      display: inline-block;
      position: relative;
      font-weight: bold;
      text-align: center;
    }

    &__arrow {
      height: 5px;

      &-down {
        transform: rotate(180deg);
      }
    }
  }
}