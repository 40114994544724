.c-bencompare-teaser-fancy-bg {
  background: colors(brand, bencompare);
}

.c-bencompare-teaser-fancy {
  padding-top: 70px;
  padding-bottom: 50px;
  position: relative; // for &__image

  @include media-query(palm) {
    padding-top: 30px;
  }

  &__title {
    color: colors(white);
    font-size: 26px;
    font-weight: 700;
    font-style: italic;
  }

  &__bullet-point-text {
    padding-left: 5px;
    float: left;
  }

  &__text {
    vertical-align: top;
    font-size: 16px;
    color: colors(white);

    span.icon-check {
      font-size: 0.3em;
      display: block;
      float: left;
      color: #54aae7;
      background-color: #fff;
      @include bg-image("icons/check-blue.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 10px 16px;
      position: relative;
      top: 5px;
      width: 1.2rem;
      height: 1.2rem;
      line-height: 1.2rem;
      text-align: center;
      border-radius: $border-radius-full;

      &:before {
        top: 1px;
        position: relative;
      }
    }
  }

  &__image {
    position: absolute;
    bottom: 53px;
    left: 20px;
    max-width: 25%;
  }
}
