.c-detail-page-content {
  top: -60px;

  @include media-query(portable) {
    top: -60px;
  }
  @include media-query(palm) {
    top: -30px;
  }

  &__back-link {
    top: -30px;

    @include media-query(palm) {
      top: -10px;
    }
  }
}
