.embed-container, //legacy because this classname might be used in content-items
.o-embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: colors(black); // for a smoother transition when lazy-loading embedded video's
  border-radius: $border-radius;
  border: 1px solid colors(grey, x-light);

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
