//
// Makes the clearfix-classname available as mixin
//

@mixin clearfix() {
  display: flow-root;

  // IE10+ workaround for flow-root
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}
