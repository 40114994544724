@keyframes o-toggle-grow {
  0% {
    display: block;
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@mixin o-toggle-visible {
  display: block;
  transform: scaleY(1);
  transform-origin: top left;
  opacity: 1;
  animation: o-toggle-grow 0.3s ease-in-out;
}

@mixin o-toggle-hidden {
  display: none;
  transform: scaleY(0);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.o-toggle {
  &__button {
    cursor: pointer;
  }

  &__trigger-instant {
    ~ .o-toggle__wrapper {
      display: none;
    }

    ~ .o-toggle__wrapper--inverse {
      display: block;
    }

    &:checked {
      ~ .o-toggle__wrapper {
        display: block;
      }

      ~ .o-toggle__wrapper--inverse {
        display: none;
      }
    }
  }

  &__trigger {
    ~ .o-toggle__wrapper--instant {
      display: none;
    }

    ~ .o-toggle__wrapper--instant-inverse {
      display: block;
    }

    ~ .o-toggle__wrapper {
      @include o-toggle-hidden;
    }

    ~ .o-toggle__wrapper--inverse {
      @include o-toggle-visible;
    }

    ~ .o-toggle__overflow {
      $height: 150px;
      max-height: $height;
      overflow: hidden;
      position: relative;

      .o-toggle__button {
        font-size: 0;
        height: $height * 0.4;
        top: $height * 0.6;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(colors(white), 0.5) 0%, rgba(colors(white), 1) 90%);
        transition: opacity 300ms;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &:hover {
          opacity: 0.4;
        }

        &[data-all-label] {
          &:before {
            content: attr(data-all-label);
            @include inuit-font-size($button-font-size, $inuit-heading-size-6-lineheight);
            text-align: center;
            color: colors(grey, dark);
            background-color: colors(white);
            padding: 15px $inuit-base-spacing-unit;
            border-radius: $border-radius-full;
            border: 1px solid;
            transition-property: background-color, color;
            transition-duration: $duration-fast;
            transition-timing-function: $swift-ease-in-timing-function;
          }

          &:hover {
            opacity: 1;

            &:before {
              background-color: colors(grey, x-light);
            }
          }
        }
      }

      &--medium {
        $height: 425px;
        max-height: $height;

        .o-toggle__button {
          height: $height * 0.6;
          top: $height * 0.4;
          background: linear-gradient(to bottom, rgba(colors(white), 0) 0%, rgba(colors(white), 0.8) 50%, rgba(colors(white), 1) 100%);
        }
      }

      &--heavy {
        $height: 500px;
        max-height: $height;

        .o-toggle__button {
          height: $height * 0.6;
          top: $height * 0.4;
          background: linear-gradient(to bottom, rgba(colors(white), 0) 0%, rgba(colors(white), 0.8) 50%, rgba(colors(white), 1) 100%);
        }
      }
    }

    &:not(:checked) {
      ~ .o-toggle__overflow {
        .is_stuck {
          position: relative !important;
          top: 0 !important;
        }
      }
    }

    &:checked {
      ~ .o-toggle__wrapper--instant {
        display: block;
      }

      ~ .o-toggle__wrapper--instant-inverse {
        display: none;
      }

      ~ .o-toggle__wrapper {
        @include o-toggle-visible;
      }

      ~ .o-toggle__wrapper--inverse {
        @include o-toggle-hidden;
      }

      ~ .o-toggle__overflow {
        max-height: none;
        overflow: visible;

        .o-toggle__button {
          display: none;
        }
      }
    }
  }
}
