.u-border {
  border: 1px solid colors(grey, light);

  &--left {
    border-left: 1px solid colors(grey, light);
  }

  &--right {
    border-right: 1px solid colors(grey, light);
  }

  &--top {
    border-top: 1px solid colors(grey, light);
  }

  &--bottom {
    border-bottom: 1px solid colors(grey, light);
  }

  &-color-grey--dark {
    border-color: colors(grey);
  }

  &-color-white {
    border-color: colors(white);
  }

  &-color-status--red {
    border-color: colors(status, red);
  }

  &--ekomi-height {
    min-height: 260px;
  }
}

.u-border--none {
  border: 0 !important;
}

@each $breakpoint in $breakpoints {
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {
    .u-#{$alias}-border--none {
      border: 0 !important;
    }
  }
}

.u-border-pseudo {
  &--left,
  &--right,
  &--top,
  &--bottom,
  &--middle {
    position: relative;

    &::after {
      content: "";
      position: absolute;
    }
  }

  &--left::after {
    border-left: 1px solid colors(grey);
    top: 0;
    bottom: 0;
    left: -1px;
  }

  &--right::after {
    border-right: 1px solid colors(grey);
    top: 0;
    bottom: 0;
    right: -1px;
  }

  &--middle {
    &::after {
      border-top: 1px solid colors(grey);
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &--top::after {
    border-top: 1px solid colors(grey);
    top: -1px;
    left: 0;
    right: 0;
  }

  &--bottom::after {
    border-bottom: 1px solid colors(grey);
    bottom: -1px;
    left: 0;
    right: 0;
  }

  &-color-grey--light::after {
    border-color: colors(grey, light);
  }
}

.u-palm-border-pseudo--none::after {
  @include media-query(palm) {
    border: 0 !important;
  }
}
