.c-steps-circle {
  position: relative;
  display: inline-block;

  &__image {
    clip-path: circle(50% at center);
  }

  &__badge {
    position: absolute;
    left: -3px;
    top: -3px;
    z-index: 1;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
  }

  &__text {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    @include typography(zeta);
  }
}