.u-transition-swift--ease-out {
  transition: $swift-ease-out;
}

.u-transition-swift--ease-in {
  transition: $swift-ease-in;
}

.u-transition-swift--ease-in-out {
  transition: $swift-ease-in-out;
}

.u-transition-duration--slow {
  transition-duration: $duration-slow;
}

.u-transition-duration--medium {
  transition-duration: $duration-medium;
}

.u-transition-duration--fast {
  transition-duration: $duration-fast;
}