﻿.c-form-finalsteps {
  ul {
    display: flex;
    justify-content: space-between;
    position: relative;
    max-width: 68.75rem;
    margin-left: auto;
    margin-right: auto;

    @include media-query(palm) {
      flex-direction: column;
    }

    @include media-query(default) {
      &:before {
        content: " ";
        position: absolute;
        border: 1px solid colors(grey, light);
        width: 100%;
        left: 0%;
      }
    }
  }

  &__item {
    position: relative;
    text-align: center;

    @include media-query(palm) {
      flex: 1 1 100px;
      text-align: center;
      width: 100%;
      margin-bottom: 40px;

      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 40px;
        position: absolute;
        bottom: -30px;
        background-image: url(#{$assetPathImages}icons/curly-pointer-down2.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }

      &:nth-child(even):before {
        right: 35%;
      }

      &:nth-child(odd):before {
        left: 35%;
        transform: scaleX(-1);
      }

      &:last-of-type {
        margin-bottom: 0;

        &::before {
          display: none;
        }
      }
    }

    @include media-query(default) {
      &:first-of-type::before,
      &:last-of-type::before {
        content: "";
        border: 1px solid colors(white);
        position: absolute;
        left: 0;
        width: 50%;
      }

      &:last-of-type::before {
        left: auto;
        right: 0;
      }
    }

    &::after {
      content: "";
      width: 64px;
      background-color: colors(white);
      height: 64px;
      position: absolute;
      top: -32px;
      left: 50%;
      margin-left: -32px;
      border-radius: $border-radius-full;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 60%;
    }
  }

  &__item--step-firstpayment {
    &::after {
      @include bg-image("icons/step-firstpayment.svg");
    }
  }

  &__item--step-email {
    &::after {
      @include bg-image("icons/step-email.svg");
    }
  }

  &__item--step-reflect14 {
    &::after {
      @include bg-image("icons/step-reflect14.svg");
    }
  }

  &__item--step-inform {
    &::after {
      @include bg-image("icons/step-inform.svg");
    }
  }

  &__item--step-meter {
    &::after {
      @include bg-image("icons/step-meter.svg");
    }
  }

  &__item--step-supply {
    &::after {
      @include bg-image("icons/check.svg");
      background-size: 50%;
    }
  }

  &__item--step-schedule {
    &::after {
      @include bg-image("icons/step-schedule.svg");
      background-size: 50%;
    }
  }

  &__item--step-install {
    &::after {
      @include bg-image("icons/step-install.svg");
      background-size: 50%;
    }
  }

  &__item--step-phone {
    &::after {
      @include bg-image("icons/step-phone.svg");
      background-size: 50%;
    }
  }

  /* oude classnames voor poliswijzer. Dit moet later gelijk getrokken worden obv naam */
  &__item--step1 {
    &::after {
      @include bg-image("icons/form-step1.svg");
      background-size: 27px 27px;
    }
  }

  &__item--step2 {
    &::after {
      @include bg-image("icons/form-step2.svg");
      background-size: 41px 36px;
    }
  }

  &__item--step3 {
    &::after {
      @include bg-image("icons/check.svg");
      background-size: 22px 17px;
    }
  }
}
