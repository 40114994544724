.layout--flex {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;

  @include media-query(palm) {
    flex-wrap: wrap;
  }

  &__force {
    display: flex !important;
  }

  &__wrap {
    flex-wrap: wrap;
  }

  &__mobile {
    @include media-query(palm) {
      flex-wrap: nowrap;
    }
  }

  &.layout--middle {
    align-items: center;
  }

  &.layout--bottom {
    align-items: end;
  }

  &-vertical-center-inner {
    flex-direction: column;
    justify-content: center;
  }

  &__vertical {
    flex-direction: column;
  }

  &__space-between {
    justify-content: space-between;

    > * {
      flex: 0 0 auto;
    }
  }
}

.layout__item--align-right {
  margin-left: auto;
}

.layout__item--flex-fill {
  flex-grow: 1;
}

.layout__item--flex-shrink {
  flex-shrink: 2;
}

.layout__item--flex-content {
  flex-basis: 0;
}

.layout__item--flex-center {
  align-self: center;
}

.layout__item--flex-end {
  align-self: flex-end;
}

.layout__item--order-1 {
  order: -1;
}

.layout__item--order1 {
  order: 1;
}