.c-faq {
  &__columns {
    @include media-query(desk) {
      column-count: 3;
      column-gap: 40px;
    }

    @include media-query(lap) {
      column-count: 2;
      column-gap: 40px;
    }
  }

  &__item {
    display: block;
    column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 20px;
  }

  &__filters {
    font-size: 0;
  }

  &__label {
    @include typography(zeta);
    border-radius: $border-radius;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 3px;
    font-weight: bold;
    background-color: rgba(colors(white),0.3);

    @include media-query(palm) {
      padding: 15px 10px;
    }

    @include media-query(lap) {
      padding: 15px;
    }

    @include media-query(desk) {
      padding: 15px 30px;
    }

    &:hover,
    &:active {
      background-color: rgba(colors(white),0.5);
      box-shadow: none;
    }
  }

  &__checkbox:checked ~ .c-faq__columns .c-faq__item {
    display: none;
  }

  &__checkbox:checked ~ .c-faq__filters .c-faq__label {
    background-color: rgba(colors(white),0.05);

    &:hover,
    &:active {
      background-color: rgba(colors(black),0.1);
      box-shadow: none;
    }
  }

  @for $item from 1 through 10 {
    &__checkbox[data-faq-cat="#{$item}"]:checked ~ .c-faq__columns .c-faq__item[data-faq-cat="#{$item}"] {
      display: block;
    }

    &__checkbox[data-faq-cat="#{$item}"]:checked ~ .c-faq__filters .c-faq__label[for="faq-cat#{$item}"] {
      &,
      &:hover,
      &:active {
        background-color: rgba(colors(white),0.3);
        box-shadow: none;
      }
    }

    &__checkbox[data-faq-cat="#{$item}"]:focus ~ .c-faq__filters .c-faq__label[for="faq-cat#{$item}"] {
      box-shadow: 0 0 4px 0 rgba(colors(white), 0.6);
    }
  }
}
