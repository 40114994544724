.c-list {
  li {
    padding-left: 30px;
    list-style: none;
    position: relative;
  }

  // -------------------------------------------------------------------------
  // List component for a pros and cons list that get prepended with a + or -
  //
  // Markup example of a list with pros:
  // <ul class="c-list c-list__pros">
  //    <li>...</li>
  //    <li>...</li>
  // </ul>
  //
  // Markup example of a list with cons:
  // <ul class="c-list c-list__cons">
  //    <li>...</li>
  //    <li>...</li>
  // </ul>
  // -------------------------------------------------------------------------

  @mixin c-list-pros-cons-shared-styles {
    @include size(18px);
    border-radius: $border-radius-full;
    color: colors(white);
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
  }

  &--cons {
    li:before {
      @include c-list-pros-cons-shared-styles;
      @include inuit-font-size(28px, 12px);
      background-color: colors(brand, base);
      content: "-";
    }

    &-new {
      li:before {
        background-color: colors(grey);
      }
    }
  }

  &--pros {
    li:before {
      @include c-list-pros-cons-shared-styles;
      @include inuit-font-size(24px, 17px);
      background-color: colors(status, green);
      content: "+";
    }

    &-new {
      li:before {
        background-color: brand-colors(nav);
      }
    }
  }

  &.blue-checkmark {
    padding-left: 0;

    li {
      @include bg-image("icons/check-blue.svg");
      background-position: left 9px;
    }

    &--medium > li {
      background-position: left 4px;
      text-align: left;
    }
  }

  &-checkmark {
    padding-left: 0;
    margin-bottom: 0;

    > li {
      list-style: none;
      padding-left: 30px;
      background-position: left 9px;
      @include bg-image("icons/check.svg");
    }

    &--white > li {
      @include bg-image("icons/check-white.svg");
    }

    &--blue > li {
      @include bg-image("icons/check-blue.svg");
    }

    &--small > li {
      @include typography(zeta);
      background-size: 13px auto;
      padding-left: 20px;
      background-position: left 5px;
      margin-bottom: 5px;
    }

    @include media-query(default) {
      &--big > li {
        @include typography(epsilon);
        background-size: 24px auto;
        padding-left: 40px;
        background-position: left 5px;
        margin-bottom: 5px;
      }
    }
  }
}
