.c-media-publications {
  &__item {
    display: grid;
    align-content: center;
    justify-items: stretch;
    align-items: stretch;
    justify-content: space-evenly;
    grid-template-rows: 50px 100px auto;
    grid-template-columns: 100%;

    @include media-query(palm) {
      grid-template-rows: 50px auto auto;
    }
  }

  &__gridcontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 20px;

    @include media-query(palm) {
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
  }
  
  &__img {
    height: 100%;
    width: 160px;
  }
}