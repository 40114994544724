.c-contract {
  margin-bottom: 20px;
  box-shadow: 0 1px 20px 0 rgba(colors(black), 0.05);
  display: flex;
  flex-wrap: wrap;
  @include dropshadow--hover;
  border-radius: $border-radius;

  @include media-query(palm) {
    margin-bottom: 10px;
  }

  &__inner {
    background-color: colors(white);
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 90px;
    align-self: flex-end;
    width: 100%;

    @include media-query(palm) {
      flex-wrap: nowrap;
    }

    &:hover {
      text-decoration: none;
    }

    &:has(+ .c-contract__bottom-bar) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--tile {
      flex-flow: column nowrap;
      justify-content: center;

      > * {
        flex-grow: 1;
      }

      @include media-query(default) {
        min-height: 195px;
        padding-top: 15px;
        padding-bottom: 35px;

        + .c-contract__bottom-bar {
          margin-top: -30px;
        }
      }

      @include media-query(palm) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    &--empty {
      background-color: rgba(colors(white), 0.6);
      padding-bottom: 15px;
    }
  }

  &--align-right {
    margin-left: auto;
  }

  &-tiles {
    @include media-query(default) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: space-between;
    }
  }

  &--tile {
    flex-basis: 195px;
    margin-left: 0;
  }

  &__provider-logo-wrapper {
    width: 80px;
    margin: 10px 20px;
    text-align: center;
  }

  &__provider-logo {
    max-height: 40px;
    max-width: 80px;
    display: inline-block;
  }

  &__content {
    margin: 10px;
  }

  &__price {
    margin-left: auto;
    margin-right: 20px;
  }

  &__or-splitter {
    @include typography(delta);
    color: colors(grey, light);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -11px;
    margin-top: -10px;
    font-weight: bold;
    background-color: colors(white);
  }

  &__arrow {
    background-image: url($assetPathImages+"icons/arrow-up.svg");
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 15px;
    width: 20px;
    transform: rotate(90deg);
    margin-right: 20px;
  }

  &__upload-layer {
    position: relative;

    &:before {
      content: "";
      border: 10px solid transparent;
      border-bottom-color: #fff;
      left: 40%;
      top: -20px;
      height: 0;
      position: absolute;
      width: 0;
    }
  }

  &__bottom-bar {
    @include typography(zeta);
    background-color: colors(brand, cta);
    color: colors(white);
    text-align: center;
    padding: 5px;
    align-self: flex-end;
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 0 0 $border-radius $border-radius;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(colors(black), 0.2);
      border-radius: 0 0 $border-radius $border-radius;
      z-index: -1;
      transform: translateZ(0) scale(0, 1);
      transform-origin: left center;
      transition: transform 2000ms $swift-ease-out-timing-function;
      -webkit-backface-visibility: hidden;
    }

    &:hover {
      text-decoration: none;

      &:before {
        transition-duration: 750ms;
        transform: translateZ(0) scale(1, 1);
      }
    }

    &:active {
      &:before {
        background: colors(brand);
      }
    }
  }
}

.c-contract__add-more-text {
  @include typography(zeta);
  text-align: center;
  width: 65%;
  margin: 0 auto;
  position: relative;
  padding-top: 30px;

  svg {
    top: 0;
    right: -40px;
    position: absolute;
    transform: rotate(210deg);
  }
}
