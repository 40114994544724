// ----------------------------------------------------------------------------
// Panels component on the homepage
//
// This could potentially be extracted to the FE core if we find a use case
// for this in other applications.
// ----------------------------------------------------------------------------

.c-button-panel {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__image {
    img {
      width: 100%;
    }
  }

  &__link {
    @include inuit-font-size(24px, 1.2);
    display: flex;
    flex: 1;
    font-style: italic;
    font-weight: bold;
    padding: 23px 62px 23px 20px;
    position: relative; // for absolute positioning of arrow on &::after
    text-align: center;

    &::after {
      background-image: url($assetPathImages + "icons/arrow-up.svg");
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: block;
      height: 30px;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      width: 20px;
    }

    &-text {
      align-self: center;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
    }
  }
}
