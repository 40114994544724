.c-gl-current-provider {
  // Table-row
  &__row {
    border-bottom: 1px solid colors(grey, light);
    border-right: 1px solid colors(grey, light);

    // Header-row
    &--header {
      border-top: 1px solid colors(grey, light);
    }
  }

  &__cell.layout__item {
    // layout--flush forces a padding-left with a higher specifitity. The specifity of this rule is equal, but loaded the latest.
    padding-left: 5px;
  }

  // Table-cell
  &__cell {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    border-left: 1px solid colors(grey, light);
    padding: 10px 5px;

    // Table-cell in the header
    &--header {
      border-left: 1px solid colors(grey, light);
      @include font-weight--semi-bold;
    }

    // Table-cell in the first col
    &--label {
      @include font-weight--semi-bold;
      @include media-query(default) {
        justify-content: flex-end;
        padding-right: 20px;
      }
      @include media-query(palm) {
        border-bottom: 1px solid colors(grey, light);
      }
    }
  }
}
