// Simple mixin to create a gradient with a fallback
//
// Example:
//
// @include gradient(#ff0000, #00ff00, #0000ff, 90deg) {;
//
// results in:
//
//  background-color: #0000ff;
//  background: linear-gradient(90deg, #ff0000 0%, #00ff00 100%);
//
//  Default value for $direction is "to bottom"
//
// The fallback background will be used for browser which don't support background-gradient.

@mixin gradient($from-color, $to-color, $fallback-color, $direction: to bottom) {
  background-color: $fallback-color;
  background: linear-gradient($direction, $from-color 0%, $to-color 100%);
}
