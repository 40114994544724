﻿.c-product {
  &-spec__item {
    position: relative;

    &::after {
      content: "";
      top: 2px;
      left: -4px;
      height: 16px;
      width: 16px;
      position: absolute;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &--check::after {
      background-image: url(#{$assetPathImages}icons/check.svg);
    }

    &--check-grey::after {
      background-image: url(#{$assetPathImages}icons/check-grey.svg);
    }

    &--cross::after {
      background-image: url(#{$assetPathImages}icons/cross.svg);
    }

    &--questionmark::after {
      background-image: url(#{$assetPathImages}icons/questionmark.svg);
    }
  }
}

.c-product-popup {
  min-height: 100%;

  &__header {
    z-index: 2;
    background-color: colors(white);

    @include media-query(palm) {
      min-height: 300pxx;
    }

    @include media-query(default) {
      min-height: 215px;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
    }

    .js-product-popup-header:empty + * {
      display: none;
    }
  }

  &__loader {
    @include typography(delta);
    font-style: italic;
    font-weight: bold;
    text-align: center;
    padding-top: 40px;
  }
}