
.c-gl-province-cols {
  @include media-query(default) {
    column-count: 2;
    column-gap: 20px;

    li {
      // needed in combination with CSS-column, otherwise the bullets will disappear.
      list-style-position: inside;
    }
  }
  @include media-query(palm) {
    padding-left: 20px;    
  }
}