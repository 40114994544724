
.c-tabbox {
  border-top-left-radius: 0 !important;
}

.c-tabbox__tab {
  &-classic {
    position: relative;
    display: inline-block;
    padding: 7px 14px 5px;
    line-height: 30px;
    margin-right: 5px;
    color: colors(grey, dark);
    border-left: 1px solid rgba($tab-border-color, $tab-border-alpha);
    border-right: 1px solid rgba($tab-border-color, $tab-border-alpha);
    border-top: 1px solid rgba($tab-border-color, $tab-border-alpha);
    border-radius: $border-radius-small $border-radius-small 0 0;
    background-color: rgba(colors(grey, x-light), 0.4);
    cursor: pointer;
    white-space: nowrap;

    @include media-query(portable) {
      padding: 12px 10px 10px;
      margin-right: -3px; // negative value cause of the default whitespace between elements that have display: inline-block;
      border-radius: $border-radius-small $border-radius-small 0 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: colors(white);
      border-color: $tab-border-color;
    }

    &--current {
      background-color: colors(white);
      border-color: $tab-border-color;
      color: colors(grey, dark) !important;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 1px;
        background-color: colors(white);
        z-index: 1;
      }

      &:hover {
        background-color: colors(white);
        border-color: $tab-border-color;
      }
    }

    &--discount {
      color: colors(status,green);
      background-color: desaturate(lighten(colors(status, green), 70%), 55%);

      &:hover {
        background-color: desaturate(lighten(colors(status, green), 72%), 55%);
      }

      &.c-tabbox__tab-classic--current {
        background-color: colors(white);
      }
    }
  }

  ~ .o-list-links {
    display: none;
  }

  &.o-list-links__current ~ .o-list-links {
    display: block;
  }
}


@keyframes blueprint-background {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.c-tab-content {
  opacity: 1;
  transition: all 500ms;

  &--loading {
    opacity: 0;
  }

  &__loader-blueprint {
    margin-top: 20px;

    span {
      display: block;
      height: 12px;
      margin-bottom: 8px;
      background-color: colors(grey, x-light);
      background: linear-gradient(270deg, colors(grey, x-light), colors(white));
      background-size: 400% 400%;
      animation: blueprint-background 3s ease infinite;

      &:nth-child(1) {
        width: 60%;
      }

      &:nth-child(2) {
        width: 55%;
      }

      &:nth-child(3) {
        width: 57%;
      }

      &:nth-child(4) {
        width: 60%;
        margin-bottom: 28px;
      }

      &:nth-child(5) {
        width: 40%;
      }

      &:nth-child(6) {
        width: 45%;
      }

      &:nth-child(7) {
        width: 20%;
      }
    }
  }
}

.c-mobile-tabs {
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  padding: 5px 20px;
  white-space: nowrap;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, rgba(colors(grey, x-light),1) 0%,rgba(colors(grey, x-light),0) 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, rgba(colors(grey, x-light),1) 0%,rgba(colors(grey, x-light),0) 100%);
  }
}
