// ----------------------------------------------------------------------------
// Vertical centering
//
// Use "position: relative" on the parent, otherwise it will not work.
// ----------------------------------------------------------------------------

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-horizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
