﻿.c-footer {
  &__sitemap {
    background-color: colors(grey, x-light);
    padding-top: 2 * $inuit-base-spacing-unit;
    padding-bottom: 2 * $inuit-base-spacing-unit;
  }

  &__sitemap-col {
    @include typography(zeta);

    h6 {
      margin-bottom: halve($inuit-base-spacing-unit);
    }
  }

  &__sitemap-list {
    a {
      color: colors(grey, dark) !important;
    }
  }

  &__sitemap-text {
    line-height: 30px;
  }

  &__external {
    padding-top: $inuit-base-spacing-unit;
    padding-bottom: $inuit-base-spacing-unit;
  }
}
