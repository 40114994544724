:root {
  --account-loading-time: 1500ms;
}

.c-account-button {
  @include media-query(palm) {
    display: none;
  }

  @include media-query(default) {
    display: inline-block;
    white-space: nowrap;

    button {
      color: colors(white);
      background-color: brand-colors(nav, hover);
      position: relative;
      transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
      height: 50px;
      padding: 10px 20px;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &-mobile {
    position: absolute;
    fill: colors(brand, support);
    right: 70px;
    padding: 0 10px;
    top: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    transition: $swift-ease-in-out;

    svg {
      height: 100%;
    }

    &.c-main-navigation__active {
      background-color: colors(brand, support);
      color: colors(white);
      top: 30px;
      background-image: url(#{$assetPathImages}icons/cross-white.svg);

      svg {
        opacity: 0;
      }
    }
  }
}

.c-account-notification {
  background-color: colors(brand, bencompare);

  &__inlogcode-title {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    line-height: 1;
  }
}

.c-account-picture-frame {
  @include circle-crop(150px);
  position: relative;
  background-color: colors(grey, x-light);
  box-shadow: 0 0 0 1px inset colors(white);
  border-radius: $border-radius-full;

  &:before {
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-color: colors(brand, bencompare);
    border-radius: $border-radius-full;
    z-index: 1;
    opacity: 0.4;
    transform: scale(0);
    transform-origin: center center;
    transition: transform var(--account-loading-time) $swift-ease-out-timing-function;
  }

  &.c-account-loading {
    &:before {
      transform: scale(1.1);
    }
  }
}

.c-account-picture-action {
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
  text-align: center;
  @include typography(zeta);
  transition: all 300ms $swift-ease-out-timing-function;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: all 200ms $swift-ease-out-timing-function;
  }

  &:hover {
    text-decoration: none;
    transform: translateY(0);

    &:before {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__add {
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 20px;
    color: colors(white);
    transform-origin: center bottom;
    transform: translateY(10px);

    &:before {
      opacity: 0;
      background-color: colors(brand, support);
      transform: translateY(7px);
      transform-origin: center bottom;
    }
  }

  &__edit {
    opacity: 0;
    top: 0;
    padding-top: 15px;
    padding-bottom: 10px;
    transform-origin: center top;
    transform: translateY(-10px);

    &:before {
      opacity: 0.8;
      background-color: colors(grey, x-light);
      transform: translateY(-7px);
      transform-origin: center top;
    }

    .c-account-picture-frame:hover & {
      opacity: 1;
    }
  }

  &__remove {
    opacity: 0;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    color: colors(white);
    transform-origin: center bottom;
    transform: translateY(10px);

    &:before {
      opacity: 0.5;
      background-color: colors(brand, base);
      transform: translateY(7px);
      transform-origin: center bottom;
    }

    .c-account-picture-frame:hover & {
      opacity: 1;
    }
  }
}

.c-account-profile {
  display: flex;
  align-items: center;
  width: 370px;
  flex-direction: row-reverse;

  &__picture {
    @include circle-crop(60px);
    background-color: colors(grey, x-light);
    margin-left: 10px;

    &--child {
      background-position: center bottom;
      background-size: 75%;
    }
  }

  &__picture--navigation {
    @include circle-crop(26px);
    display: inline-block;
    margin-top: 2px;
    background-color: colors(grey, x-light);
    margin-right: 5px;

    @media screen and (max-width: 63.9375em) {
      margin-top: -2px;
    }

    @media screen and (max-width: 48em) {
      display: none;
    }

    &[style*="user-ghost.svg"] {
      opacity: 0.6;
      filter: brightness(1.2);
    }

    &-sticky {
      display: inline-block !important;
      @include circle-crop(40px);
      background-position: center 2px;
      background-size: contain;
      margin-top: -2px;
      margin-right: 15px;
      vertical-align: middle;

      @include media-query(palm) {
        margin-right: 5px;
        @include circle-crop(20px);
        background-size: contain;
      }
    }
  }
}

.c-account-address-details-edit {
  position: relative;

  &:after {
    content: "wijzig";
    position: absolute;
    right: 0;
    color: colors(brand, cta);
    padding-left: 15px;
    background: colors(white);
    background: linear-gradient(
      to right,
      rgba(colors(white), 0) 0%,
      rgba(colors(white), 1) 10px,
      rgba(colors(white), 1) 100%
    );
    transition: all 500ms;
    opacity: 0;
    max-width: 0;
    overflow: hidden;
    display: inline-block;
  }

  &:hover {
    &:after {
      max-width: 100%;
      opacity: 1;
    }
  }
}

@keyframes download-bencompare-toggle {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.c-download-bencompare {
  display: none;

  &--visible {
    display: block;

    .c-download-bencompare__overlay {
      display: block;
      opacity: 1;
      animation: download-bencompare-toggle 0.3s ease-in-out;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: 1;
  }

  &__overlay {
    display: none;
    opacity: 0;
    position: fixed;
    top: 20vh;
    max-width: 650px;
    z-index: 2;
    margin-right: 40px;

    @include media-query(palm) {
      margin-right: 10px;
      top: 40px;
    }
  }

  &__phone-wrapper {
    margin-bottom: -20px;
  }
}

.c-account-loggedin-icon {
  padding-left: 70px;
  position: relative;
  min-height: 70px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    @include bg-image("graphics/account/house.svg");
    background-size: contain;
    background-position: left center;
  }

  &:after {
    content: "";
    position: absolute;
    left: 30px;
    top: 30px;
    height: 25px;
    width: 25px;
    @include bg-image("icons/check.svg");
    background-position: center center;
    background-color: colors(white);
    border-radius: $border-radius-full;
    border: 2px solid gradients(gradient1, to);
    box-shadow: 0 0 2px 0 colors(white);
  }
}

.c-account-action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-left: -10px;
  height: 100px;

  &__item {
    background: colors(bencompare, base);
    margin-left: 10px;
    color: colors(white);
    font-weight: bold;
    text-align: center;
    flex: 1 0 calc(25% - 10px);
    border-radius: $border-radius-small;
    padding-top: 60px;
    @include typography(default);
    position: relative;
    overflow: hidden;
    transition: $swift-ease-in-out;

    &:after {
      content: attr(data-account-button-batch);
      position: absolute;
      right: 10px;
      top: 10px;
      @include typography(beta);
      font-style: normal;
      opacity: 0.5;
    }

    &[data-account-button-batch=""]:after {
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      top: 10px;
      background-size: auto 100%;
      background-position: right bottom;
      width: 120px;
      left: -55px;
      opacity: 0.25;
      transform-origin: right bottom;
      transition: $swift-ease-in-out;
    }

    &:hover {
      box-shadow: 0 0 5px 2px rgba(colors(black), 0.3);
      text-decoration: none;

      &:before {
        transform: scale(0.95);
      }
    }

    &:active {
      transform: scale(0.95);
    }

    &--contract {
      &:before {
        @include bg-image("icons/contract-white.svg");
        left: -65px;
      }
    }

    &--profile {
      &:before {
        @include bg-image("icons/profile-white.svg");
      }
    }

    &--address {
      &:before {
        @include bg-image("icons/house-white.svg");
      }
    }

    &--usage {
      padding-top: 0;
      align-items: center;
      display: flex;
      justify-content: center;
      height: 55px;

      &:before {
        @include bg-image("icons/usage-white.svg");
      }

      &:after {
        display: inline-block;
        position: relative;
        right: 0;
        top: 0;
        background-color: colors(brand, base);
        border-radius: $border-radius-small;
        padding: 10px;
        margin-left: 10px;
        opacity: 1;
        @include typography(small);
      }
    }
  }
}

.c-account-prefill-message {
  background: gradients(gradient3, from);
  color: colors(grey, dark);
  padding: 10px 15px;
  @include typography(zeta);
  display: inline-block;
  font-weight: bold;
  margin-bottom: 20px;
}

.c-account-category {
  &__button {
    position: relative;
    background-color: gradients(gradient3, from);
    padding: 10px 5px 10px 76px;
    min-height: 90px;
    display: block;
    margin-bottom: 10px;
    border-radius: $border-radius;
    border: 1px solid gradients(gradient3, to);
    z-index: 0;

    &-description {
      @include typography(zeta);
      color: colors(grey);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 40px;
      overflow: hidden;
    }

    &:hover {
      text-decoration: none;
    }

    &-icon {
      position: absolute;
      left: 20px;
      width: 36px;
      height: 32px;
      top: 50%;
      margin-top: -15px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      &:before {
        content: "";
        position: absolute;
        $offset: -10px;
        top: $offset;
        bottom: $offset;
        left: $offset;
        right: $offset;
        background-color: colors(white);
        border-radius: $border-radius-small;
        border: 1px solid gradients(gradient3, to);
        z-index: -1;
      }
    }
  }
}

.c-account-feature-hint {
  position: absolute;
  color: colors(white);
  top: 43px;
  right: 60%;

  &--pointer {
    filter: brightness(0) invert(1);
    transform: scaleX(-1) rotate(95deg);
  }

  &--title {
    position: absolute;
    top: 15px;
    right: 50px;
  }

  &--new {
    z-index: 1;
    background: colors(bencompare, base);
    color: colors(white);
    position: absolute;
    border-radius: $border-radius-tiny;
    top: -10px;
    right: 10px;
    padding: 0 7px 1px;
    margin-top: 2px;
    white-space: nowrap;
    @include typography(small);
  }
}

.c-account-feature-bg {
  background-color: rgba(colors(bencompare, light), 0.2);
}
